import React, { useState } from 'react';
import {
  Card,
  Typography,
  CardContent,
  CardActions,
  Button,
  Collapse,
  TextField,
  IconButton,
  Grid,
  Hidden,
  Dialog,
  DialogContent,
  Divider,
  useTheme,
} from '@material-ui/core';
import { makeStyles, alpha } from '@material-ui/core/styles';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import SendIcon from '@material-ui/icons/Send';
import './Idea.css';
import { useDispatch, useSelector } from 'react-redux';
import { timelineActions } from '../../actions';
import { IdeaComment } from './IdeaComment';
import { FbGridImage } from './FbGridImage';
import { AttachmentFiles } from "./AttachmentFiles"
import { Author } from '../Shared';
import { CustomTooltip } from '../Shared/CustomTooltip';
import { IdeaUpContent } from './IdeaUpContent';
import { IdeaUpTitle } from './IdeaUpTitle';
import { history } from '../../helpers';
import { ImagineLinkify } from "../TextLinkify";
import ShowMoreText from "react-show-more-text";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { CanvasAttachment } from './CanvasAttachment';
import { CardAttachment } from './CardAttachment';
import { AvatarGroupIdea } from '../Shared';
// in your react component

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
  },
  sectionTitleIdea: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 2, 0, 2),
    alignItems: 'center',
  },
  sectionTitleChallenge: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2, 0, 2),
    alignItems: 'center',
  },
  sectionTitleBox: {
    fontSize: 14,
    fontWeight: 600,
    wordWrap: 'break-word',
    cursor: 'pointer'
  },
  sectionChallengeBox: {
    padding: theme.spacing(0.5, 1),
    fontSize: 11,
    borderRadius: 4,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
  },
  sectionTitleChallengeBoxBackground: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    textTransform: 'uppercase',
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  },
  cardContentBorderBottom: {
    borderBottom: `1px solid ${theme.palette.neutrals.high.medium}`,
    paddingBottom: theme.spacing(3),
  },
  spacer: {
    flexGrow: 1,
  },
  link: {
    fontSize: 11.44,
    fontWeight: 600,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },
  },
  linkWeb: {
    paddingTop: 0,
    fontSize: 14,
    fontWeight: 400,
    cursor: 'pointer',
    color: theme.palette.auxiliary.blue.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical',
  },
  linkContainer: {
    display: 'flex',
    maxWidth: '100%',
  },
  linkTitle: {
    fontSize: 14,
    color: theme.palette.neutrals.low.dark,
    fontWeight: 600,
    paddingRight: 8,
    minWidth: 'fit-content',
  },
  ideaComment: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  ideaCommentText: {
    marginRight: theme.spacing(2),
  },
  firstUp: {
    backgroundColor: theme.palette.primary.lightest,
    padding: theme.spacing(2),
    paddingBottom: '16px !important',
  },
  firstUpText: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    fontSize: 12,
  },
  seeMoreComments: {
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  cardActions: {
    padding: theme.spacing(2, 2, 2, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  fontLowercase: {
    textTransform: 'lowercase',
  },
  author: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  authorClick: {
    cursor: 'pointer',
  },
  favorite: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
  },
  starBorder: {
    '&:hover': {
      color: theme.palette.auxiliary.yellow.main,
    },
  },
  grid: {
    display: 'grid',
    gridAutoFlow: 'row',
    gridGap: 10,
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'auto',
  },
  challenge: {
    minWidth: 'fit-content',
    alignSelf: 'start',
    cursor: "pointer",
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },
  challengeLink: {
    textDecoration: 'none',
    "&:hover": {
      color: theme.palette.primary.main,
      textDecoration: 'underline'
    }
  },
  titleAttachmentSection: {
    fontSize: 14.22,
    fontWeight: 500,
    textTransform: 'lowercase',
    overflow: 'hidden',
    color: '#434343',
  }
}));

function Idea(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userLogged = useSelector((state) => state.user);
  const [expanded, setExpanded] = React.useState(false);
  const { register, errors, handleSubmit, reset } = useForm();
  const [pageNumberComment, setPageNumberComment] = useState(1);
  const { t } = useTranslation();
  const theme = useTheme();

  const { idea, index } = props;
  const { user } = props.idea;

  const { loadingUpIdea, loadingUpComment } = props;

  function upIdea() {
    dispatch(timelineActions.upIdea(idea._id, index));
  }

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function getCommentsIdea() {
    dispatch(
      timelineActions.getCommentsByIdea(
        idea._id,
        pageNumberComment,
        { createdAt: -1 },
        index
      )
    );
    setPageNumberComment(pageNumberComment + 1);
    handleExpandClick();
  }

  function goToIdeia() {
    history.push(`/idea/${idea._id}/general`);
  }

  function loadMoreComment() {
    dispatch(
      timelineActions.getCommentsByIdea(
        idea._id,
        pageNumberComment,
        { createdAt: -1 },
        index
      )
    );
    setPageNumberComment(pageNumberComment + 1);
  }

  function onSubmitComment(data) {
    const comment = {
      idea: idea._id,
      user: userLogged._id,
      comment: data.comment,
    };
    dispatch(timelineActions.newComment(comment, index));
    reset();
  }

  function favoriteIdea(id) {
    dispatch(timelineActions.favoriteIdea(id, index));
  }

  function openChallengeUrl(challengeId) {
    history.push(`/challenge/detail/${challengeId}`, "_blank");
  }

  function renderAttachmentVideo(attachments) {
    const videos = attachments.filter(
      (video) => video.mimeType.container === 'videos'
    );

    if (videos.length > 0) {
      return (
        <Grid item xs={12} key={videos[0]._id}>
          <video style={{ width: '100%' }} controls>
            <source src={videos[0].url} type={videos[0].mimeType.mimeType} />
            <object data={videos[0].url}>
              <embed src={videos[0].url} />
            </object>
          </video>
        </Grid>
      );
    }

    return null;
  }

  function renderAttachmentFiles(attachments) {
    const files = attachments.filter(
      (file) => file.mimeType.container === 'dynamic-files'
    );

    if (files.length > 0) {
      var AllFiles = files.map((file, index) => {
        return (
          <Grid item sm={12} md={'auto'} lg={6} key={index}>
            <CardAttachment
              fileName={file.fileName}
              fileSize={file.size}
              fileURL={file.url}
              fileExtension={file.mimeType.extension}
            />
          </Grid>
        );
      });
      return AllFiles;
    } else {
      return null;
    }
  }

  function renderAttachmentImages(attachments) {
    const images = attachments.filter(
      (image) => image.mimeType.container === 'images'
    );

    if (images.length > 0) {
      return <FbGridImage images={images} />;
    }

    return null;
  }

  function renderAttachmentLink(links) {
    var AllLinks = links.map((link, index) => {
      return (
        <div className={classes.linkContainer} key={index}>
          <Typography className={classes.linkTitle}>
            {`Link ${index + 1}:`}
          </Typography>
          <a
            className={classes.linkWeb}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link}
          </a>
        </div>
      );
    });
    return AllLinks;
  }

  function renderCanvas(canvas, type) {
    if (canvas.length > 0) {
      return (
        <Grid item sm={12} md={'auto'} lg={6}>
          <CanvasAttachment canvas={canvas} type={type} />
        </Grid>
      );
    } else {
      return null;
    }
  }

  function isFillCanvas(canvas) {
    if (!!canvas.length) {
      const hasAnswer = canvas.map((item) => item.answer?.length).some((length) => length > 0);
      return hasAnswer;
    };
    return false
  };

  function renderAttachmentsSection(idea) {
    const canvas = idea.canvas || [];
    const canvasValueProposition = idea.canvasValueProposition || [];
    const files = idea.attachments?.some(
      (file) => file.mimeType.container === 'dynamic-files'
    );
    if ((idea.attachments?.length > 0 && files) || isFillCanvas(canvas) || isFillCanvas(canvasValueProposition)) {
      return (
        <>
          <Divider style={{ margin: '20px 0 20px 0' }} />
          <Typography className={classes.titleAttachmentSection}>{t('anexos')}</Typography>
          <Grid container spacing={2} style={{ paddingTop: 16 }}>
            {idea?.attachments?.length > 0 && renderAttachmentFiles(idea.attachments)}
            {isFillCanvas(canvas) && renderCanvas(canvas, 'canvas')}
            {isFillCanvas(canvasValueProposition) && renderCanvas(canvasValueProposition, 'canvasValue')}
          </Grid>
        </>
      );
    }
  }

  return (
    <Card className={classes.root} elevation={0}>
      <div className={classes.author}>
        {idea?.contributors?.length > 0 ? (
          <AvatarGroupIdea user={user} idea={idea} />
        ) : (
        <Author
          activeUser={user.status}
          isTimeline={true}
          userAuthor={user}
          timelineDate={idea.timelineDate}
        />
        )}
        <div className={classes.favorite}>
          <CustomTooltip
            title={idea.favorite ? t('desfavoritar') : t('favoritar')}
            aria-label={t('favoritar')}
          >
            <IconButton
              onClick={() => favoriteIdea(idea._id)}
              className={classes.starBorder}
            >
              {idea.favorite ? (
                <StarRoundedIcon style={{ color: theme.palette.auxiliary.yellow.main }} />
              ) : (
                <StarBorderRoundedIcon />
              )}
            </IconButton>
          </CustomTooltip>
        </div>
      </div>


      <div className={classes.sectionTitleChallenge}>
        <Typography
          className={classes.sectionTitleBox}
          variant="subtitle2"
          color="primary"
          component="h1"
        >

        </Typography>
        {idea.challenge && idea.challenge.name && (
          <Link to={`/challenge/detail/${idea.challenge._id}`} target="_blank" className={classes.challengeLink}>
            <Typography
              className={clsx(
                classes.sectionChallengeBox,
                classes.sectionTitleChallengeBoxBackground,
                classes.challenge
              )}
              variant="subtitle2"
              color="primary"
              component="h1"
            >
              {idea.challenge.name}
            </Typography>
          </Link>

        )}
      </div>
      <div className={classes.sectionTitleIdea}>
        <Typography
          className={classes.sectionTitleBox}
          variant="subtitle2"
          color="primary"
          component="h1"
          onClick={goToIdeia}

        >
          {idea.name}
        </Typography>
      </div>

      <CardContent className={classes.cardContentBorderBottom}>
        <Typography
          color="textSecondary"
          component="div"
          style={{ fontSize: 14, whiteSpace: 'pre-line', paddingBottom: 16 }}
        >
          <ShowMoreText
            lines={6}
            anchorClass="readMoreText"
            more={t('visualizar_mais')}
            less={t('visualizar_menos')}
            truncatedEndingComponent={"..."}
          >
            <ImagineLinkify>
              {idea.description}
            </ImagineLinkify>
          </ShowMoreText>
          {idea.links?.length > 0 && (
            <div style={{ paddingTop: 16 }}>
              {renderAttachmentLink(idea.links)}
            </div>
          )}
          <Grid container spacing={2} style={{ paddingTop: 16 }}>
            {idea.attachments?.length > 0 && renderAttachmentVideo(idea.attachments)}
            {idea.attachments?.length > 0 && renderAttachmentImages(idea.attachments)}
          </Grid>
          {renderAttachmentsSection(idea)}
        </Typography>
        <Hidden smUp>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 16 }}>
              <span className={classes.link}>
                {idea.upsLength || 0}
                <span> {t('up')}</span>
                {idea.upsLength > 1 && <span>S</span>}
              </span>
            </div>
            <div>
              <span
                onClick={getCommentsIdea}
                aria-expanded={expanded}
                className={classes.link}
              >
                {idea.commentsLenght || 0}
                <span> {t('comentario').toUpperCase()}</span>
                {idea.commentsLenght > 1 && <span>S</span>}
              </span>
            </div>
          </div>
        </Hidden>
      </CardContent>
      <CardActions className={classes.cardActions}>
        { userLogged._id !== idea.user._id && (
        <Button
          color={idea.hasUserUpped ? 'primary' : 'default'}
          startIcon={<ThumbUpIcon />}
          onClick={upIdea}
          size="small"
          style={{ fontSize: 14.22 }}
          disabled={loadingUpIdea}
        >
          {t('up').toLowerCase()}
        </Button>
        )}
        <Button
          size="small"
          startIcon={<ChatBubbleIcon />}
          onClick={getCommentsIdea}
          style={{ fontSize: 14.22 }}
        >
          {t('comentar').toUpperCase()}
        </Button>
        <div className={classes.spacer}></div>
        <Hidden xsDown>
          <div>
            <span className={classes.link} onClick={handleClickOpen}>
              {idea.upsLength || 0}
              <span> {t('up').toUpperCase()}</span>
              {idea.upsLength > 1 && <span>S</span>}
            </span>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
            >
              <IdeaUpTitle
                id="customized-dialog-title"
                onClose={handleClose}
              />
              <DialogContent dividers>
                <IdeaUpContent
                  ups={idea.ups}
                />
              </DialogContent>
            </Dialog>
          </div>
          <div>
            <span
              onClick={getCommentsIdea}
              aria-expanded={expanded}
              className={classes.link}
            >
              {idea.commentsLenght || 0}
              <span> {t('comentario').toUpperCase()}</span>
              {idea.commentsLenght > 1 && <span>S</span>}
            </span>
          </div>
        </Hidden>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <form
            className={classes.ideaComment}
            onSubmit={handleSubmit(onSubmitComment)}
          >
            <TextField
              error={!!errors.comment}
              id="outlined-basic"
              label={t('adicione_um_comentario') + '...'}
              variant="filled"
              multiline
              fullWidth
              margin="dense"
              name="comment"
              inputRef={register({
                required: t('nao_pode_postar_uma_mensagem_em_branco'),
              })}
              className={classes.ideaCommentText}
              helperText={errors.comment && errors.comment.message}
            />
            <div>
              <Button
                className={classes.fontLowercase}
                color="primary"
                variant="contained"
                type="submit"
              >
                <Hidden smUp>
                  <SendIcon style={{ fontSize: 30 }} />
                </Hidden>
                <Hidden xsDown>{t('publicar')}</Hidden>
              </Button>
            </div>
          </form>
          {idea.comments &&
            idea.comments.map((comment, i) => (
              <IdeaComment
                comment={comment}
                key={comment._id}
                postIndex={index}
                commentIndex={i}
                loadingUpComment={loadingUpComment}
              />
            ))}
          {idea.comments &&
            idea.pagingComment &&
            idea.comments.length < idea.pagingComment.total && (
              <div
                className={classes.seeMoreComments}
                onClick={loadMoreComment}
              >
                {t('ver_mais_comentarios')}
              </div>
            )}
        </CardContent>
      </Collapse>
      {idea.upsLength < 1 && (
        <CardContent className={classes.firstUp}>
          <div className={classes.firstUpText}>
            {t('seja_o_primeiro_a_reagir')}!
          </div>
        </CardContent>
      )}
    </Card>
  );
}

export { Idea };
