import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import { useForm } from 'react-hook-form';
import DescriptionIcon from '@material-ui/icons/Description';
import { challengeActions } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { ChallengeStepWrapper } from "./ChallengeStepCard/ChallengeStepWrapper"
import { ChallengeStepHeader } from "./ChallengeStepCard/ChallengeStepHeader"
import { ChallengeStepHeaderIcon } from "./ChallengeStepCard/ChallengeStepHeaderIcon"
import { ChallengeStepContent } from "./ChallengeStepCard/ChallengeStepContent"
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  about: {
    position: 'relative',
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
}));

function ChallengeCreateCard() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { newChallenge: { name, description, goal }} = useSelector(state => state.challenge)
  const [descriptionChar, setDescription] = useState('');
  const { t } = useTranslation();
  const [goalChar, setGoal] = useState('');
  const { register, errors, getValues } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    dispatch(challengeActions.setChallenge({ name, description, goal }));
  }, [name, description, goal, dispatch])

  function handleDescription() {
    const description = getValues().description;
    setDescription(description);
  }

  function handleGoal() {
    const goal = getValues().goal;
    setGoal(goal);
  }

  function setPropChallenge({ target: { value, name } }) {
    dispatch(challengeActions.setPropChallenge(value, name));
  }

  return (
    <ChallengeStepWrapper>
      <ChallengeStepHeader>
        <ChallengeStepHeaderIcon icon={<DescriptionIcon color="primary" />} text={t('desafio')} />
      </ChallengeStepHeader>
      <ChallengeStepContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              name="name"
              id="name"
              label={t('nome_do_desafio')}
              autoComplete="name"
              type="text"
              variant="filled"
              fullWidth
              inputRef={register({
                required: t('titulo_do_desafio_e_obrigatorio'),
              })}
              error={!!errors.name}
              helperText={errors.name && errors.name.message}
              defaultValue={name}
              onBlur={setPropChallenge}
            />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.about}>
              <div className={classes.characters}>
                <span>{descriptionChar.length}/10000 caracteres</span>
              </div>
              <TextField
                error={!!errors.description}
                id="outlined-multiline-static"
                name="description"
                label={t('descricao_do_desafio')}
                multiline
                fullWidth
                minRows={8}
                variant="filled"
                onChange={handleDescription}
                inputRef={register({
                  maxLength: 10000,
                  minLength: 100,
                  required: t('descricao_do_desafio_e_obrigatorio'),
                })}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                defaultValue={description}
                onBlur={setPropChallenge}
              />
              <p className={classes.error}>
                {errors.description && errors.description.message}
                {errors.description &&
                  errors.description.type === 'maxLength' &&
                  'Você passou o limite de caracteres'}
                {errors.description &&
                  errors.description.type === 'minLength' &&
                  t('a_quantidade_minima_de_caracteres_da_descricao_e') +
                  ' 100'}
              </p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={clsx(classes.about, classes.marginBetweenInputs)}>
              <div className={classes.characters}>
                <span>{goalChar.length}/10000 caracteres</span>
              </div>
              <TextField
                error={!!errors.goal}
                id="outlined-multiline-static"
                name="goal"
                label={t('objetivos_do_desafio')}
                multiline
                fullWidth
                minRows={8}
                variant="filled"
                onChange={handleGoal}
                inputRef={register({
                  maxLength: 10000,
                  required: t('objetivos_do_desafio_e_obrigatorio'),
                })}
                InputLabelProps={{
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                defaultValue={goal}
                onBlur={setPropChallenge}
              />
              <p className={classes.error}>
                {errors.goal && errors.goal.message}
                {errors.goal &&
                  errors.goal.type === 'maxLength' &&
                  t('voce_passou_o_limite_de_caracteres')}
              </p>
            </div>
          </Grid>
        </Grid>
      </ChallengeStepContent>   
    </ChallengeStepWrapper>
  );
}

export { ChallengeCreateCard };
