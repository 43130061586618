import { ideaCreateDialogsConstans } from '../constants';
import update from 'react-addons-update';

const initialState = {
  dialogTitleDescription: false,
  dialogArea: false,
  dialogMultiArea: false,
  dialogClassification: false,
  dialogChallenge: false,
  dialogSendIdea: false,
  dialogPhoto: false,
  dialogVideo: false,
  dialogFile: false,
  dialogLinks: false,
  dialogCanvas: false,
  dialogValueCanvas: false,
  dialogClose: false,
  fromHistory: [],
};

export function ideaCreateDialogs(state = initialState, action) {
  switch (action.type) {
    case ideaCreateDialogsConstans.OPEN_DIALOG_TITLE_DESCRIPTION:
      return update(state, {
        dialogTitleDescription: { $set: true },
        dialogPhoto: { $set: false },
        dialogVideo: { $set: false },
        dialogFile: { $set: false },
        dialogLinks: { $set: false },
        dialogCanvas: { $set: false },
        dialogValueCanvas: { $set: false },
        dialogArea: { $set: false },
        dialogMultiArea: { $set: false },
        dialogClassification: { $set: false },
        from: { $set: '' },
        fromHistory: { $set: [] },
      });

    case ideaCreateDialogsConstans.OPEN_DIALOG_PHOTO:
      return update(state, {
        dialogPhoto: { $set: true },
        dialogTitleDescription: { $set: false },
        dialogSendIdea: { $set: false },
        fromHistory: { $set: action.payload?.from ? [ ...state.fromHistory, action.payload.from ] : state.fromHistory },
      });

    case ideaCreateDialogsConstans.OPEN_DIALOG_VIDEO:
      return update(state, {
        dialogVideo: { $set: true },
        dialogTitleDescription: { $set: false },
        dialogSendIdea: { $set: false },
        fromHistory: {
          $set:
             action.payload?.from ? [ ...state.fromHistory, action.payload.from ] : state.fromHistory,
        },
      });

    case ideaCreateDialogsConstans.OPEN_DIALOG_FILE:
      return update(state, {
        dialogFile: { $set: true },
        dialogTitleDescription: { $set: false },
        dialogSendIdea: { $set: false },
        fromHistory: {
          $set:
            action.payload?.from ? [ ...state.fromHistory, action.payload.from ] : state.fromHistory,
        },
      });

    case ideaCreateDialogsConstans.OPEN_DIALOG_CANVAS:
      return update(state, {
        dialogCanvas: { $set: true },
        dialogTitleDescription: { $set: false },
        dialogSendIdea: { $set: false },
        fromHistory: {
          $set:
            action.payload?.from ? [ ...state.fromHistory, action.payload.from ] : state.fromHistory,
        },
      });
    case ideaCreateDialogsConstans.OPEN_DIALOG_VALUE_CANVAS:
      return update(state, {
        dialogValueCanvas: { $set: true },
        dialogTitleDescription: { $set: false },
        dialogSendIdea: { $set: false },
        fromHistory: {
          $set:
            action.payload?.from ? [ ...state.fromHistory, action.payload.from ] : state.fromHistory
        },
      });
    case ideaCreateDialogsConstans.OPEN_DIALOG_LINKS:
      return update(state, {
        dialogLinks: { $set: true },
        dialogTitleDescription: { $set: false },
        dialogSendIdea: { $set: false },
        fromHistory: {
          $set:
            action.payload?.from ? [ ...state.fromHistory, action.payload.from ] : state.fromHistory
        },
      });

    case ideaCreateDialogsConstans.OPEN_DIALOG_AREA:
      return update(state, {
        dialogMultiArea: { $set: false },
        dialogTitleDescription: { $set: false },
        dialogSendIdea: { $set: false },
        dialogChallenge: { $set: false },
        dialogClassification: { $set: false },
        from: { $set: action.payload?.from },
        fromHistory: { $set: action.payload?.from ? [ ...state.fromHistory, action.payload.from ] : state.fromHistory },
      });

    
    case ideaCreateDialogsConstans.OPEN_DIALOG_MULTI_AREA:
      return {
        ...state,
        fromHistory: action.payload?.from ? [ ...state.fromHistory, action.payload.from ] : state.fromHistory,
        dialogChallenge: false,
        dialogSendIdea: false,
        dialogTitleDescription: false,
        dialogMultiArea: true,
        dialogClassification: false,
      }

    case ideaCreateDialogsConstans.OPEN_DIALOG_CLASSIFICATION:
      return {
        ...state,
        from: action.payload && action.payload.from ? action.payload.from : '',
        dialogTitleDescription: false,
        dialogMultiArea: false,
        dialogChallenge: false,
        dialogClassification: true,
        dialogSendIdea: false,
      }
    case ideaCreateDialogsConstans.OPEN_DIALOG_CHALLENGE:
      return update(state, {
        dialogChallenge: { $set: true },
        dialogClassification: { $set: false },
        dialogArea: { $set: false },
        dialogMultiArea: { $set: false },
        dialogSendIdea: { $set: false },
        fromHistory: {
          $set:
            action.payload?.from ? [ ...state.fromHistory, action.payload.from ] : state.fromHistory,
        },
      });

    case ideaCreateDialogsConstans.OPEN_DIALOG_SEND_IDEA:
      return update(state, {
        dialogSendIdea: { $set: true },
        dialogChallenge: { $set: false },
        dialogClassification: { $set: false },
        dialogArea: { $set: false },
        dialogMultiArea: { $set: false },
        dialogPhoto: { $set: false },
        dialogVideo: { $set: false },
        dialogFile: { $set: false },
        dialogLinks: { $set: false },
        fromHistory: { $set: action.payload?.from ? [ ...state.fromHistory, action.payload.from ] : state.fromHistory },
      });

    case ideaCreateDialogsConstans.OPEN_DIALOG_CLOSE:
      return update(state, {
        dialogTitleDescription: { $set: false },
        dialogArea: { $set: false },
        dialogMultiArea: { $set: false },
        dialogClassification: { $set: false },
        dialogChallenge: { $set: false },
        dialogSendIdea: { $set: false },
        dialogPhoto: { $set: false },
        dialogVideo: { $set: false },
        dialogFile: { $set: false },
        dialogLinks: { $set: false },
        dialogCanvas: { $set: false },
        dialogValueCanvas: { $set: false },
        dialogClose: { $set: true },
      });

    case ideaCreateDialogsConstans.CLOSE_ALL:
      return update(state, {
        dialogTitleDescription: { $set: false },
        dialogArea: { $set: false },
        dialogMultiArea: { $set: false },
        dialogClassification: { $set: false },
        dialogChallenge: { $set: false },
        dialogSendIdea: { $set: false },
        dialogPhoto: { $set: false },
        dialogVideo: { $set: false },
        dialogFile: { $set: false },
        dialogLinks: { $set: false },
        dialogCanvas: { $set: false },
        dialogValueCanvas: { $set: false },
        dialogClose: { $set: false },
        fromHistory: { $set: [] },
      });

    case ideaCreateDialogsConstans.PREVIOUS_DIALOG_CREATE_IDEA: {
      const lastFrom = state.fromHistory[state.fromHistory.length - 1];
      
      switch (lastFrom) {
        case 'sendIdea':
          return {
            ...initialState,
            dialogSendIdea: true,
            fromHistory: state.fromHistory.splice(0, state.fromHistory.length-1)
          }

        case 'DIALOG_SEND_IDEA':
          return {
            ...initialState,
            dialogSendIdea: true,
            fromHistory: state.fromHistory.splice(0, state.fromHistory.length-1)
          }

        case 'DIALOG_TITLE_DESCRIPTION': 
          return { 
            ...initialState,
            dialogTitleDescription: true,
            fromHistory: state.fromHistory.splice(0, state.fromHistory.length-1)
          }

        case 'DIALOG_MULTI_AREA': 
          return {
            ...initialState,
            dialogMultiArea: true,
            fromHistory: state.fromHistory.splice(0, state.fromHistory.length-1)
          }

        default:
          return state;
      }
    }

    default:
      return state;
  }
}
