import { feedbackConstants } from '../constants';
import { feedbackService } from '../services';
import { alertActions } from './alert.actions';

export const feedbackActions = {
  setFeedback,
  clearFeedback,
  openFeedback,
  discartFeedback,
  sendFeedback,
};

function setFeedback(feedbackText) {
  return (dispatch) => {
    dispatch({ 
      type: feedbackConstants.SET_FEEDBACK_TEXT, 
      payload: feedbackText
    });
  };
}

function clearFeedback() {
  return (dispatch) => {
    dispatch({
      type: feedbackConstants.CLEAR_FEEDBACK_TEXT,
    })
  }
}

function openFeedback(payload) {
  return (dispatch) => {
    dispatch({
      type: feedbackConstants.OPEN_FEEDBACK,
      payload
    })
  }
}

function discartFeedback(payload) {
  return (dispatch) => {
    dispatch({
      type: feedbackConstants.DISCART_FEEDBACK,
      payload
    })
  }
}

function sendFeedback(data) {
  return (dispatch) => {
    dispatch({ type: feedbackConstants.POST_USER_FEEDBACK_REQUEST })
    feedbackService.sendFeedback(data)
      .then(({ data }) => {
        dispatch({
          type: feedbackConstants.POST_USER_FEEDBACK_SUCCESS,
          payload: data.data,
        });
        dispatch(alertActions.success('O seu Feedback foi enviado!'))
      })
      .catch((error) => {
        dispatch({
          type: feedbackConstants.POST_USER_FEEDBACK_FAILURE,
          error
        });
      })
  }
}