import React, { useEffect, Fragment, useMemo } from 'react'
import { Grid, TextField, ButtonBase, makeStyles } from "@material-ui/core"
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from "react-redux"
import { multiAreaCascadeActions } from "./../../actions"
import DeleteIcon from '@material-ui/icons/Delete';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

const filterOptions = createFilterOptions({
  limit: 100,
})

const useStyles = makeStyles((theme) => ({
  trashButton: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  trashButtonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  skeletonWrapper: {
    borderRadius: 4
  }
}))

export const MultiAreaCascade = ({ 
  cutomLocals, 
  hasDeleteButton=false,
  clearAll=true,
  loadBusinessUnitLocals, 
  columns="horizontal",
}) => {
  const dispatch = useDispatch(); 
  const classes = useStyles();
  const { imagineLocals } = useSelector(state => state.domains)
  const { fields } = useSelector(state => state.multiAreaCascade)
  const { t } = useTranslation();

  useEffect(() => {
    if (cutomLocals) {
      dispatch(multiAreaCascadeActions.initialLoad(cutomLocals))
    } else if (imagineLocals) {
      dispatch(multiAreaCascadeActions.initialLoad(imagineLocals))
    }
  }, [cutomLocals, imagineLocals, dispatch])

  useEffect(() => {
    if (loadBusinessUnitLocals && 
      loadBusinessUnitLocals[0] && 
      loadBusinessUnitLocals.length > 0 && 
      imagineLocals && 
      imagineLocals.length > 0
    ) {
      dispatch(multiAreaCascadeActions.loadFields(loadBusinessUnitLocals, imagineLocals))
    }
  }, [dispatch, imagineLocals])

  useEffect(() => {
    return () => {
      if (clearAll) {
        dispatch(multiAreaCascadeActions.clearAll())
      }
    }
  }, [dispatch])


  function handleChangeField(unit, fieldIndex, newValue, field) {
    dispatch(multiAreaCascadeActions.changeField(unit, fieldIndex, newValue, imagineLocals, field))
  } 
  
  function handleDeleteBusinessField(fieldId) {
    dispatch(multiAreaCascadeActions.removeField(fieldId))
  }

  return (
    <Fragment>
      { fields.length > 0 ? (
        fields.map((field, index) => (
          <Grid container spacing={4} key={index}>
            <Grid item xs={hasDeleteButton ? 11 : 12}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={columns === "horizontal" ? 4 : 12}>
                  <Autocomplete
                    loadingText={t('carregando...')}
                    noOptionsText={t('sem_opcoes')}
                    filterOptions={filterOptions}
                    id={`business-${index}`}
                    loading={field.businessUnit.options.length === 0}
                    options={field.businessUnit.options}
                    getOptionLabel={(input) => input.label}
                    value={field.businessUnit.selectedOption}
                    onChange={ (e, newValue) => handleChangeField("businessUnit", index, newValue, field)}
                    renderInput={(params) => <TextField {...params} label={t('unidade_de_negocio')} variant="filled" /> }
                  />
                </Grid>
                <Grid item xs={12} md={columns === "horizontal" ? 4 : 12}>
                  <Autocomplete
                    loadingText={t('carregando...')}
                    noOptionsText={t('sem_opcoes')}
                    filterOptions={filterOptions}
                    id={`local-${index}`}
                    disabled={field.businessUnit.selectedOption.value === ""}
                    loading={field.local.options.length === 0}
                    options={field.local.options}    
                    value={field.local.selectedOption}
                    onChange={ (e, newValue) => handleChangeField("local", index, newValue, field)}
                    getOptionLabel={(input) => input.label}
                    renderInput={(params) => <TextField {...params} label={t('local')} variant="filled" /> }
                  />
                </Grid>
                <Grid item xs={12} md={columns === "horizontal" ? 4 : 12}>
                  <Autocomplete
                    loadingText={t('carregando...')}
                    noOptionsText={t('sem_opcoes')}
                    filterOptions={filterOptions}
                    id={`atuacao-${index}`}
                    disabled={field.local.selectedOption.value === ""}
                    loading={field.occupationArea.options.length === 0}
                    options={field.occupationArea.options}
                    value={field.occupationArea.selectedOption}
                    onChange={ (e, newValue) => handleChangeField("occupationArea", index, newValue, field)}
                    getOptionLabel={(input) => input.label}
                    renderInput={(params) => <TextField {...params} label={t('area')} variant="filled" /> }
                  />
                </Grid>
              </Grid>
            </Grid>
            { hasDeleteButton && fields.length > 1 && (
              <Grid item xs={1} sm={1} className={classes.trashButtonWrapper}>
                <ButtonBase
                  color="transparent"
                  variant="contained"
                  className={classes.trashButton}
                  onClick={() => handleDeleteBusinessField(index)}
                >
                  <DeleteIcon fontSize="large" />
                </ButtonBase>
              </Grid>
            )}
          </Grid>
        ))
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={columns === "horizontal" ? 4 : 12}>
            <Skeleton width="100%" height="53px" variant="rect" animation="wave" className={classes.skeletonWrapper} />
          </Grid>
          <Grid item xs={columns === "horizontal" ? 4 : 12}>
            <Skeleton width="100%" height="53px" variant="rect" animation="wave" className={classes.skeletonWrapper} />
          </Grid>
          <Grid item xs={columns === "horizontal" ? 4 : 12}>
            <Skeleton width="100%" height="53px" variant="rect" animation="wave" className={classes.skeletonWrapper} />
          </Grid>
        </Grid>
      )}
    </Fragment>
  )
}
