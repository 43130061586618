import React, { useState } from 'react';
import {
  makeStyles,
  TextField,
} from '@material-ui/core';
import { challengeActions } from '../../actions';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { useTranslation } from 'react-i18next';
import { ChallengeStepWrapper } from "./ChallengeStepCard/ChallengeStepWrapper"
import { ChallengeStepHeader } from "./ChallengeStepCard/ChallengeStepHeader"
import { ChallengeStepHeaderIcon } from "./ChallengeStepCard/ChallengeStepHeaderIcon"
import { ChallengeStepContent } from "./ChallengeStepCard/ChallengeStepContent"
import { useSelector } from "react-redux"

const useStyles = makeStyles((theme) => ({
  labelRoot: {
    fontWeight: 600,
    lineHeight: 1,
  },
  about: {
    position: 'relative',
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
  content: {
    padding: theme.spacing(4),
  },
}));

function ChallengeCriteriaCard() {
  const dispatch = useDispatch();
  const { newChallenge: { requirement } } = useSelector(state => state.challenge)
  const classes = useStyles();
  const { register, errors, getValues } = useForm({
    mode: 'onBlur',
  });
  const [requirementChar, setRequeriment] = useState('');
  const { t } = useTranslation();

  function handleRequirement() {
    const requirement = getValues().requirement;
    setRequeriment(requirement);
  }

  function onSubmit({target: {value}}) {
    dispatch(challengeActions.setCriteria(value));
  }  

  return (
    <ChallengeStepWrapper>
      <ChallengeStepHeader>
        <ChallengeStepHeaderIcon icon={<AssignmentTurnedInIcon color="primary" />} text={t('criterios')} />
      </ChallengeStepHeader>
      <ChallengeStepContent>
        <div className={classes.about}>
          <div className={classes.characters}>
            <span>{requirementChar.length}/10000 caracteres</span>
          </div>
          <TextField
            error={!!errors.requirement}
            id="outlined-multiline-static"
            name="requirement"
            label={t('criterios_e_requisitos')}
            multiline
            fullWidth
            minRows={8}
            variant="filled"
            onChange={handleRequirement}
            inputRef={register({
              maxLength: 10000,
              required: t('criterios_e_requisitos_e_obrigatorio'),
            })}
            InputLabelProps={{
              classes: {
                root: classes.labelRoot,
              },
            }}
            defaultValue={requirement}
            onBlur={onSubmit}
          />
          <p className={classes.error}>
            {errors.requirement && errors.requirement.message}
            {errors.requirement &&
              errors.requirement.type === 'maxLength' &&
              'Você passou o limite de caracteres'}
          </p>
        </div>
      </ChallengeStepContent>
    </ChallengeStepWrapper>
  );
}

export { ChallengeCriteriaCard };
