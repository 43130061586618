import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import { crudTemplateActions, faqActions } from '../../actions';
import { DialogImagine } from '../../components/Shared';
import { TemplateCrudLayout } from '../../components/TemplateCrud';
import { TemplateCrudNewPage } from '../TemplateCrudNewPage';
import { QuestionTable } from '../../components';


export function TemplateCrudPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { typePage, sidebarOptions, title, type, idToUpdate, openDeleteDialog } = useSelector(state => state.crudTemplate);
  const { questions } = useSelector(state => state.faq);

  const titleItemToDelete = !!idToUpdate.length && questions.filter(({ _id }) => _id === idToUpdate)[0]?.title;

  function handleDelete(){
    dispatch(faqActions.deleteQuestion(idToUpdate));
    handleOpenDialog(false);
  };

  function children() {
    switch(type) {
      case 'faq':
        return (<QuestionTable />);

      default:
        return(<></>)
    }
  };

  useEffect(() => {
    dispatch(crudTemplateActions.setType(sidebarOptions[0].pathname));
    dispatch(crudTemplateActions.setTitle(sidebarOptions[0].label));
  }, []);

  function handleOpenDialog(bool) {
    dispatch(crudTemplateActions.setOpenDeleteDialog(bool));
  };

  return (
    typePage === 'table' ? (
      <TemplateCrudLayout title={t(`${title}`)}>
        <DialogImagine
          open={openDeleteDialog}
          onClose={handleOpenDialog}
          title={`atenção, exclusão de ${titleItemToDelete}` + '!'}
          description={'ao excluir, os dados serão removidos do sistema de forma definitiva. Tem certaza de que deseja excluir?'}
        >
          <Box>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: 10 }}
              onClick={handleDelete}
            >
              {t('sim')}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleOpenDialog(false)}
            >
              {t('nao')}
            </Button>
          </Box>
        </DialogImagine>
        { children() }
      </TemplateCrudLayout>
    ) : (
      <TemplateCrudNewPage data={sidebarOptions} title={title} />
    )
  );
};
