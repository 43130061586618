import React from 'react'
import makeStyles from "@material-ui/styles/makeStyles"
import Box from "@material-ui/core/Box"
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  userPhaseStatus: {
    padding: "4px 6px",
    borderRadius: 4,
    fontSize: 11,
    fontWeight: "bold",
    color: "#fff",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
  },
  notApproved: {
    backgroundColor: "#FF1744"
  },
  awaiting: {
    backgroundColor: "#129FB5"
  },
  approved: {
    backgroundColor: "#00CC8D"
  },
}))

export const PhaseStatus = ({ status }) => {
  const classes = useStyles();

  return (
    <>
      { status === "approved" && (
        <Box className={clsx(classes.userPhaseStatus, classes.approved)}>aprovada</Box>
      )}

      { status === "notApproved" && (
        <Box className={clsx(classes.userPhaseStatus, classes.notApproved)}>não aprovada</Box>
      )}

      { status === "awaiting" && (
        <Box className={clsx(classes.userPhaseStatus, classes.awaiting)}>aguardando qualificação</Box>
      )}
    </>
  )
}
