import * as moment from 'moment';
import {
  Button,
  Grid,
  makeStyles,
  //FormControl, 
  //Select, 
  //InputLabel, 
  //MenuItem,  
  Box,
  TextField,
  Checkbox
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { KeyboardDatePicker } from '@material-ui/pickers';
import Search from '@material-ui/icons/Search';
import { formatDate } from '../../utils/date';
import { useSelector, useDispatch } from 'react-redux';
import { BusinessUnitPropsCheckboxList } from "./../../components"
import { Autocomplete } from "@material-ui/lab"
import { CheckBoxOutlineBlank, CheckBox as CheckboxIcon } from '@material-ui/icons';
import { challengeActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
    },
  },
  selectIdeias: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 20,
    },
  },
  selectLocal: {
    width: '100%',
  },
  selectUnits: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 16,
    },
  },
  label: {
    marginRight: 16,
    fontSize: 12,
    color: theme.palette.neutrals.low.medium,
  },
  containerInput: {
    display: 'flex',
    alignItems: 'center',
  },
  containerDates: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  containerDate: {
    maxWidth: 200,
    position: 'relative',
  },
  errorSpan: {
    position: 'absolute',
    color: theme.palette.auxiliary.red.main,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

function FiltersDashboard({ onFilterChange }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const today = new Date();
  const dt = new Date();
  dt.setMonth(dt.getMonth() - 1);
  const beforeMonth = dt;
  const { t } = useTranslation();
  const { statusIdea } = useSelector((state) => state.domains);
  const { local, occupationArea, businessUnit } = useSelector(state => state.businessUnitProps);
  const {
    challenges,
    paging: { page, sort, filter },
    loading,
  } = useSelector((state) => state.challenge);

  const [challenge, setChallenge] = useState([]);
  const [endDate, setEndDate] = useState(today);
  const [startDate, setStartDate] = useState(beforeMonth);

  // DOMAINS
  const [ideas, setIdeas] = useState([]);

  useEffect(() => {
    if (!loading) {
      dispatch(challengeActions.getChallenges(1, sort, filter));
    }
  }, [dispatch]);

  useEffect(() => {
    if (statusIdea) {
      const i = statusIdea.filter(item => item.code !== "DRAFT").map((b) => ({
        label: b.name,
        value: b.code,
        checked: false
      }));
      setIdeas([{
        checked: false,
        label: t('selecionar_todos'),
        value: 'SELECT_ALL'
      }, ...i]);
    }
  }, [statusIdea]);

  useEffect(() => {
    if (challenges) {
      const c = challenges.map((s) => ({
        label: s.name,
        value: s._id,
        checked: false
      }));
      setChallenge([{
        checked: false,
        label: t('selecionar_todos'),
        value: 'SELECT_ALL'
      }, ...c]);
    }
  }, [challenges]);

  function handleFilter() {
    onFilterChange({
      statusIdeaCode: ideas.filter(item => item.checked && item.value !== "SELECT_ALL").map((item) => item.value),
      statusChallengeCode: challenge.filter(item => item.checked && item.value !== "SELECT_ALL").map((item) => item.value),
      businessUnits: businessUnit.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      locals: local.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      occupationArea: occupationArea.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      endDate: formatDate(endDate),
      startDate: formatDate(startDate),
    });
  }

  const handleIdeaChange = (details) => {
    if (details) {
      const { option } = details;
      const updatedIdea = ideas.map((item) => {
        if (item.value === option.value) {
          return {
            ...item,
            checked: !item.checked
          }

        }
        return item;
      })
      if (option.value === 'SELECT_ALL') {
        setIdeas(ideas.map(item => ({...item, checked: option.checked ? false : true})));
      } else {
        setIdeas(updatedIdea);
      }
    } else {
      setIdeas(ideas.map(item => ({ ...item, checked: false })))
    }
  };

  const handleChallengeChange = (details) => {
    if (details) {
      const { option } = details;
      const updatedIdea = challenge.map((item) => {
        if (item.value === option.value) {
          return {
            ...item,
            checked: !item.checked
          }
        }
        return item;
      })
      if (option.value === 'SELECT_ALL') {
        setChallenge(challenge.map(item => ({...item, checked: option.checked ? false : true})));
      } else {
        setChallenge(updatedIdea);
      }
    } else {
      setChallenge(challenge.map(item => ({ ...item, checked: false })));
    }
  };

  const handleStartDate = (date) => {
    if (date instanceof Date && isFinite(date)) {
      setStartDate(date);
    }
  };

  const handleEndDate = (date) => {
    if (date instanceof Date && isFinite(date)) {
      setEndDate(date);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={3}>
        <Autocomplete
          multiple
          disableCloseOnSelect
          id="checkboxes-ideas"
          options={ideas}
          loading={ideas.length === 0}
          getOptionLabel={(input) => input.label}
          value={ideas.filter(item => item.checked)}
          onChange={(e, items, options, details) => handleIdeaChange(details)}
          renderInput={(params) => (
            <TextField {...params} label={t('status_das_ideias')} variant="filled" />
          )}
          renderTags={(option, state) => {
            return (<span>{option.length} {option.length > 1 ? "itens" : "item"} {option.length > 1 ? "selecionados" : "selecionado"}</span>)
          }}
          renderOption={(value) => (
            <li className={classes.flex}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckboxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={value.checked}
              />
              {value.label}
            </li>
          )}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <Autocomplete
          multiple
          disableCloseOnSelect
          id="checkboxes-challenge"
          options={challenge}
          loading={challenge.length === 0}
          getOptionLabel={(input) => input.label}
          value={challenge.filter(item => item.checked)}
          onChange={(e, items, options, details) => handleChallengeChange(details)}
          renderInput={(params) => (
            <TextField {...params} label="desafio" variant="filled" />
          )}
          renderTags={(option, state) => {
            return (<span>{option.length} {option.length > 1 ? "itens" : "item"} {option.length > 1 ? "selecionados" : "selecionado"}</span>)
          }}
          renderOption={(value) => (
            <li className={classes.flex}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckboxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={value.checked}

              />
              {value.label}
            </li>
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <KeyboardDatePicker
          variant="inline"
          inputVariant="filled"
          format="dd/MM/yyyy"
          disableFuture
          value={startDate}
          fullWidth
          id="date-picker-inline"
          label="inicio"
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          invalidDateMessage={
            <span className={classes.errorSpan}>
              Data de inicio inválida
            </span>
          }
          name="startDate"
          onChange={handleStartDate}
          autoOk
          maxDate={endDate}
          maxDateMessage={
            <span className={classes.errorSpan}>
              A data não pode ser futura ou maior que a data de termino
            </span>
          }
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <KeyboardDatePicker
          variant="inline"
          inputVariant="filled"
          format="dd/MM/yyyy"
          value={endDate}
          fullWidth
          disableFuture
          id="date-picker-inline-endDate"
          label={t('termino')}
          onChange={handleEndDate}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          invalidDateMessage={
            <span className={classes.errorSpan}>
              Data de termino invalida
            </span>
          }
          name="endDate"
          minDate={startDate}
          autoOk
          minDateMessage={
            <span className={classes.errorSpan}>
              A data de termino não pode ser inferior a data de inicio
            </span>
          }
          maxDateMessage={
            <span className={classes.errorSpan}>
              A data de termino não pode ser uma data futura.
            </span>
          }
        />
      </Grid>
      <Grid item xs={12} md={10}>
        <BusinessUnitPropsCheckboxList />
      </Grid>
      <Grid item xs={12} md={2}>
        <Box display="flex" alignItems="center" height="100%">
          <Button
            variant="contained"
            color="primary"
            endIcon={<Search />}
            onClick={handleFilter}
            size="large"
          >
            filtrar
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

export { FiltersDashboard };
