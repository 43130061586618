import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  makeStyles,
  withStyles,
  InputAdornment,
  FormControl,
  IconButton,
  FormHelperText,
  FilledInput,
  Hidden,
  CircularProgress,
  Box,
  Divider
} from '@material-ui/core';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import MicrosoftLogin from 'react-microsoft-login';

import { authenticationActions } from '../../actions/authentication.actions';
import config from '../../config';
import loginImage from '../../assets/imgs/login.svg';
import logoImagine from '../../assets/imgs/logo-haoc.png';
import logoOffice from '../../assets/imgs/microsoft-365.svg';
import clsx from 'clsx';
import { FooterAuthentication } from '../../components/Shared/FooterAuthentication';
import { deepOrange } from '@material-ui/core/colors';
import PersonIcon from '@material-ui/icons/Person';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import InfoIcon from '@material-ui/icons/Info';
import Autorenew from '@material-ui/icons/Autorenew';
// import GoogleBtn from './GoogleBtn';
import {
  GoogleLogin,
  GoogleLogout
} from 'react-google-login';
import LanguageSelect from '../../components/LanguageSelect/LanguageSelect';
import { useTranslation } from 'react-i18next';
import { alertActions } from '../../actions/alert.actions';
import { LoadingButton } from '../../components/Shared/LoadingButton';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.high.lightest,
    flexGrow: 1,
    height: '100vh',
    padding: theme.spacing(0),
    margin: 0,
  },
  loginGrid: {
    backgroundColor: 'white',
  },
  loginContainer: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.primary.main,
  },
  loginTitle: {
    fontSize: 28.83,
    fontWeight: 'bold',
    paddingBottom: theme.spacing(3),
  },
  subtitle: {
    fontSize: 14,
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  spaceBottom: {
    paddingBottom: theme.spacing(3),
  },
  presentation: {
    padding: theme.spacing(0, 12),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 60,
  },
  containerForm: {
    margin: theme.spacing(3, 0),
  },
  googleButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoImagine: {
    width: '100%',
    maxWidth: 220,
    [theme.breakpoints.down('md')]: {
      maxWidth: 200,
      marginLeft: -8,
    },
  },
  containerLogoImagine: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  containerDivider: {
    position: 'relative',
    textAlign: 'center',
  },
  content: {
    padding: theme.spacing(0, 7),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  divider: {
    '&::after': {
      content: '" "',
      position: 'absolute',
      width: 'calc(50% - 24px)',
      height: 1,
      backgroundColor: theme.palette.neutrals.high.medium,
      top: 8,
      right: 0,
    },
    '&::before': {
      content: '" "',
      position: 'absolute',
      width: 'calc(50% - 24px)',
      height: 1,
      backgroundColor: theme.palette.neutrals.high.medium,
      top: 8,
      left: 0,
    },
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      marginBottom: theme.spacing(2),
    },
  },
  containerImageBottom: {
    marginLeft: -15,
    marginRight: -15,
    marginBottom: theme.spacing(2),
  },
  fullWidth: {
    width: '100%'
  },
  descriptionSupport: {
    color: theme.palette.neutrals.low.light,
    textDecoration: 'none',
    textAlign:'center',
    transition: 'all ease-out .3s',
    marginBottom: theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  infoIcon:{
    fontSize:'0.9em' 
  },
  loading:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  resetPassword: {  
    width: 'auto',
    background: 'transparent',
    boxShadow: 'none',
    color: theme.palette.secondary.dark,
    fontSize: 13,
    fontWeight: 600,
    textAlign: 'center',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.secondary.dark,
      background: 'transparent',
      boxShadow: 'none',
      textDecoration: 'underline',
    },
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: deepOrange[500],
    borderColor: deepOrange[500],
    width: '100%',
    fontWeight: 600,
  },
}))(Button);

function LoginPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
  });
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const authentication = useSelector((state) => state.authentication);
const url = useLocation()
const [token, setToken] = useState(url.hash);
  const [shouldRenderOffice365, setShouldRenderOffice365] = useState(false);
  const TIME_TO_RENDER_OFFICE_BUTTON = 3000;

  const { loading } = authentication;

  function onSubmit({ email, password }) {
    if (email && password) {
      dispatch(authenticationActions.login(email, password, t('email_ou_senha_esta_incorreta')));
    }
  }

  function authHandler(err, data, msalInstance) {
    if (err !== null) return;
    let access_token = data?.accessToken || data?.authResponseWithAccessToken?.accessToken;
    loginOffice365(access_token);
  }

  function loginOffice365(token) {
    dispatch(authenticationActions.loginOffice365(token));
  }

  function authHandlerGoogle(err, data) {
    loginGoogle(data.accessToken);
  }

  function loginGoogle(token) {
    dispatch(authenticationActions.loginGoogle(token.tokenId));
  }

  useEffect(() => {
 
if (url.hash)
{
let _token = url.hash.split('#id_token=')[1].split("&")[0];
loginOffice365(_token);
}
  else
  {
    dispatch(authenticationActions.logout());
  }
}, [dispatch, url.hash]);

  useEffect(() => {
    setTimeout(() => setShouldRenderOffice365(true), TIME_TO_RENDER_OFFICE_BUTTON)
  }, []);

const clientId = `${config.GOOGLE_ID}`;
const gerror = response => {
  console.error(response) // eslint-disable-line
}

  return (
    <Box className={classes.root}>
      <Grid container style={{ height: '100vh' }}>
        
          <Grid item xs={12} md={12} lg={12} className={classes.presentation}>
            <div className={classes.containerLogoImagine}>
              <img
                className={classes.logoImagine}
                src={logoImagine}
                alt="logo-imagine"
              />
            </div>
            <h1 className={classes.title}>
              Olá colaborador HAOC,
              <br /> agora estamos integrados no Conecta:
              <a href="https://conectahaoc.com.br/home"
              >
                <div>https://conectahaoc.com.br/home</div>
              </a>
            </h1>
            <div>
              <img style={{ width: '100%' }} src={loginImage} alt="login"></img>
            </div>
          </Grid> 
 
      </Grid>
    </Box>
  );
}

export { LoginPage };
