import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Grid, Typography, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { StatusIdea } from "./../../components";
import { ideaEditStyles } from "./IdeaEditStyles";
import { ideaUpdateActions } from "./../../actions";
import { Author } from "./../../components/Shared";

export const IdeaEditGeneral = ({ ideaUpdate }) => {
  const classes = ideaEditStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { typeProfile, _id: userId } = useSelector((state) => state.user);

  function handleChange(e) {
    const { name, value } = e.target;
    dispatch(ideaUpdateActions.changeField(name, value));
  }

  function handleChallengeChange(data) {
    if (data) {
    dispatch(ideaUpdateActions.changeField('challenge', data.option));
    } else {
      dispatch(ideaUpdateActions.clearFieldChallenge(ideaUpdate.challenge));
    }
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <Author
            activeUser={ideaUpdate.user.status}
            paddingless={true}
            dateAndTime={ideaUpdate.createdAt}
            userAuthor={{...ideaUpdate.user, businessUnitLocal: ideaUpdate.businessUnitLocal}}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" marginRight={8}>
            <Typography variant="subtitle2" className={classes.textStatus}>{t('status')}:</Typography>
            <StatusIdea status={ideaUpdate.statusIdea} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="name"
            id="name"
            label={t('titulo')}
            autoComplete="name"
            type="text"
            variant="filled"
            fullWidth
            defaultValue={ideaUpdate.name}
            className={classes.box}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            id="description"
            label={t('descricao')}
            autoComplete="description"
            type="text"
            variant="filled"
            fullWidth
            multiline
            minRows={8}
            defaultValue={ideaUpdate.description}
            className={classes.box}
            onChange={handleChange}
          />
        </Grid>
        { (ideaUpdate?.contributors?.filter(item => item?._id === userId).length > 0 || ideaUpdate.user?._id === userId || typeProfile.type === 'admin') &&
          ideaUpdate.challengesList.length > 0 && (<>
          <Grid item xs={12}>
            <Autocomplete
              id="challenges-list"
              loadingText={t('carregando')}
              noOptionsText={t('sem_opcoes')}
              clearText={t('excluir_desafio')}
              clearOnEscape={true}
              options={ideaUpdate.challengesList}
              getOptionLabel={(option) => option.label || ''}
              getOptionSelected={(_option, value) => ideaUpdate?.challenge === value}
              renderInput={(params) => <TextField {...params} label={t('desafio')} variant="filled" />}
              loading={ideaUpdate.challengesList.length === 0}
              value={ideaUpdate?.challenge || null}
              onChange={(_e, _items, _options, details) => handleChallengeChange(details)}
            />
          </Grid>
        </>)}
      </Grid>
    </Grid>
  );
}
