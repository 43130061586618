import React, { useState } from 'react'
import Box from "@material-ui/core/Box"
import Avatar from "@material-ui/core/Avatar"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import makeStyles from "@material-ui/styles/makeStyles"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DoneIcon from '@material-ui/icons/Done';
import clsx from "clsx"
import moment from "moment"
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  phaseStatus: {
    width: 48,
    height: 48,
    fontSize: 16,
    marginRight: 16,
    fontWeight: "bold",
  },
  phaseConcluded: {
    backgroundColor: theme.palette.auxiliary.green.main,
    color: "#fff"
  },
  phaseActive: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff"
  },
  phaseFuture: {
    backgroundColor: "#fff",
    border: `1px solid ${theme.palette.grey.main}`,
    color: theme.palette.neutrals.low.dark,
  },
  phaseTitle: {
    color: theme.palette.neutrals.low.dark,
    fontWeight: "bold",
  },
  phaseDate: {
    color: theme.palette.neutrals.low.light,
  },
  phaseDescription: {
    color: theme.palette.neutrals.low.medium,
    marginLeft: 64
  },
}))

export const ChallengePhaseStep = ({ 
  currentPhase=false,
  concludedPhase=false,
  futurePhase=false,
  title,
  startDate,
  endDate,
  description,
  index,
}) => {
  const classes = useStyles();
  const [ open, setOpen ] = useState(false)
  const { t } = useTranslation();

  function toggleOpen() {
    setOpen(!open);
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            { concludedPhase && (
              <Avatar className={clsx(classes.phaseStatus, classes.phaseConcluded)}>
                <DoneIcon color="#fff" />
              </Avatar>
            )}

            { currentPhase && (
              <Avatar className={clsx(classes.phaseStatus, classes.phaseActive)}>
                {index+1}º
              </Avatar>
            )}

            { futurePhase && (
              <Avatar className={clsx(classes.phaseStatus, classes.phaseFuture)}>
                {index+1}º
              </Avatar>
            )}
        
            <Box display="flex" flexDirection="column">
              <Typography variant="subtitle1" className={classes.phaseTitle}>{title}</Typography>
              <Typography variant="body2" className={classes.phaseDate}>
                {`${moment(new Date(startDate)).format('DD/MM/YYYY')} ${t("ate")} ${moment(new Date(endDate)).format('DD/MM/YYYY')}`}
              </Typography>
            </Box>
          </Box>
          <IconButton onClick={toggleOpen}>
            { open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Box>
      </Grid>
      { open && (
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.phaseDescription}>{description}</Typography>
        </Grid>
      )}
    </Grid>
  );
}
