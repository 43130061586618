import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  IconButton,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import infoSvgRounded from '../../assets/imgs/info-rounded-48dp.svg';
import { Link } from 'react-router-dom';
import { helpCenterService } from '../../services/help-center.service';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
  root: {
    margin: 0,
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBotton: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    color: theme.palette.neutrals.low.medium
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 664,
    height: 596,
    display: 'flex',
    flexDirection: 'column',
  },
  iframe: {
    border: 0,
    marginTop: theme.spacing(3),
  },
  body: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(4),
    paddingBotton: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  textTop: {
    fontSize: 16,
    color: theme.palette.neutrals.low.medium,

  },
  textFooter: {
    fontSize: 14,
    color: theme.palette.neutrals.low.medium,
  },
  footer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(3),

  },
  infoIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  imageContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    paddingTop: 7,
    paddingBottom: 7,
    paddingRight: 8,

  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    textDecoration: 'none',
  }
}));


export function ModalWelcome({ openWelcomePopUp }) {
  const [open, setOpen] = useState(openWelcomePopUp);
  const [urlVideo, setUrlVideo] =  useState(openWelcomePopUp);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(openWelcomePopUp)
  }, [openWelcomePopUp])

  useEffect(() => {
    helpCenterService.getWelcomeVideo()
      .then(response => {
        setUrlVideo(response.data.value)
      })
      .catch((error) => {
        console.error(error);
      });
  }, [])

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth='lg'
    >
      <Box className={classes.dialog}>

        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography className={classes.title} gutterBottom>
            {t('boas_vindas_ao_imagine')}!
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.body} dividers>
          <Typography className={classes.textTop} gutterBottom>
            {t('esse_e_o_seu_primeiro_acesso')} {' '}
            {t('preparamos_um_tutorial_especial_para_te_ajudar_a_iniciar_sua_jornada_de_inovacao_aqui_no_imagine')}            
          </Typography>
          {urlVideo && (

              <video
                className={classes.iframe}
                width="600"
                height="338"
                controls>
                <source src={`${urlVideo}`} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
          )}
          <Box className={classes.footer}>
            <Box className={classes.imageContainer}>
              <img
                src={infoSvgRounded}
                alt="info"
                className={classes.infoIcon}
              />
            </Box>

            <Typography className={classes.textFooter} gutterBottom>
              {t('voce_pode_assistir_ao_video_novamente_basta_acessar_o')}{' '}
              <strong>{t('menu_superior_direito')}</strong> {' '}
              {t('e_selecionar_a')} {' '}
              <Link to="/help-center" className={classes.link} >
                {t('central_de_ajuda')}
              </Link>
              .
            </Typography>

          </Box>

        </DialogContent>
      </Box>

    </Dialog>

  );
}
