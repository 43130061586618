import React from 'react';
import { UploadFile } from './UploadFile';
import { uniqueId } from 'lodash';
import filesize from 'filesize';
import { ImageList } from './ImageList';
import { useDispatch, useSelector } from 'react-redux';
import { ideaCreateActions } from '../../actions';
import { VideoPreview } from './VideoPreview';
import { FileList } from './FileList';

function Upload({ type, img, name, message, accept, maxSize }) {
  const dispatch = useDispatch();
  const { images, video, files } = useSelector((state) => state.ideaCreate);

  function handleUpload(archives) {
    const newUploadedFiles = archives.map((file) => ({
      file,
      type: file.type,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      url: null,
    }));

    if (type === 'photo') {
      dispatch(ideaCreateActions.setImages(newUploadedFiles));
    }

    if (type === 'video') {
      dispatch(ideaCreateActions.setVideo(newUploadedFiles[0]));
    }

    if (type === 'file') {
      dispatch(ideaCreateActions.setFiles(newUploadedFiles));
    }
  }

  function render() {
    if (type === 'photo') {
      return images.length === 0 && <img src={img} alt={name} />;
    }

    if (type === 'video') {
      return !video.id && <img src={img} alt={name} />;
    }

    if (type === 'file') {
      return files.length === 0 && <img src={img} alt={name} />;
    }
  }

  return (
    <div>
      {images.length < 1 && type === 'photo' && (
        <UploadFile
          onUpload={handleUpload}
          name={name}
          message={message}
          accept={accept}
          maxSize={maxSize}
        >
          {render()}
        </UploadFile>
      )}

      {video && !video.id && type === 'video' && (
        <UploadFile
          onUpload={handleUpload}
          name={name}
          message={message}
          accept={accept}
          maxSize={maxSize}
        >
          {render()}
        </UploadFile>
      )}

      {files.length < 1 && type === 'file' && (
        <UploadFile
          onUpload={handleUpload}
          name={name}
          message={message}
          accept={accept}
          maxSize={maxSize}
        >
          {render()}
        </UploadFile>
      )}

      {type === 'photo' && !!images.length && <ImageList files={images} />}
      {type === 'video' && video && video.id && <VideoPreview video={video} />}
      {type === 'file' && !!files.length && <FileList files={files} />}
    </div>
  );
}

export { Upload };
