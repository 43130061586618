import React, { Fragment } from 'react';
import { Paper, Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useStyles } from "./styles";
import { useTranslation } from 'react-i18next';
import { ReportEmptyState } from './ReportEmptyState';

export const GraphicPanel = ({ children }) => {
  const { chart, loading } = useSelector(state => state.report)
  const classes = useStyles();
  const { t } = useTranslation();
  
  return (
    <Fragment>
      { loading ? (
        <Paper className={classes.noContent} elevation={0}>
          <Box display="flex" justifyContent="center" alignItems="center">
            {t('carregando_informacoes')}
          </Box>
        </Paper>
      ) : (
        <Fragment>
          { chart.length > 0 ? (
            <Paper className={classes.paperContent}>
            { children }
            </Paper>
            ) : (
            <ReportEmptyState />
          )}
        </Fragment>
      )}
    </Fragment>
  )
}
