import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { makeStyles, withStyles } from '@material-ui/core';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyle = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  text: {
    transform: 'none',
  },
}))(MuiSkeleton);

export function SkeletonIdeaEvaluation() {
  const classes = useStyle();

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Card elevation={0} className={classes.card}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.flex}>
              <Skeleton variant="circle" width={56} height={56} className={classes.avatar} />
              <Box style={{ marginLeft: 15 }}>
                <Skeleton variant="text" width={100} height={20} style={{ marginBottom: 4 }} />
                <Skeleton variant="text" width={100} height={17} style={{ marginBottom: 4 }} />
                <Skeleton variant="text" width={100} height={17} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" width={'60%'} height={24} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" width={'100%'} height={200} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card elevation={0} className={classes.card} style={{ padding: 24 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} className={classes.flex}>
              <Skeleton variant="circle" width={32} height={32} style={{ marginRight: 8 }} />
              <Skeleton variant="text" width={'40%'} height={28}  />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" width={140} height={24} style={{ marginBottom: 6 }} />
              <Skeleton variant="text" width={'100%'} height={28} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" width={140} height={24} style={{ marginBottom: 6 }} />
              <Skeleton variant="text" width={'100%'} height={28} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid> 
  );
}