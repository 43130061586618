import { faqConstants } from '../constants';
import update from 'react-addons-update';

const initalState = {
  questions: [],
  newQuestion: {
    title: '',
    description: '',
  },
  paging: {
    page: 1,
    sort: { createdAt: -1 },
    limit: 10,
    skip:0,
    total: 0,
  },
  loading: false,
};

export function faq(state = initalState, action) {
  switch (action.type) {
    case faqConstants.GET_ALL_QUESTIONS_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case faqConstants.GET_ALL_QUESTIONS_SUCCESS:
      return update(state, {
        questions: { $set: action.payload.questions.data },
        paging: {
          page: { $set: action.payload.page + 1 },
        },
        loading: { $set: false },
      });

    case faqConstants.GET_ALL_QUESTIONS_FAILURE:
      return update(state, {
        loading: { $set: false },
      });
  
    case faqConstants.GET_QUESTION_BY_ID_SUCCESS:
      return update(state, {
        questions: { $set: action.payload.questions._id },
      });

    case faqConstants.GET_QUESTION_BY_ID_FAILURE:
      return state;

    case faqConstants.SET_PAGE:
      return update(state, {
        paging: {
          page: { $set: action.payload.page },
        },
      });

    case faqConstants.SET_LIMIT:
      return update(state, {
        paging: {
          limit: { $set: action.payload.limit },
        },
      });

    default:
      return state;
  }
}
