import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { 
  ChallengeDetailsMenu,
  ChallengeDetailsContent,
  ChallengeFollowUp,
  DynamicChallengePhaseTabs,
  ChallengeAwardWinningIdeas,
} from '../../components/Challenge';
import { ChallengeIdeaTable } from '../../components/Tables';
import { DialogAllCreateIdea } from '../../components/CreateIdeaDialogs';
import { BackNavAdmin, BackNavAdminButtons } from '../../components/Shared';
import { SkeletonTable, SkeletonChallengeDetails } from '../../components/Skeletons';
import { challengeActions, ideaCreateDialogsActions, ideaCreateActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
  },
  containerLoading: {
    marginTop: theme.spacing(30),
    display: 'flex',
    justifyContent: 'center',
  },
  media: {
    height: 206,
    // backgroundColor: theme.palette.neutrals.low.light,
    borderRadius: 8,
    marginBottom: theme.spacing(3)
  },
  cronogramaTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.neutrals.low.dark,
    textTransform: "uppercase"
  },
  cardContent: {
    padding: theme.spacing(4),
  }
}));

export function ChallengeDetailPage() {
  const { id } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tabChosed, setTabChosed] = useState(0);
  const [ selectedPhase, setSelectedPhase ] = useState(0);
  const {
    loading,
    loadingPhaseIdeas,
    challengeDetail,
    challengeTableIdeas: { ideas, paging },
  } = useSelector((state) => state.challenge);
  const { typeProfile: { type } } = useSelector((state) => state.user);

  useEffect(() => {
    let status = [];
    if (type === 'colaborator') {
      status = [{
        text: 'conluído',
        value: 'CONCLUDED',
        checked: true
      }, {
        text: 'pendente de patente',
        value: 'PATENT_PENDING',
        checked: true
      }, {
        text: 'file de execução',
        value: 'EXECUTION_QUEUE',
        checked: true
      }, {
        text: 'em execução',
        value: 'EXECUTING',
        checked: true
      }, {
        text: 'qualificado',
        value: 'QUALIFIED',
        checked: true
      }]
    } else {
      status = [{
        text: 'conluído',
        value: 'CONCLUDED',
        checked: true
      }, {
        text: 'pendente de patente',
        value: 'PATENT_PENDING',
        checked: true
      }, {
        text: 'file de execução',
        value: 'EXECUTION_QUEUE',
        checked: true
      }, {
        text: 'em execução',
        value: 'EXECUTING',
        checked: true
      }, {
        text: 'qualificado',
        value: 'QUALIFIED',
        checked: true
      }, {
        text: 'aguardando qualificação',
        value: 'AWAITING_QUALIFICATION',
        checked: true
      }]
    }
    dispatch(challengeActions.setFilter('statusCode', status));
    dispatch(challengeActions.getIdeasChallenge(
      id,
      1,
      paging.sort,
      paging.limit,
      status.map(item => item.value),
    ));
    dispatch(challengeActions.getChallengeById(id));

    return () => dispatch(challengeActions.resetFilters())
  }, [dispatch, type, id]);

  useEffect(() => {
    if (Object.keys(challengeDetail).length !== 0 && !challengeDetail.phases) {
      if (type === 'colaborator') {
        dispatch(challengeActions.setFilterColaborator());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challengeDetail.phases]);

  // Tab 1 FollowUp
  useEffect(() => {
    if (tabChosed === 1 && challengeDetail.phases) {
      dispatch(challengeActions.getIdeasByPhase(
        challengeDetail.phases.filter((item, index) => (selectedPhase) === index)[0]._id,
        1,
        paging.sort,
        paging.limit,
        paging.statusCode.filter(item => item.checked).map(item => item.value),
      ));
    }
    // eslint-disable-next-line
  }, [tabChosed, challengeDetail.phases, selectedPhase ]);

  // Tab 2 Award
  useEffect(() => {
    if (tabChosed === 2) {
      dispatch(challengeActions.getIdeasChallenge(
        id,
        1,
        paging.sort,
        paging.limit,
        paging.statusCode.filter(item => item.checked).map(item => item.value)
      ));
  
      return () => {
        dispatch(challengeActions.resetFilters())      
        dispatch(challengeActions.clearAllWinners());
      }
    }
    
}, [dispatch, tabChosed, id]);

  useEffect(() => {
    if (tabChosed === 2 && challengeDetail.rewards?.length > 0) {
      challengeDetail.rewards.forEach(item => {
        dispatch(challengeActions.setWinners({
          position: parseInt(item.index),
          idea: item.win
        }));
      })
    }
  }, [tabChosed]);

  function createIdea() {
    const challenge = {
      _id: challengeDetail._id,
      name: challengeDetail.name,
      createdAt: challengeDetail.createdAt,
      status: true,
    };

    dispatch(ideaCreateActions.setChallenge(challenge));
    dispatch(ideaCreateActions.setIdeaCreateChallenge());
    dispatch(ideaCreateDialogsActions.openDialogTitleDescription());
  }

  function handleChangePhase(phase) {
    if (!loadingPhaseIdeas) {
      setSelectedPhase(phase);
    }
  }

  return (<>
    <DialogAllCreateIdea challenge />

    <BackNavAdmin title={loading ? t('carregando...') : challengeDetail.name} pathname="/challenges">
      <BackNavAdminButtons type={type} id={id} createIdea={createIdea} />
    </BackNavAdmin>
    <Container maxWidth="lg" className={classes.container}>
      {loading ? (
        <SkeletonChallengeDetails />
      ) : (<>
        <ChallengeDetailsMenu tabChosed={tabChosed} handleChangeTab={setTabChosed} type={type} />
        {tabChosed === 0 && (
          <ChallengeDetailsContent 
            id={id} 
            ideas={ideas} 
            challengeDetail={challengeDetail} 
            paging={paging} 
            type={type}
            loading={loading}
          />
        )}

        {tabChosed === 1 && (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <ChallengeFollowUp
                daysAway={challengeDetail.daysAway}
                daysLeft={challengeDetail.daysLeft}
                dueDate={challengeDetail.dueDate}
                ups={challengeDetail.ups}
                allIdeas={challengeDetail.ideas}
                comments={challengeDetail.comments}
                topUser={challengeDetail.topUser}
                topArea={challengeDetail.topArea}
                totalParticipants={challengeDetail.totalParticipants}
                totalIdeasInExecutionQueue={challengeDetail.totalIdeasInExecutionQueue}
                totalIdeasInAwaitingQualification={challengeDetail.totalIdeasInAwaitingQualification}
              />
            </Grid>
            {challengeDetail.phases && (
              <Grid item xs={12}>
                <DynamicChallengePhaseTabs 
                  selectedPhase={selectedPhase} 
                  phases={challengeDetail.phases} 
                  handleChangePhase={handleChangePhase} 
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {loadingPhaseIdeas 
                ? <SkeletonTable cols={7} />
                : <ChallengeIdeaTable id={id} hasPhase={challengeDetail.phases ? true : false} />
              }
            </Grid>
          </Grid>
        )}

        {tabChosed === 2 && (
          <ChallengeAwardWinningIdeas id={id} ideas={ideas} paging={paging} />
        )}
      </>)}
    </Container>
  </>);
}
