import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
	FormControl,
	Typography,
	Box,
	Divider,
	Menu,
	RadioGroup,
	FormControlLabel,
	Radio
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SortIcon from '@material-ui/icons/Sort';
import config from '../../../config';
import { useWindowSize } from '../../../hooks';
import { infraStructureActions } from '../../../actions';
import { ButtonBase, FilterTitle } from './styles';

export function InfrastructureClassificationFilter({ tabChosed }) {
	const [width] = useWindowSize();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState(null);
	const { filters: { dateSort }, paging: { page, limit, query }, selectedTab } = useSelector(state => state.infraStructure);

	function handleChange(event) {
		const { value } = event.target;
		dispatch(infraStructureActions.changeClassificationFilter(value));

		if (tabChosed === 0) {
			dispatch(infraStructureActions.getInfraStructure({ page, limit, types: [selectedTab], dateSort: value }));
		} else {
			dispatch(infraStructureActions.getAllAssociations(page, limit, value, query))
		}			
	}

	function handleOpen(event) {
		setAnchorEl(event.currentTarget);
	}

	function handleClose() {
		setAnchorEl(null);
	}

	return (<>
		<ButtonBase onClick={handleOpen}>
			<Box width="100%" display="flex" justifyContent="space-evenly" alignItems="center">
				{width > config.RESPONSIVE_MOBILE ? (
					<>
						<SortIcon />
						<Typography variant="subtitle2" style={{ padding: '0 13px' }}>{t('classificacao')}</Typography>
					</>
				) : (
					<SortIcon />
				)}
				<ArrowDropDownIcon />
			</Box>
		</ButtonBase>
		<Menu
			elevation={2}
			getContentAnchorEl={null}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={handleClose}
		>
			<FilterTitle variant="subtitle2">{t('classificar_por')}</FilterTitle>
			<Divider />
			<Box display="flex" flexDirection="column" padding="24px">
				<FormControl>
					<RadioGroup
						aria-label="infrastructure-classification"
						name="infrastructure-classification"
						value={dateSort}
						onChange={handleChange}
					>
						<FormControlLabel name="dateSortAsc" value="ASC" checked={dateSort === "ASC"} control={<Radio />} label={t('data_em_ordem_crescente')} />
						<FormControlLabel name="dateSortDesc" value="DESC" checked={dateSort === "DESC"} control={<Radio />} label={t('data_em_ordem_decrescente')} />
						{/* <FormControlLabel name="occupationAreaSort" value="A-Z" checked={dateSort === "A-Z"} control={<Radio />} label={t('unidade_de_negocio_a_z')} /> */}
					</RadioGroup>
				</FormControl>
			</Box>
		</Menu>
	</>);
}