import React from 'react';
import { 
  makeStyles,
  Box,
  Card,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,   
  useTheme,  
} from '@material-ui/core';
import Event from '@material-ui/icons/Event';
import History from '@material-ui/icons/History';
import WatchLaterOutlined from '@material-ui/icons/WatchLaterOutlined';

import { Approver } from '../Shared';
import { dateFormat, timeFormat } from '../../helpers';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.neutrals.high.medium
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0, 3)
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    paddingBottom: theme.spacing(3),
    color: theme.palette.neutrals.low.dark,
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    textTransform: 'lowercase',
    paddingRight: theme.spacing(1),
    color: theme.palette.neutrals.low.dark,
  },
  content: {
    fontSize: 14,
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    paddingTop: theme.spacing(0.8)
  },
  status: {
    fontSize: 11.24,
    fontWeight: 600,
    textTransform: 'uppercase',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0.5, 1),
    color: theme.palette.neutrals.high.main,
  },
  historyIcon: {
    background: theme.palette.primary.light,
  },
  historyDescription: {
    fontSize: 14,
    color: theme.palette.neutrals.low.dark,
    padding: theme.spacing(1, 1, 0),
  },
  historyDate: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
  },
  iconDate: {
    color: theme.palette.neutrals.low.light,
    marginRight: 5
  },
  bar: {
    position: 'absolute',
    borderLeft: '2px solid',
    height: 29,
    left: 19,
    top: 63,
    borderColor: theme.palette.primary.main
  },
}));

function IdeaQualification({ idea, createdAt, approveDate }) {
  const classes = useStyles();
  const { approvedBy } = idea;
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const theme = useTheme();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography component="h1" className={classes.title}>
          {t('qualificacao')}
        </Typography>

        <Card className={classes.card} elevation={0}>
          <div style={{marginLeft: -16}}>
            <Approver approvedBy={approvedBy} dateAndTime={approveDate} />
          </div>
        
          <Box className={classes.box}>
            <Typography className={classes.subTitle}>
              {t('qualificacao')}:
            </Typography>
            <span className={classes.status} style={{ background: idea.statusIdea.color }}>
              { idea.statusIdea.name }
            </span>
          </Box>

          {(idea.user?._id === user._id || user.typeProfile.type === 'admin' || user.typeProfile.type === 'manager') && (
            <>
              <Typography className={classes.subTitle}>
                {t('observacao_do_gestor')}:
              </Typography>
              <div className={classes.content}>{idea.evaluation}</div>
            </>
          )}
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Typography component="h1" className={classes.title} style={{ paddingTop: 24 }}>
          {t('historico')}
        </Typography>

        <Card className={classes.card} elevation={0}>
          { idea &&
            idea.history &&
            idea.history.length > 0 &&
            idea.history.map((history, index) => (

            <List className={classes.root}>
              <ListItem key={history._id} style={{ padding: 0 }}>
                <ListItemAvatar >
                  <Avatar className={classes.historyIcon}>
                    <History color="primary" />
                  </Avatar>
                </ListItemAvatar>
                { index !== (idea.history.length - 1) &&
                  <span className={classes.bar}></span>
                }
                <ListItemText 
                  className={classes.historyDescription}
                  primary={
                    <>
                      {history && history.status && history.status.value + ' ' + t('por') + ' '}
                      <strong>{history.user && history.user.name}</strong>
                    </>
                  } 
                  secondary={
                    <div className={classes.historyDate}>
                      <Event className={classes.iconDate}/>
                      {dateFormat(history.createdAt)}
                      <div style={{ marginRight: 15 }}></div>
                      <WatchLaterOutlined className={classes.iconDate} />
                      {timeFormat(history.createdAt)}hs
                    </div>
                  } 
                />
              </ListItem>
            </List>

          ))}
        </Card>
      </Grid>
    </Grid>
  );
}

export { IdeaQualification };
