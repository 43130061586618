import React, { useState } from 'react'
import { useSelector, useDispatch } from "react-redux"
import Card from "@material-ui/core/Card"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { PortifolioTitle } from "./../PortifolioTitle";
import makeStyles from "@material-ui/styles/makeStyles";
import planjemantoFinanceiroIcon from '../../../assets/imgs/planjemantoFinanceiroIcon.svg';
import { TagIcon } from "./../TagIcon";
import { CostsList } from "./../Details/CostsList";
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { SkeletonFinancialPlanning } from '../Details/SkeletonGeneralData';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import Divider from "@material-ui/core/Divider"
import { portfolioDialogFinnacialActions } from "./../../../actions/portfolio/portfolio-dialog-finnacial.actions";
import { formatBRLFromFloat, formatRealToNumber } from "./../../../helpers";
import { DialogCreateFinancialPlanning } from "./../DialogCreateFinancialPlanning";
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
    borderRadius: '8px',
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  tableTitle: {
    color: theme.palette.neutrals.low.dark,
    fontSize: 14,
    fontWeight: 600,
  },
  spacingTop: {
    paddingTop: theme.spacing(4)
  },
  divider: {
    marginTop: theme.spacing(4)
  },
  iconMoneyOff: {
    color: theme.palette.auxiliary.red.main
  },
  iconAttachMoney: {
    color: theme.palette.auxiliary.green.main
  },
}));

export const CardFinacialPlanning = () => {
  const { loading, financialPlanning } = useSelector(state => state.portfolioDetails);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const [openFinancialPlanning, setOpenFinancialPlanning] = useState(false);


  function handleOpenFinancialPlanning() {
    if (!openFinancialPlanning && financialPlanning.details !== null) {
      dispatch(portfolioDialogFinnacialActions.loadCreateFinancialPlanningDialog(financialPlanning.details));
    }
    setOpenFinancialPlanning(!openFinancialPlanning);
  };

  function totalFinancial() {
    const costsArray = financialPlanning.details?.costs?.map((item) => item.estimatedValue) || [];
    return formatBRLFromFloat(costsArray.reduce((previousValue, currentValue) => previousValue + parseFloat(formatRealToNumber(currentValue)), 0))
  };

  return (
    <>
      { openFinancialPlanning && <DialogCreateFinancialPlanning open={openFinancialPlanning} onClose={handleOpenFinancialPlanning} projectId={id} /> }
      <Card elevation={0} className={classes.card}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <PortifolioTitle
            title='planejamento financeiro'
            icon={ <img src={planjemantoFinanceiroIcon} alt="financial plan icon" style={{ width: 25 }} /> }
          />
          {financialPlanning.details !== undefined && !!financialPlanning.details?.costs?.length ? (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<EditIcon />}
              onClick={handleOpenFinancialPlanning}
              disabled={loading}
            >
              editar planejamento
            </Button>
          ) : (
            <Button
              color="primary"
              startIcon={<AddCircleIcon />}
              className={classes.button}
              onClick={handleOpenFinancialPlanning}
              disabled={loading}
            >
              adicionar planejamento
            </Button>
          )}
        </Box>
        
        { financialPlanning.loading ? (
          <SkeletonFinancialPlanning />
        ) : (
          <>
            { financialPlanning.details?.costs?.length > 0 && (
              <Box className={classes.spacingTop}>
                <Box style={{ marginBottom: '24px' }}>
                  <TagIcon
                    title='estrutura de custos'
                    icon={<MoneyOffIcon fontSize="small" className={classes.iconMoneyOff} />}
                  />
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography className={classes.tableTitle}> despesas</Typography>
                  <Box style={{ minWidth: '240px' }} >
                    <Typography className={classes.tableTitle}> R$ estimado</Typography>
                  </Box>
                </Box>

                <CostsList />

                <Box display="flex" alignItems="center" justifyContent="space-between" style={{ marginTop: '16px' }}>
                  <Typography className={classes.tableTitle}> total</Typography>
                  <Box style={{ minWidth: '240px' }} >
                    <Typography className={classes.tableTitle}>{`R$ ${totalFinancial()}`}</Typography>
                  </Box>
                </Box>
                <Divider className={classes.divider} />
                <Box style={{ margin: '32px 0 24px 0' }}>
                  <TagIcon
                    title='retorno financeiro'
                    icon={<AttachMoneyIcon fontSize="small" className={classes.iconAttachMoney} />}
                  />
                </Box>
                <Box display="flex" alignItems="center" justifyContent="start">
                  <Typography style={{ maxWidth: '240px', margin: '0 24px 0 0' }} className={classes.tableTitle}>valor estimado:</Typography>
                  <Typography>
                    {financialPlanning.details !== undefined && `R$ ${financialPlanning.details?.estimatedFinancialReturn}`}
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        )}
      </Card>
    </>
  )
}
