import { profileConstants } from '../constants';
import update from 'react-addons-update';

const initialState = {
  user: null,
  ideas: [],
  loading: false,
  paging: {
    page: 1,
    total: 0,
    limit: 10,
    skip: 0,
    sort: { createdAt: -1 },
  },
};

export function profile(state = initialState, action) {
  switch (action.type) {
    case profileConstants.GET_USER_PROFILE_SUCCESS:
      return update(state, {
        user: { $set: action.payload.user },
      });

    case profileConstants.GET_USER_PROFILE_FAILURE:
      return state;

    case profileConstants.GET_IDEAS_PROFILE_REQUEST: 
      return update(state, {
        loading: { $set: true }
      });

    case profileConstants.GET_IDEAS_PROFILE_SUCCESS:
      return update(state, {
        ideas: { $set: action.payload.ideas },
        paging: {
          total: { $set: action.payload.paging.total },
        },
        loading: { $set: false }
      });

    case profileConstants.GET_IDEAS_PROFILE_FAILURE:
      return state;

    case profileConstants.SET_LIMIT:
      return update(state, {
        paging: {
          limit: {
            $set: action.payload.limit,
          },
        },
      });

    case profileConstants.SET_PAGE:
      return update(state, {
        paging: {
          page: {
            $set: action.payload.page,
          },
        },
      });

      case profileConstants.GET_IDEAS_PROFILE_MOBILE_REQUEST:
        return update(state, {
          loading: { $set: true },
        });
    
      case profileConstants.GET_IDEAS_PROFILE_MOBILE_SUCCESS:
        return update(state, {
          ideas: { $push: action.payload.ideas },
          paging: {
            page: { $set: parseInt(action.payload.paging.page) + 1 },
            total: { $set: action.payload.paging.total },
          },
          loading: {
            $set: false,
          },
        });
  
      case profileConstants.GET_IDEAS_PROFILE_MOBILE_FAILURE:
        return state;

    default:
      return state;
  }
}
