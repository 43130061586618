export default {
  control: {
    backgroundColor: '#fff',
    fontSize: 13.7,
    fontWeight: 'normal',
  },
  highlighter: {
    overflow: 'hidden',
  },
  input: {
    margin: 0,
    width: '100%',
  },
  '&singleLine': {
    control: {
      display: 'inline-block',
      width: '100%',
      color: '#fff',
    },
    highlighter: {
      color: '#7E0386',
    },
    input: {
      padding: 1,
      border: 'none',
    },
  },
  suggestions: {
    width: '100%',
    top: '15px',
    
    list: {
      backgroundColor: '#fff',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      maxHeight: '100vh',
      overflowY: 'auto',
      zIndex: 9999,
    },
    item: {
      padding: '15px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',

      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};