import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Typography,
    Box,
    Popover
} from '@material-ui/core';

import Icon from '@material-ui/core/Icon';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    margin: {
        paddingLeft: theme.spacing(2),
        width: '100%',
    },
    select: {
        padding: theme.spacing(1.2, 2, 0.3, 2),
    backgroundColor: theme.palette.primary.lightest,
        borderStyle: 'solid',
    borderColor: theme.palette.primary.lightest,
        borderWidth: 2,
        borderRadius: theme.spacing(0.5),
        color: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 14,
        textTransform: 'lowercase',
        fontWeight: 600,
    transition: 'border-color 0.5s ease',
    cursor: 'pointer',
    },
    border: {
        borderColor: theme.palette.primary.main,
    },
    option: {
        display: 'flex',
    '&:hover': {
      color: theme.palette.primary.main
    }
    },
    title: {
        fontSize: 14,
        paddingLeft: theme.spacing(1),
    fontWeight: 600,
    cursor: 'pointer',
    },
    dropDownIcon: {
        color: theme.palette.neutrals.low.medium,
        fontSize: 24,
    cursor: 'pointer',
    },
    dropUpIcon:{
        color: theme.palette.primary.main,
        fontSize: 24,
    cursor: 'pointer',
    },
    paper: {
        padding: theme.spacing(1.2, 2, 1.2, 2),
    width: '100%',
    }
}));

const options = {
    'idea': {
      icon: 'emoji_objects',
      title: 'ideia_geral',
    },
    'general': {
      icon: 'emoji_objects',
      title: 'ideia_geral',
    },
    'conclusion': {
      icon: 'emoji_objects',
      title: 'conclusao',
    },
    'result': {
      icon: 'emoji_objects',
      title: 'resultado',
    },
    'engagement': {
      icon: 'emoji_objects',
      title: 'engajamento',
    },
    'qualification': {
      icon: 'thumb_up',
      title: 'qualificacao',
    },
    'edit-profile': {
      icon: 'person',
      title: 'editar_perfil'
    },
    'notifications': {
      icon: 'notifications_active',
      title: 'central_de_notificacoes'
    },
    'primeiro-acesso': {
      icon: 'play_circle_filled_icon',
      title: 'primeiro_acesso'
    }, 
    'tutoriais': {
      icon: 'assistant_icon',
      title: 'tutoriais'
    },
   'faq': {
      icon: 'help_icon',
      title: 'duvidas_frequentes'
    }, 
    'administradores': {
      icon: 'account_box',
      title: 'administradores'
    },
  'atribuir-funcao': {
    icon: 'description',
    title: 'atribuir_funcao_ao_usuario'
  },
  'editar-dados': {
    icon: 'edit',
    title: 'editar_dados_do_usuario'
  },
  'gerenciar-pontos': {
    icon: 'account_balance_wallet',
    title: 'gerenciar_pontos'
  },
}

// {optionsAvaiable = [optionConstants.IDEA, optionConstants.QUALIFICATION], startOption = optionConstants.IDEA}, handlePage

function CustomSelect(props) {
    const classes = useStyles();
    const [value, setValue] = useState(props.startOption);
    const [anchorEl, setAnchorEl] = useState(null)
    const { t } = useTranslation();
    const theme = useTheme();
    function handleChange(val){
        setValue(val);
        setAnchorEl(null);
        setTimeout(()=>{
            props.handlePage(val);
        }, 150)
    }

    function handlePopoverOpen(e) {
        setAnchorEl(e.currentTarget);
    }

    function handlePopoverClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);

    return(
        <div>
            <Box
            className={`${classes.select} ${open && classes.border}`}
            onClick={handlePopoverOpen}
            >
                <Box className={classes.option}>
                    <Icon>{options[value].icon}</Icon>
                    <Typography
                    variant="subtitle2"
                    component="span"
                    className={classes.title}
                    >
                     {t(`${options[value].title}`)}
                    </Typography>
                </Box>
                <Box>
                    <Icon className={!open ? classes.dropDownIcon : classes.dropUpIcon}>
                    {
                        !open ? 'arrow_drop_down':'arrow_drop_up'
                    }
                    </Icon>
                </Box>
            </Box>
            <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            style={{ transform: 'translateY(8px)'}}
            classes={{
                paper: classes.paper
            }}
            >
            <div>
                { props.optionsAvaiable.map((key, index) => {
                    return(
                        <Box 
                        key={index} 
                        className={classes.option} 
                        style={{
                            paddingBottom: 12, 
                            paddingTop: 12
                        }}
                        onClick={()=>{handleChange(key)}}
                        >
                            <Icon style={{color: theme.palette.neutrals.low.light}}>{options[key]?.icon}</Icon>
                            <Typography
                            variant="subtitle2"
                            component="span"
                            className={classes.title}
                            style={{color: theme.palette.neutrals.low.medium}}
                            >
                            {t(`${options[key]?.title}`)}
                            </Typography>
                        </Box>
                    );
                    })
                }
            </div>
            </Popover>
        </div>
    );
}

export { CustomSelect }