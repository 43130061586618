import React from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
  makeStyles,
  Box,
  Card,
  Grid,
} from '@material-ui/core';
import { tableCss } from './TableCss';
import { dateFormat, truncate } from '../../helpers';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { StatusIdea, Search } from '../Shared';
import ViewIcon from '@material-ui/icons/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { challengeActions } from '../../actions';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import emptyIdeasSvg from "./../../assets/imgs/emptystate/illustration_empty-state-my-ideas.svg"
import { PhaseStatus } from "./../../components/Idea/PhaseStatus"
import { ChallengeTableFilter } from "./../Challenge/ChallengeTableFilter"
import { EmptyState } from '../Shared';
 
const useStyles = makeStyles((theme) => ({
  root: {},
  label: {
    fontSize: 16,
    color: theme.palette.neutrals.low.medium,
    textAlign: 'center'
  },
  title: {
    color: theme.palette.neutrals.low.medium,
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    margin: theme.spacing(3, 0),
  },
  icon: { color: theme.palette.grey[400], marginRight: 10 },
  cardEmptyState: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4),
  }
}));

function ChallengeIdeaTable({ id, hasPhase = false }) {

  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language);
  const {
    challengeTableIdeas: { ideas, paging },
  } = useSelector((state) => state.challenge);
  const {
    typeProfile: { type },
  } = useSelector((state) => state.user);
  const linhasTable = {
    text: t('linhas_por_pagina'),
  };
  const tableClasses = tableCss(linhasTable);

  const columns = [
    { id: 'date', label: t('data') },
    { id: 'title', label: t('ideia') },
    { id: 'contributor', label: t('colaborador') },
    { id: 'area', label: t('area') },
    { id: 'engagement', label: t('engajamento') },
    { id: 'comments', label: t('comentarios') },
    { id: 'status', label: t('status') },
    { id: 'actions', label: '' },
  ];

  function handleChangePage(event, page) {
    let newPage = page + 1;
    dispatch(
      challengeActions.getIdeasChallenge(
        id,
        newPage,
        paging.sort,
        paging.limit,
        paging.statusCode.filter(item => item.checked).map(item => item.value)
      )
    );
  }

  function handleChangeRowsPerPage(event) {
    var limit = event.target.value || 10;
    dispatch(
      challengeActions.getIdeasChallenge(
        id,
        1,
        paging.sort,
        limit,
        paging.statusCode.filter(item => item.checked).map(item => item.value)
      )
    );
  }

  function goToPage(idea) {
    if (type === 'manager') {
      switch (idea.statusIdea.code) {
        case 'AWAITING_QUALIFICATION':
          return `/idea/${idea._id}/qualification`;

        case 'EXECUTION_QUEUE':
          return `/idea/${idea._id}/execution`;

        case 'EXECUTING':
          return `/idea/${idea._id}/conclusion`;

        default:
          return `/idea/${idea._id}/general`;
      }
    } else {
      return `/idea/${idea._id}/general`;
    }
  }
  
  function searchIdea(query) {
    dispatch(challengeActions.setFilter('search', query));
    dispatch(challengeActions.getIdeasChallenge(
      id,
      1,
      paging.sort,
      paging.limit,
      paging.statusCode.filter(item => item.checked).map(item => item.value),
      query
    ));
  }

  return (
    <div className={classes.root}>
      { !hasPhase && (
        <div className={classes.title}>{t('ideais_do_desafio')}</div>
      )}
      <Paper className={tableClasses.paperRoot}>
        { ideas.length > 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <ChallengeTableFilter />
                <Search
                  query={searchIdea}
                  placeholder={t('pesquise_por_nome_da_ideia_ou_colaborador')}
                />
              </Box>
            </Grid>
            { ideas.length > 0 && (
              <Grid item xs={12}>
                <TableContainer>
                  <Table
                    stickyHeader
                    aria-label="tabela de ideias do desafio"
                    classes={{ root: tableClasses.table }}
                  >
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            classes={{ root: tableClasses.tableCellHeader }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ideas &&
                        ideas.map((idea) => {
                          return (
                            <TableRow
                              hover
                              key={idea._id}
                              classes={{ root: tableClasses.tableRow }}
                            >
                              <TableCell
                                classes={{ root: tableClasses.tableCellRadiusLeft }}
                              >
                                {dateFormat(idea.createdAt, language)}
                              </TableCell>
                              <TableCell
                                className={tableClasses.oneLine}
                                classes={{ root: tableClasses.tableCell }}
                              >
                                {truncate(idea.name, 20)}
                              </TableCell>
                              <TableCell
                                className={tableClasses.oneLine}
                                classes={{ root: tableClasses.tableCell }}
                              >
                                {truncate(idea.user?.name || idea.userName, 18)}
                              </TableCell>
                              <TableCell
                                className={tableClasses.oneLine}
                                classes={{ root: tableClasses.tableCell }}
                              >
                                {idea.businessUnitLocal && idea.businessUnitLocal.length > 0 && truncate(idea?.businessUnitLocal[0]?.local?.name, 15)}
                              </TableCell>
                              <TableCell
                                className={tableClasses.oneLine}
                                classes={{ root: tableClasses.tableCell }}
                              >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <ThumbUpIcon className={classes.icon} />{' '}
                                  <div>{idea.upsLength || 0} {t('up')}s</div>
                                </div>
                              </TableCell>
                              <TableCell
                                className={tableClasses.oneLine}
                                classes={{ root: tableClasses.tableCell }}
                              >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <ChatBubbleIcon className={classes.icon} />{' '}
                                  <div>{idea.commentsLength || 0} comentários</div>
                                </div>
                              </TableCell>
                              <TableCell
                                className={tableClasses.oneLine}
                                classes={{ root: tableClasses.tableCell }}
                              >
                                {!idea.currentChallengePhase && idea.statusIdea?.name && (
                                  <StatusIdea status={idea.statusIdea} />
                                )}
                                { idea.challengePhasesEvaluations && (
                                  <>
                                    { idea.currentChallengePhase.awaitingQualification && (
                                      <PhaseStatus status="awaiting" />
                                    )}
                                    { !idea.currentChallengePhase.awaitingQualification && idea.currentChallengePhase.evaluation.qualified && (
                                      <PhaseStatus status="approved" />
                                    )}        
                                    { !idea.currentChallengePhase.awaitingQualification && !idea.currentChallengePhase.evaluation.qualified && (
                                      <PhaseStatus status="notApproved" />
                                    )}
                                  </>
                                )}
                              </TableCell>
                              <TableCell
                                classes={{ root: tableClasses.tableCellRadiusRight }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    paddingRight: 8,
                                  }}
                                >
                                  <IconButton
                                    component={Link}
                                    to={() => goToPage(idea)}
                                  >
                                    <ViewIcon />
                                  </IconButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  classes={{
                    input: tableClasses.paginationInput,
                    spacer: tableClasses.paginationSpacer,
                    caption: tableClasses.paginationCaption,
                    toolbar: tableClasses.paginationToolbar,
                  }}
                  rowsPerPageOptions={[10, 20, 30, 40, 50]}
                  count={paging.total}
                  rowsPerPage={paging.limit}
                  page={paging.page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={t('exibindo')}
                />
              </Grid>  
            )}
          </Grid>
        )}

        {ideas.length === 0 && (
          <Card elevation={0} className={classes.cardEmptyState}>
            <EmptyState
              icon={emptyIdeasSvg}
              description="Ainda não há ideias nessa fase!"
            />
          </Card>
        )}
      </Paper>
    </div>
  );
}

export { ChallengeIdeaTable };
