import React from 'react';
import { useTranslation } from 'react-i18next';

import { styled } from '@material-ui/core';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';

const Tabs = styled(MuiTabs)(({ theme }) => ({
  marginBottom: theme.spacing(3)
}));

const Tab = styled(MuiTab)({
  '& .MuiTab-wrapper': {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'lowercase'
  }
});

export function ChallengeDetailsMenu({ type, handleChangeTab, tabChosed }) {
  const { t } = useTranslation();

  return (
    <>
      {type === 'admin' && (
        <Tabs
          centered
          textColor="primary"
          indicatorColor="primary"
          value={tabChosed}
          onChange={(_e, value) => handleChangeTab(value)} 
        >
          <Tab label={t('detalhes')} />
          <Tab label={t('acompanhamento')} />
          <Tab label={t('premiacao')} />
        </Tabs>
      )}
    </>
  );
}