import axios from 'axios';
import { requestHeaders } from '../helpers';
import config from '../config';

export const reportService = {
  getReportData,
  getReportCsv,
  getReportDataChart,
  getByQuery
}

function getReportData(data) {
  return axios.post(`${config.URL_BASE}/reports/${data.reportType}`, data, requestHeaders());
}


function getReportDataChart(data) {
  return axios.post(`${config.URL_BASE}/reports/${data.reportType}/chart`, data, requestHeaders());
}

function getReportCsv(data) {
  return axios.post(`${config.URL_BASE}/reports/${data.reportType}/csv`, data, requestHeaders());
}


function getByQuery(query) {
  return axios.post(`${config.URL_BASE}/reports/ideas-status`,{'search':query}, requestHeaders())
}