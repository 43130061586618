import React, { useState } from 'react';
import {
  Box,
  Card,
  Grid,
  Menu,
  Button,
  makeStyles,
  IconButton,
  Typography
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { dashboardActions } from '../../actions';
import { today, beforeMonth, formatDate } from '../../utils/date';
import { SkeletonDashboardTops } from '../../components/Skeletons';
import { useDispatch } from 'react-redux';
import { DashboardTopColaboratorFilter, TopUserDashboard } from "./../../components"
import { dashboardService } from '../../services';
import { SelectColaboratorsRanking } from '../SelectColaboratorsRanking';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    borderRadius: 8,
    border: '1px solid',
    borderColor: theme.palette.grey.main,
    padding: theme.spacing(4),
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
  },
  text: {
    color: theme.palette.text.primary, 
    fontWeight: 500
  },
  line: {
    padding: '8px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  lineGroup: {
    display: 'flex',
  },
  icon: {
    fontSize: 20,
    color: theme.palette.text.secondary,
  },
  box: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  buttonCsv: {
    fontSize: 14,
    borderRadius: 0,
    backgroundColor: "#fff",
    boxShadow: 'none',
    width: "100%",
    textAlign: 'left',
  },
  chevron: {
    cursor: 'pointer'
  },
  bulTitle: {
    fontSize: 14,
    color: theme.palette.text.dark,
    fontWeight: 600,
  },
  bulSubtitle: {
    fontSize: 14,
  },
  menu: {
    width: "100%",
    padding: '0 !important',
    overflow: 'hidden !important',
  }
}));

function TopColaboradores({ topUsers, loadingTopUsers }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(e) {
    setAnchorEl(e.currentTarget);
  };

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClickDownloadCsv() {
    const filters = dashboardService.getFilters();
    filters.delimiter = ";";
    filters.reportType = "top-users";

    dispatch(dashboardActions.getTopFiveAreas(filters));
  }

  return (
        <Card elevation={0} className={classes.card}>
          {loadingTopUsers ? (
            <SkeletonDashboardTops />
          ) : (
            <>
              <Box className={classes.box}>
                <Typography className={classes.title}>top 10 colaboradores</Typography>
                <IconButton onClick={handleClick}>
                  <MoreHorizIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={anchorEl}
                  className={classes.menu}
                  onClose={handleClose}
                  getContentAnchorEl={null}
                  MenuListProps={{ disablePadding: true }}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Grid container sx={{ position: "relative" }}>
                  {/*   <Grid item xs={12}>
                      <DashboardTopColaboratorFilter />
                    </Grid> */}
                    <Grid item xs={12}>
                      <Button
                        variant="contained" 
                        startIcon={<GetAppIcon />}
                        className={classes.buttonCsv}
                        onClick={handleClickDownloadCsv}
                        children={(
                          <Box display="flex" alignItems="center" width="100%">
                            exportar para csv
                          </Box>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Menu>
              </Box>
              <SelectColaboratorsRanking />
              {topUsers.map((user, index) => (
                <TopUserDashboard user={user} key={index} position={index} />
              ))}
            </>
          )}
        </Card>
  );
}

export { TopColaboradores };
