import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
} from '@material-ui/core';
import { DialogCustomTitle } from '../CreateIdeaDialogs';
import { useDispatch } from 'react-redux';
import { ideaActions } from '../../actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20.25,
    fontWeight: 'bold',
    color: theme.palette.neutrals.low.main,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 16,
    },
  },
  content: {
    fontSize: 14.22,
    color: theme.palette.neutrals.low.medium,
  },
  containerContent: {
    textAlign: 'center',
    padding: theme.spacing(4, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  containerActions: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  buttonBlock: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
      marginBottom: 8,
    },
  },
}));

function DialogDeleteDraft({ idea, onClose, open }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function onAccept() {
    function getMessage() {
      if (idea.statusIdea?.code !== "DRAFT") {
        return t('sua_ideia_foi_deletada_com_sucesso')
      } else {
        return t('seu_rascunho_foi_deletado_com_sucesso')
      }
    }
    dispatch(ideaActions.deleteDraft(idea._id, idea.statusIdea?.code, getMessage()));
    onClose();
  }

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogCustomTitle onClose={() => onClose()}></DialogCustomTitle>
      <DialogContent>
        <div className={classes.containerContent}>
          <div className={classes.title}>
            { idea.statusIdea?.code !== "DRAFT" ? (
              <>
                {`${idea.name}` }
              </>
            ) : (
              <>
                {t('deletar_rascunho')}
              </>
            )}
          </div>
          <div className={classes.content}>
            {t('tem_certeza_que_deseja_excluir_permanentemente_essa_ideia')}
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.containerActions}>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonBlock}
          onClick={() => onAccept()}
        >
          {t('sim')}
        </Button>
        <Button
          variant="outlined"
          className={classes.buttonBlock}
          onClick={() => onClose()}
        >
          {t('nao')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export { DialogDeleteDraft };
