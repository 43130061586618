import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles, Container, Grid, Button } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';
import LinkIcon from '@material-ui/icons/Link';

import {
  InfrastructureTabs,
  InfrastructureHeader,
  InfrastructureListingTabs,
} from '../../components/Infrastructure';
import { BackNavAdmin } from '../../components/Shared';
import { infraStructureActions } from './../../actions';
import { SkeletonTable } from '../../components/Skeletons';
import { InfraestructureTable } from '../../components/Tables';
import { DialogAddInfra } from '../../components/Infrastructure/DialogAddInfra';
import { ReactComponent as InfraIcon } from '../../assets/imgs/infrastucture-icon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  button: {
    float: "right"
  }
}));

export const InfraestructurePage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [openDialogAddInfra, setOpenDialogAddInfra] = useState(false);
  const [tabChosed, setTabChosed] = useState(0);
  const { loading, selectedTab, filters: { dateSort }, paging: { page, limit, query } } = useSelector(state => state.infraStructure);

  useEffect(() => {
    if (tabChosed === 0) {
      dispatch(infraStructureActions.getInfraStructure({ page: 1, limit: 10, types: [selectedTab], dateSort }));
    } else {
      dispatch(infraStructureActions.getAllAssociations(page, limit, dateSort, query));
    }
  }, [selectedTab, tabChosed, dateSort, dispatch]);

  function handleOpenDialogAddInfra() {
    setOpenDialogAddInfra(!openDialogAddInfra);
  }

  function handleChangeTab(tabChosed) {
    setTabChosed(tabChosed);
  }

  return (
    <>
      <DialogAddInfra open={openDialogAddInfra} onClose={handleOpenDialogAddInfra} />
      <BackNavAdmin title={t('infraestrutura')}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleOpenDialogAddInfra}
          startIcon={<AddCircle />}
        >
          {t('adicionar_infraestrutura')}
        </Button>
      </BackNavAdmin>
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <InfrastructureTabs tabChosed={tabChosed} handleChangeTab={handleChangeTab} />
          </Grid>
          <InfrastructureHeader
            icon={tabChosed === 0 ? <InfraIcon /> : <LinkIcon />}
            title={tabChosed === 0 ? t('listagem_de_infraestrutura') : t('vinculos_de_infraestrutura')}
            info={tabChosed === 0 ? t('info_infra_listing-page') : t('info_infra_links-page')}
            tabChosed={tabChosed}
          />
          <Grid item xs={12}>
            {tabChosed === 0 ? (<>
              <InfrastructureListingTabs />
              {loading ? <SkeletonTable cols={4} /> : <InfraestructureTable />}
            </>) : (
              loading ? <SkeletonTable cols={4} /> : <InfraestructureTable tabChosed={'links'} />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
