import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import {
  ChallengeRankCard,
  ChallengeSchedule,
  ChallengePhaseStep,
  ChallengeInfoDetails,
  ChallengeInfoAwardsCard
} from './';
import { ChallengeIdeaTable } from '../Tables/ChallengeIdeaTable';
import { SkeletonTable } from '../../components/Skeletons';
import imageCard from '../../assets/imgs/imagem_padrao_card_desafio.png';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4),
  },
  media: {
    height: 203,
    backgroundColor: theme.palette.neutrals.low.light,
    borderRadius: 8,
  },
  cronogramaTitle: {
    fontWeight: 700,
    color: theme.palette.neutrals.low.dark,
    textTransform: "uppercase"
  },
}));

export function ChallengeDetailsContent({ id, challengeDetail, ideas, paging, type, loading }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const imgUrl = challengeDetail?.banner?.url ? challengeDetail?.banner?.url : challengeDetail?.attachment?.url;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <CardMedia className={classes.media} image={ imgUrl || imageCard }/>
      </Grid>

      <Grid item xs={12} lg={8}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <Grid container spacing={3}>
                {challengeDetail && (
                  <Grid item xs={12}>
                    <ChallengeInfoDetails challenge={challengeDetail} />
                  </Grid>
                )}
                {!challengeDetail.phases && (
                  <Grid item xs={12}>
                    <ChallengeSchedule challenge={challengeDetail} />
                  </Grid>
                )}
              </Grid>
            </Card>
          </Grid>
          { challengeDetail.phases &&
            challengeDetail.phases.length > 0 && (
            <Grid item xs={12}>
              <Card elevation={0} className={classes.card}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" className={classes.cronogramaTitle}>
                      {t("cronograma")}
                    </Typography>
                  </Grid>
                  {challengeDetail.phases.map((item, index) => (
                    <Grid item xs={12} key={item._id}>
                      <ChallengePhaseStep
                        currentPhase={!item.concluded && item.executing}
                        concludedPhase={item.concluded }
                        futurePhase={!item.concluded && !item.executing}
                        title={item.name}
                        startDate={item.startDate}
                        endDate={item.endDate}
                        description={item.description}
                        index={index}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Card>      
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12} lg={4}>
        <Grid container spacing={4}>
          {challengeDetail?.rewards?.every(reward => reward.win) && (
            <Grid item xs={12}>
              <ChallengeRankCard challenge={challengeDetail} />
            </Grid>
          )}
          {challengeDetail.rewards?.length > 0 && (
            <Grid item xs={12}>
              <ChallengeInfoAwardsCard challenge={challengeDetail} />
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {loading 
          ? <SkeletonTable cols={7} />
          : <ChallengeIdeaTable id={id} ideas={ideas} paging={paging} userRule={type} />
        }
      </Grid>
    </Grid>
  );
}