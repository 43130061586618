import React, { useEffect } from 'react';
import { makeStyles, withStyles, useTheme, Container, Grid, Tabs } from '@material-ui/core';
import MuiTab from '@material-ui/core/Tab';
import {
  BackNavAdmin,
  CurationTitle,
  CurationTable,
  CurantionCardsStatus,
  CurationMenusTable,
  CurationCard,
  Loading,
  CurationFilters,
  SkeletonTable
} from '../../components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { curationActions, ideaActions } from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks';
import config from '../../config';
import ListIcon from '@material-ui/icons/List';
import { ReactComponent as IdeaIcon } from '../../assets/imgs/ideiaPurple.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
}));

const Tab = withStyles({
  wrapper: {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'lowercase',
  }
})(MuiTab);

function CurationIdeasMatPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [width] = useWindowSize();
  const dispatch = useDispatch();
  const {
    ideas,
    paging,
    loading,
    paging: { statusCode, page, sort, query, limit },
    filters,
    curationTab
  } = useSelector((state) => state.curation);
  const { t } = useTranslation();
  const { statusIdea } = useSelector(state => state.domains)
  const { 
    paging: { 
      sort: challengeSort, 
      filter: challengeFilter 
    }
  } = useSelector(state => state.challenge);
  const { occupationArea } = useSelector(state => state.businessUnitProps);

  const handleChangeCurationTab = (event, newValue) => {
    dispatch(curationActions.clearFilters())
    dispatch(curationActions.changeCurationProp("curationTab", newValue));
  };

  useEffect(() => {
    if (statusIdea) {
      var allStatus = [];
      
      if (curationTab === "listagem") {
        allStatus = statusIdea?.filter(item => !["PATENT_PENDING", "STAND_BY", "DRAFT"].includes(item.code)).map(item => ({
          label: item.name,
          value: item.code,
          checked: true
        }));
        dispatch(curationActions.loadIdeasAdmin({
          statusCode: allStatus,
          challenges: [],
        }, paging, occupationArea))
      } else {
        allStatus = statusIdea?.filter(item => ["AWAITING_QUALIFICATION"].includes(item.code)).map(item => ({
          label: item.name,
          value: item.code,
          checked: true
        }));
      
      dispatch(curationActions.loadIdeas({
        statusCode: allStatus,
        challenges: [],
      }, paging, occupationArea))
      }
      dispatch(curationActions.getChallenges(1, challengeSort, challengeFilter));
      dispatch(curationActions.loadAllStatus(allStatus))

      if (curationTab === "esteira") {
        dispatch(curationActions.changeCurationProp("esteiraTab", 'AWAITING_QUALIFICATION'));
        dispatch(ideaActions.getStatusIdeas());
      }
    }
  }, [statusIdea, curationTab]);

  
  function loadMoreIdeas() {
      var newPage = page === 1 ? 2 : page;
    if (!loading && curationTab !== "listagem") {
      dispatch(curationActions.loadIdeas(filters, {
        ...paging,
        page: newPage,
      }, occupationArea))
    } else if(!loading && curationTab === "listagem") {
      dispatch(curationActions.loadIdeasAdmin(filters, {
        ...paging,
        page: newPage,
      }, occupationArea))
    }
  }

  function renderTable() {
    if (loading) {
      return <SkeletonTable cols={6} />
    } else if (ideas?.length) {
      return <CurationTable />
    }
  }

  function renderCards() {
    if (ideas?.length > 0) {
      return (
        <InfiniteScroll
          dataLength={ideas.length}
          next={loadMoreIdeas}
          hasMore={true}
          scrollThreshold={'100%'}
          loader={
            loading && (
              <div className={classes.containerLoading}>
                <Loading />
              </div>
            )
          }
        >
          {ideas.map((idea, index) => (
            <CurationCard
              key={idea?._id}
              index={index}
              idea={idea}
            />
          ))}
        </InfiniteScroll>
      );
    }

    return null;
  }

  return (
    <>
      <BackNavAdmin title={t('curadoria')} />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Tabs
              value={curationTab}
              onChange={handleChangeCurationTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab value="esteira" label={t('esteira_de_ideias')} />
              <Tab value="listagem" label={t('listagem_de_ideias')} />
            </Tabs>
          </Grid>

          { curationTab === "esteira" && (
            <Grid item xs={12}>
              <CurationTitle
                title={t('esteira_de_ideias')}
                icon={<IdeaIcon style={{ fill: theme.palette.primary.main, width: 27 }} />}
                info={t('as_ideias_na_esteira_necessitam_de_acoes_do_gestor_para_avancar_para_os_proximos_status')}
              />
              <CurantionCardsStatus />
              <CurationFilters />
              <CurationMenusTable />
              <Grid container>
                <Grid item xs={12}>
                  {width > config.RESPONSIVE_MOBILE ? renderTable() : renderCards()}
                </Grid>
              </Grid>
            </Grid>
          )}

          { curationTab === "listagem" && (
            <Grid item xs={12}>
              <CurationTitle
                title={t('lista_de_ideias')}
                icon={<ListIcon color="primary" />}
                info={t('na_listagem_e_possivel_visualizar_todas_as_ideias_filtrando_as_por_status_e_por_nome_ou_desafio')}
              />
              <CurationFilters />
              <Grid container>
                <Grid item xs={12}>
                  {width > config.RESPONSIVE_MOBILE ? renderTable() : renderCards()}
                </Grid>
              </Grid>
            </Grid>
          )}

        </Grid>
      </Container>
    </>
  );
}

export { CurationIdeasMatPage };
