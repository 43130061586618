import { makeStyles } from '@material-ui/core';

export const IdeaCss = makeStyles((theme) => ({
  // IdeaGeneral
  card: {
    padding: theme.spacing(4),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  cardSmall: {
    padding: theme.spacing(3),
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
  },
  avatarIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    background: theme.palette.primary.light,
    marginRight: theme.spacing(1),
  },
  titleUpperCase: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  contributorImage: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1)
  },
  contributorName: {
    fontWeight: 500,
    cursor: 'pointer',
    color: theme.palette.neutrals.low.main,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  buttonCanvas: {
    borderRadius: 8,
    boxShadow: 'none',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.neutrals.low.dark,
    backgroundColor: theme.palette.neutrals.high.lightest,
    textAlign: 'left',
    '&:hover': {
      boxShadow: 'none',
    },
    '&:last-child': {
      marginTop: theme.spacing(2)
    }
  },
  challengeLink: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    }
  },
  texto: {
    whiteSpace: 'pre-line',
  }
}));