import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Edit from '@material-ui/icons/Edit';
import EmojiObjects from '@material-ui/icons/EmojiObjects';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

function BackNavAdminButtons({ type, id, createIdea }) {
  const { t } = useTranslation();
  const { 
    challengeDetail: { statusChallenge, currentPhase }
  } = useSelector((state) => state.challenge);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        width: 270,
      }}
    >
      <div style={{display: 'flex'}}>
        {type === 'admin' && (
          <Button
            style={{ fontSize: '0.75rem' }}
            variant="outlined"
            color="primary"
            startIcon={<Edit />}
            component={Link}
            to={`/challenge/edit/${id}`}
          >
            {t('editar_desafio')}
          </Button>
        )}
        {statusChallenge && 
          statusChallenge.code == 4 && currentPhase && currentPhase.acceptNewIdeas && createIdea && (
          <Button
            style={{ marginLeft: 10,fontSize: '0.75rem' }}
            variant="contained"
            color="primary"
            startIcon={<EmojiObjects />}
            onClick={createIdea}
          >
            {t('dar_ideia')}
          </Button>
        )}
      </div>
    </div>
  );
}

export { BackNavAdminButtons };
