import React from 'react';
import clsx from 'clsx';
import MuiAvatar from '@material-ui/core/Avatar';
import { makeStyles, styled } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  small: {
    width: 30,
    height: 30,
    fontSize: 12,
  },
  large: {
    width: theme.spacing(7), //56px
    height: theme.spacing(7),
    fontSize: 24,
  },
  medium: {
    width: theme.spacing(6), // 48px
    height: theme.spacing(6),
  },
  xlarge: {
    width: theme.spacing(9), // 72px
    height: theme.spacing(9),
    fontSize: 32,
  },
  superLarge: {
    width: theme.spacing(16), // 128px
    height: theme.spacing(16),
    fontSize: 56,
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
}));

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  textTransform: 'uppercase',
  fontWeight: 600
}));

export function AvatarWithoutPhoto({ 
  userName,
  className,
  style,
  src,
  alt,
  superLarge = false,
  large = false,
  medium = false,
  xlarge = false,
  small = false
}) {
  const classes = useStyles();
  
  function getSize() {
    if (superLarge) {
      return classes.superLarge;
    }

    if (large) {
      return classes.large;
    }

    if (medium) {
      return classes.medium;
    }

    if (xlarge) {
      return classes.xlarge;
    }

    if (small) {
      return classes.small;
    }

    return {};
  }

  function getInitialsName(name) {
    const arrName = name?.split(' ');

    if (arrName.length > 1) {
      return `${arrName[0][0]}${arrName[1][0]}`;
    } else {
      return `${arrName[0][0]}`;
    }
  }

  return (
    <Avatar
      children={getInitialsName(userName)} 
      className={clsx(className, getSize())}
      style={style}
      src={src}
      alt={alt}
    />
  );
}