import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import {
  DialogAllUserFunctions,
  SelectedUser,
  ListFunction,
  FormEditFunction,
} from '../../components';
import { userFunctionActions } from './../../actions';

export const UserFunctionPage = ({ id }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [editFunctions, setEditFunctions] = useState(false);
  const {
    loadingUser,
    userType,
    selectedUser,
  } = useSelector(state => state.userFunction)
  const { imagineLocals } = useSelector(state => state.domains);

  useEffect(() => {
    if (!selectedUser) {
      if (id && !loadingUser) {
        dispatch(userFunctionActions.loadPage(id, imagineLocals));
      } else {
        if (!userType) {
          dispatch(userFunctionActions.getUserType())
        } else {
          dispatch(userFunctionActions.getAllUsers(userType))
        }
      }
    } else {
      dispatch(userFunctionActions.loadPage(selectedUser.id, imagineLocals));
    }
  }, [params, dispatch])

  function handleToggleEdit() {
    setEditFunctions(!editFunctions)
  }

  return (
    <>
      <DialogAllUserFunctions toggleEdit={handleToggleEdit} />
      <Grid container spacing={3}>
        {selectedUser && (
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectedUser />
                  </Grid>
                </Grid>
              </Grid>
              {selectedUser && (
                <Grid item sm={12}>
                  {editFunctions ? (
                    <FormEditFunction />
                  ) : (
                    <ListFunction toggleEdit={handleToggleEdit} />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}