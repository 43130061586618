import React from 'react';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import { styled } from '@material-ui/core/styles';

const Tabs = styled(MuiTabs)(({ theme }) => ({
  background: theme.palette.neutrals.high.main,
  borderRadius: 8,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
}));

const Tab = styled(MuiTab)({
  '& .MuiTab-wrapper': {
    fontSize: 14,
    fontWeight: 600,
    textTransform: 'lowercase'
  }
});

export function DynamicChallengePhaseTabs({ handleChangePhase, selectedPhase, phases }) {
  return (
    <Tabs
      variant="fullWidth"
      textColor="primary"
      indicatorColor="primary"
      value={selectedPhase}
      onChange={(_e, value) => handleChangePhase(value)}
    >
      {phases.map((item, index) => (
        <Tab key={item._id} label={<>{`${index+1}º fase:`} {' '} {item.name}</>} />
      ))}
    </Tabs>
  );
}
