import React, { useEffect } from 'react';
import { List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { truncate } from '../../helpers';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { CustomTooltip } from '../../components/Shared/CustomTooltip';
import { useDispatch, useSelector } from "react-redux";
import { contentsActions } from '../../actions';
import { history } from '../../helpers';
import { Container } from '@material-ui/core';
import { BackNav } from '../../components';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
    flexGrow: 1,
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  content: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 14,
  },
  icon: {
    marginRight: 8,
  },
  title: {
    fontSize: 14,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    cursor: 'pointer',
    color: theme.palette.neutrals.low.dark,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  subTitle: {
    fontSize: 12,
    color: theme.palette.neutrals.low.light,
    wordWrap: 'break-word',
    // textOverflow: 'ellipsis',
    // whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 500,
  },
  item: {
    paddingRight: 0,
    paddingLeft: 0,
    paddingTop: 2,
    paddingBottom: 2,
  },
  seeAll: {
    fontWeight: 600,
    fontSize: 11,
    cursor: 'pointer',
    color: theme.palette.neutrals.low.dark,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  headerSeeAll: {
    fontSize: 11,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: theme.palette.neutrals.low.dark,
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  column: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.neutrals.low.main,
    fontSize: 14,
    fontWeight: 'bold',
  },
  link: {
    color: theme.palette.neutrals.low.dark,
    fontSize: 12,
    fontWeight: 700,
    cursor: 'pointer',
    textTransform: 'uppercase',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
    },
  },
}));

function ContentsDetailsPage({ title }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { contents, contentsLoading } = useSelector(state => state.contents)

  useEffect(() => {
    dispatch(contentsActions.getContents());
  }, [dispatch])

  return (
    <Container maxWidth="lg" className={classes.root}>
      <BackNav title="conteúdos" pathname="/" />
      <div className={classes.header}>
        {title && (
          <div className={classes.row}>
            <div className={classes.column}>
              <AssignmentIcon color="secondary" className={classes.icon} />
              <div className={classes.title}>CONTEÚDOS</div>
            </div>

          </div>
        )}
        {!contentsLoading && (
          <List>
            {contents &&
              contents.map((content, index) => {
                return (
                  <ListItem className={classes.item} key={index}>
                    <ListItemText>
                      <CustomTooltip title={content.title}>
                        <div className={classes.title}>
                          {content.link ? (
                            <a
                              className={classes.title}
                              href={content.link}
                              target="_blank"
                              rel="noopener noreferrer"
                          style={{ wordWrap: 'break-word' }}
                            >
                              {content.title}
                            </a>
                          )
                            : (
                              <>
                                {content.title}
                              </>
                            )}

                        </div>
                      </CustomTooltip>
                      {/* <div className={classes.subTitle}>
                      <TimeAgo
                        date={content.createdAt}
                        formatter={formatterTimer}
                      />{' '}
                      por {content.user.name}
                    </div> */}
                      <div className={classes.subTitle}>
                        {content.description}
                      </div>
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
        )}
        {/*  <div className={classes.seeAll}>VER TODOS</div> */}
      </div>
    </Container>
  );
}

export { ContentsDetailsPage };
