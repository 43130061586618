import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Grid,
  TextField,
  Checkbox,
  Button,
  Typography,
  FormControlLabel,
} from "@material-ui/core";
import FilterListIcon from '@material-ui/icons/FilterList';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Autocomplete, createFilterOptions  } from "@material-ui/lab";
import { CheckBoxOutlineBlank, CheckBox as CheckboxIcon } from '@material-ui/icons';

import { useStyles } from './styles';
import { formatDate } from '../../utils/date';
import { BusinessUnitPropsCheckboxList } from "./../../components/Shared";
import { reportActions, businessUnitPropsAction } from "./../../actions";

const filterOptions = createFilterOptions({
  limit: 100,
});

export const ReportFilters = ({ closeFilter }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [splittedPathname, checked] = useState(history.location.pathname.split("/"), true);
  const { filters, paging, fieldValidation, loading, tab } = useSelector(state => state.report);
  const { local, occupationArea, businessUnit } = useSelector(state => state.businessUnitProps);
  
  function handleChangeStatus(data) {
    if (data) {
      dispatch(reportActions.changeStatus(data.option))
    } else {
      dispatch(reportActions.clearStatus())
    }
  }

  function handleChangeChallenge(data) {
    if (data) {
      dispatch(reportActions.changeChallenge(data.option));
    } else {
      dispatch(reportActions.clearChallenge());
    }
  }

  const handleStartDate = (date) => {
    if (date instanceof Date && isFinite(date)) {
      dispatch(reportActions.changeStartDate(date))
    }
  };

  const handleEndDate = (date) => {
    if (date instanceof Date && isFinite(date)) {
      dispatch(reportActions.changeEndDate(date))
    }
  };

  const handleClearFilters = () => {
    dispatch(reportActions.clearFilters())
    dispatch(businessUnitPropsAction.clearBusinessUnitPropField('occupationArea'))
    dispatch(businessUnitPropsAction.clearBusinessUnitPropField('local'))
    dispatch(businessUnitPropsAction.clearBusinessUnitPropField('businessUnit'))
    dispatch(reportActions.changeBulEmptyChecked(false))
  }

  function handleFilter() {
    let params = {
      challenge: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.challenges && filters.challenges.filter(item => item.checked).map(item => item.value),
      statusCode: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.status && filters.status.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      businessUnits: businessUnit.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      locals: local.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      occupationArea: occupationArea.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      startDate: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.startDate && formatDate(filters.startDate),
      endDate: fieldValidation[splittedPathname[splittedPathname.length - 1]]?.endDate && formatDate(filters.endDate),
      bulWithoutUsers: filters.bulWithoutUsers,
      ideasUngrouped: filters.ideasUngrouped,
      ideaDescriptionOnCsv: filters.ideaDescriptionOnCsv,
      useConclusionDate: filters.useConclusionDate,
      ideasVisibilityInFeed: filters.ideasVisibilityInFeed,
      reportType: splittedPathname[splittedPathname.length - 1],
      searchText: filters.query
    }

    dispatch(reportActions.preFilter())

    if (tab === "table") {
      params = {
        ...params,
        page: 1,
        limit: paging.limit,
      }
      dispatch(reportActions.getReportData(params))

    } else {
      params = {
        ...params,
        page: -1,
      }
      dispatch(reportActions.getReportDataChart(params))
    }
    closeFilter();
  }

  function handleChangeCheckbox(event) {
    dispatch(reportActions.changeBulEmptyChecked(event.target.checked));
  }

  function handleChangeIdeasUngrouped(e) {
    const { name, checked } = e.target;
    dispatch(reportActions.changeIdeasUngrouped(name, checked));
  }

  function handleChangeIdeaDescriptionOnCsv(e) {
    const { name, checked } = e.target;
    dispatch(reportActions.changeIdeaDescriptionOnCsv(name, checked));
  }

  function handleChangeUseConslusionDate(e) {
    const { name, checked } = e.target;
    dispatch(reportActions.changeUseConslusionDate(name, checked));
  }
  function handleChangeHiddenIdeasInFeed(e) {
    const { name, checked } = e.target;
    dispatch(reportActions.changeHiddenIdeasInFeed(name, checked));
  }

  return (
    <Box padding="1rem">
      <Grid container>
        <Grid item xs={12}>
          {fieldValidation[splittedPathname[splittedPathname.length - 1]]?.status && (
            <Box>
              <Typography className={classes.titleInputs}>{t('detalhes')}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Autocomplete 
                    multiple
                    fullWidth
                    disableCloseOnSelect
                    loadingText={t('carregando...')}
							      noOptionsText={t('sem_opcoes')}
                    id="checkboxes-status"
                    filterOptions={filterOptions}
                    options={filters.status}
                    loading={filters.status.length === 0}
                    value={filters.status.filter(item => item.checked)}
                    onChange={ (e, items, options, details) => handleChangeStatus(details) }
                    getOptionLabel={(input) => input.label}
                    renderInput={(params) => <TextField {...params} label={t('status')} variant="filled" />}
                    renderTags={(option, state) => {
                      return (<span>{option.length > 1 ? `${option[0].label} +${option.length-1}` : option[0].label}</span>)
                    }}
                    renderOption={(value) => {
                      return (
                        <li>
                          <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckboxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={value.checked}
                            />
                          {value.label}
                        </li>
                    )}}
                  />
                </Grid>
                {location.pathname === '/report/ideas-status' && (
                  <Grid item xs={12} md={6}>
                    <Autocomplete 
                      multiple
                      fullWidth
                      disableCloseOnSelect
                      loadingText={t('carregando...')}
							        noOptionsText={t('sem_opcoes')}
                      id="checkboxes-challenge"
                      filterOptions={filterOptions}
                      options={filters.challenges}
                      loading={filters.challenges.length === 0}
                      value={filters.challenges.filter(item => item.checked)}
                      onChange={(e, items, options, details) => handleChangeChallenge(details)}
                      getOptionLabel={(input) => input.label}
                      renderInput={(params) => <TextField {...params} label={t('desafios')} variant="filled" />}
                      renderTags={(option, state) => {
                        return (<span>{option.length > 1 ? `${option[0].label} +${option.length-1}` : option[0].label}</span>)
                      }}
                      renderOption={(value) => {
                        return (
                          <li>
                            <Checkbox
                              icon={<CheckBoxOutlineBlank fontSize="small" />}
                              checkedIcon={<CheckboxIcon fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={value.checked}
                              />
                            {value.label}
                          </li>
                      )}}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          {fieldValidation[splittedPathname[splittedPathname.length - 1]]?.startDate && (
            <Box>
              <Typography className={classes.titleInputs}>{t('data')}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} className={classes.containerDate}>
                  <KeyboardDatePicker
                    variant="inline"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    disableFuture
                    value={filters.startDate}
                    fullWidth
                    id="date-picker-inline"
                    className={classes.datePicker}
                    label={t('inicio')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    invalidDateMessage={
                      filters.startDate && <span>Data de inicio inválida</span>
                    }
                    name="startDate"
                    onChange={handleStartDate}
                    autoOk
                    maxDate={filters.endDate}
                    maxDateMessage={
                      <span className={classes.errorSpan}>
                        A data não pode ser futura ou maior que a data de termino
                      </span>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} className={classes.containerDate}>
                  <KeyboardDatePicker
                    variant="inline"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    value={filters.endDate}
                    className={classes.datePicker}
                    fullWidth
                    disableFuture
                    margin="normal"
                    id="date-picker-inline-endDate"
                    label={t('termino')}
                    onChange={handleEndDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    invalidDateMessage={
                      <span className={classes.errorSpan}>
                        Data de termino invalida
                      </span>
                    }
                    name="endDate"
                    minDate={filters.startDate}
                    autoOk
                    minDateMessage={
                      <span className={classes.errorSpan}>
                        A data de termino não pode ser inferior a data de inicio
                      </span>
                    }
                    maxDateMessage={
                      <span className={classes.errorSpan}>
                        A data de termino não pode ser uma data futura.
                      </span>
                    }
                  />
                </Grid> 
              </Grid>
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.titleInputs}>{t('infraestrutura')}</Typography>
            </Grid>
            <Grid item xs={12} md={fieldValidation[splittedPathname[splittedPathname.length - 1]]?.status ? 12 : 12}>
              <BusinessUnitPropsCheckboxList businessUnitField = {fieldValidation[splittedPathname[splittedPathname.length - 1]]?.businessUnit} />
            </Grid>
          </Grid> 
        </Grid>

        {location.pathname === '/report/ideas-status' && (
          <Grid item xs={12} style={{ paddingTop: 20 }}>
            <FormControlLabel
              label={t('exibir_ideias_em_grupo_separadas_por_participante')}
              control={
                <Checkbox
                  color="primary"
                  name="ideasUngrouped"
                  checked={filters.ideasUngrouped}
                  onChange={handleChangeIdeasUngrouped}
                />
              }
            />
            <FormControlLabel
              label={t('incluir_o_campo_descricao_da_ideia_no_arquivo_excel')}
              control={
                <Checkbox
                  color="primary"
                  name="ideaDescriptionOnCsv"
                  checked={filters.ideaDescriptionOnCsv}
                  onChange={handleChangeIdeaDescriptionOnCsv}
                />
              }
            />
            <FormControlLabel
              label={t('considerar_data_de_conclusao_da_ideia_na_consulta')}
              control={
                <Checkbox
                  color="primary"
                  name="useConclusionDate"
                  checked={filters.useConclusionDate}
                  onChange={handleChangeUseConslusionDate}
                />
              }
            />
            <FormControlLabel
              label={t('exibir_apenas_ideias_nao_visiveis_no_feed')}
              control={
                <Checkbox
                  color="primary"
                  name="ideasVisibilityInFeed"
                  checked={filters.ideasVisibilityInFeed}
                  onChange={handleChangeHiddenIdeasInFeed}
                />
              }
            />
          </Grid>
        )}
        
        <Grid item xs={12}>
          <Box display="flex" alignItems="end" justifyContent="space-between" marginTop="2rem">

          <Box>
            {fieldValidation[splittedPathname[splittedPathname.length - 1]]?.showCheckBoxEmptyBul && (<FormControlLabel
                label={t('exibir_areas_sem_gestores')}
                control={
                  <Checkbox
                    color="primary"
                    checked={checked[0] && checked[1]}
                    indeterminate={checked[0] !== checked[1]}
                    onChange={handleChangeCheckbox}
                  />
                }
                className={classes.alignCheckBoxBulEmpty}
              />
              )}
            </Box>
            <Box display="flex" alignItems="end" justifyContent="end">
              <Button
                variant="outlined"
                color="primary"
                className={classes.filterButtons}
                onClick={handleClearFilters}
              >
                {t('limpar_filtro')}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.filterButtons}
                disabled={loading}
                onClick={handleFilter}
                startIcon={<FilterListIcon />}
              >
                {t('filtrar')}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
