import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.neutrals.low.medium
  },
  iconAvatar: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
  },
  awardTitle: {
    color: theme.palette.neutrals.low.medium,
  },
  awardDescription: {
    color: theme.palette.neutrals.low.light,
  },
}));

export function ChallengeInfoAwardsCard({ challenge }) {
  const classes = useStyles();
  const { rewards } = challenge;
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Card elevation={0} className={classes.card}>
      <Typography variant="subtitle2" className={classes.title}>
        {t('premiacao').toUpperCase()}
      </Typography>

      <Box>
        { rewards &&
          rewards.length > 0 &&
          rewards.map((award, index) => (
            <Box className={classes.row} key={index}>
              <Avatar 
                className={classes.iconAvatar}
                style={
                  index < 3
                    ? { backgroundColor: theme.palette.auxiliary.yellow.main }
                    : { backgroundColor: theme.palette.neutrals.high.medium }
                }
              >
                <span
                  className="material-icons"
                  style={{
                    color: 'white',
                    width: 30,
                    textAlign: 'center',
                  }}
                >
                  military_tech
                </span>
              </Avatar>
              <Box>
                <Typography variant="subtitle2" className={classes.awardTitle}>
                  {index + 1}° {t('lugar')}
                </Typography>
                <Typography variant="caption" className={classes.awardDescription}>
                  {award.description}
                  {award.score && award.score > 0 && (
                    <span>
                      + {award.score} {t('pontos').toLowerCase()}
                    </span>
                  )}
                </Typography>
              </Box>
            </Box>
        ))}
      </Box>
    </Card>
  );
}
