import React, { useEffect, useState } from 'react'
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import CardContent from "@material-ui/core/CardContent"
import makeStyles from "@material-ui/styles/makeStyles"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { useDispatch, useSelector} from "react-redux"
import { ChallengeStepHeader } from "./../Challenge/ChallengeStepCard/ChallengeStepHeader"
import { ChallengeStepHeaderIcon } from "./../Challenge/ChallengeStepCard/ChallengeStepHeaderIcon"
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { useForm } from 'react-hook-form';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import clsx from "clsx";
import { ideaActions, alertActions } from "./../../actions"
import { PhaseStatus } from "./../../components/Idea/PhaseStatus"

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    color: theme.palette.neutrals.low.dark,
    textTransform: "uppercase",
  },
  content: {
    padding: theme.spacing(2),
  },
  buttonLeft: {
    marginRight: theme.spacing(2)
  },
  phaseItemTitle: {
    fontSize: 14,
    color: theme.palette.neutrals.low.dark,
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  phaseEvaluation: {
    fontSize: 14,
    color: theme.palette.neutrals.low.medium,
  },
  phaseItemLabel: {
    fontSize: 14,
    color: theme.palette.neutrals.low.dark,
    fontWeight: "bold",
    marginRight: "0.75rem"
  },
  division: {
    height: 1,
    border: "none",
    backgroundColor: "#E7EAED"
  },
  about: {
    position: 'relative',
  },
  characters: {
    margin: theme.spacing(1, 0),
    fontSize: '0.75rem',
    position: 'absolute',
    right: 10,
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginLeft: 14,
    marginRight: 14,
    marginTop: 3,
    fontSize: '0.6428571428571428rem',
    fontWeight: 400,
    lineHeight: 1.66,
  },
}))

export const PhaseReview = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [ obsChar, setObsChar ] = useState(0)
  const { 
    ideaDetails, 
    phaseEvaluation,
    evaluationLoading,
    evaluationSuccess,
    evaluationError,
   } = useSelector(state => state.idea);
  const { register, errors, getValues } = useForm({
    mode: 'onBlur',
  });

  useEffect(() => {
    if (evaluationSuccess) {
      dispatch(alertActions.success("A ideia foi avaliada!"));
      dispatch(ideaActions.handleClearEvaluationFlags())
      window.location.reload();
    }

    if (evaluationError) {
      dispatch(alertActions.error("Ocorreu um problema ao avaliar idéia."));
      dispatch(ideaActions.handleClearEvaluationFlags())
      window.location.reload();
    }

  }, [evaluationSuccess, evaluationError, dispatch])

  function handleBlurObs({ target: { name, value } }) {
    dispatch(ideaActions.changeIdeaField(name, value))
  }

  function handleChangeObs() {
    const obs = getValues().phaseEvaluation;
    setObsChar(obs)
  }

  function handleNotApproved() {
    dispatch(ideaActions.handleEvaluate(ideaDetails._id, ideaDetails.currentChallengePhase, phaseEvaluation, false))
  }

  function handleApproved() {
    dispatch(ideaActions.handleEvaluate(ideaDetails._id, ideaDetails.currentChallengePhase, phaseEvaluation, true))
  }

  function getFaseNumber(phaseId) {
    return ideaDetails.challengePhases.findIndex((item) => item._id === ideaDetails.currentChallengePhase._id)+1
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography component="h1" className={classes.title}>Avaliação por fases</Typography>
      </Grid>
      { ideaDetails.currentChallengePhase.awaitingQualification && (
        <Grid item xs={12}> 
         <Card elevation={0} className={classes.content}>
           <CardContent>
             <Grid container spacing={4}>
               <ChallengeStepHeader>
                 <ChallengeStepHeaderIcon icon={<AssignmentTurnedInIcon color="primary" /> } text={`avaliar ideia na ${getFaseNumber()}ª fase: ${ideaDetails.currentChallengePhase.name}`}/>
               </ChallengeStepHeader>
               <Grid item xs={12}>
                 <div className={classes.about}>
                   <div className={classes.characters}>
                     <span>{obsChar.length}/10000 caracteres</span>
                   </div>
                   <TextField
                     error={!!errors.description}
                     id="phaseEvaluation"
                     name="phaseEvaluation"
                     label={"observações"}
                     multiline
                     fullWidth
                     rows={8}
                     variant="filled"
                     onChange={handleChangeObs}
                     inputRef={register({
                       maxLength: 10000,
                       minLength: 100,
                       required: 'observação é um campo obrigatório',
                     })}
                     InputLabelProps={{
                       classes: {
                         root: classes.labelRoot,
                       },
                     }}
                     onBlur={handleBlurObs}
                   />
                   <p className={classes.error}>
                     {errors.description && errors.description.message}
                     {errors.description &&
                       errors.description.type === 'maxLength' &&
                       'Você passou o limite de caracteres'}
                   </p>
                 </div>
               </Grid>
               <Grid item xs={12}>
                 <Box display="flex" alignItems="center" justifyContent="flex-end">
                   <Button
                     className={classes.buttonLeft}
                     variant="outlined"
                     color="primary"
                     startIcon={<ThumbUpIcon color="primary" />}
                     onClick={handleNotApproved}
                     disabled={evaluationLoading}
                   >
                     não aprovar
                   </Button>
                   <Button
                     variant="contained"
                     color="primary"
                     startIcon={<ThumbDownIcon color="#fff" />}
                     onClick={handleApproved}
                     disabled={evaluationLoading}
                   >
                     aprovar
                   </Button>
                 </Box>
               </Grid>
             </Grid>
           </CardContent>
         </Card>
       </Grid>
      )}
      <Grid item xs={12}>
        <Card elevation={0} className={classes.content}>
          <CardContent>
            <Grid container spacing={4}>
              <ChallengeStepHeader>
                <ChallengeStepHeaderIcon icon={<AutorenewIcon color="primary" />} text={"progresso da ideia no desafio"}/>
              </ChallengeStepHeader>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  { ideaDetails.challengePhases.map((item, index) => (
                    <>
                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Typography variant="h1" className={classes.phaseItemTitle}>{`${index+1}º FASE: ${item.name}`}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                              <Typography className={classes.phaseItemLabel} variant="p">status:</Typography>
                              { item.evaluation && item.evaluation?.qualified && (
                                <PhaseStatus status="approved" />
                              )}

                              { item.evaluation && !item.evaluation?.qualified && (
                                <PhaseStatus status="notApproved" />
                              )}

                              { !item.evaluation && (
                                <PhaseStatus status="awaiting" />
                              )}
                            </Box>
                          </Grid>
                          { item.evaluation && (
                            <Grid item xs={12}>
                              <Box display="flex" flexDirection="column">
                                <Typography variant="b" className={classes.phaseItemLabel}>observações</Typography>
                                <Typography variant="p" className={classes.phaseEvaluation}>{ item.evaluation.evaluation }</Typography>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      { index+1 !== ideaDetails.challengePhases.length && (
                        <Grid item xs={12}>
                          <hr className={classes.division} />
                        </Grid>
                      )}
                    </>
                  )) } 
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
