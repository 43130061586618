import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  Divider,
} from '@material-ui/core';
import LampIcon from '@material-ui/icons/EmojiObjects';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import BuildIcon from '@material-ui/icons/Build';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AddCommentIcon from '@material-ui/icons/AddComment';
import { Copyright } from '../Shared';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  backgroundActived: {
    borderRadius: 4,
    backgroundColor: theme.palette.primary.lightest,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
}));

const useStylesIcon = makeStyles(() => ({
  root: {
    minWidth: 30,
  },
}));

function SidebarIdeaPoint() {
  const { ideaDetails, page } = useSelector((state) => state.idea);
  const { typeProfile: { type }, _id: userId } = useSelector((state) => state.user);

  const classes = useStyles();
  const classesIcon = useStylesIcon();
  const location = useLocation();
  const { t } = useTranslation();

  function shouldDisableButtonToUsers() {
    if (type === 'colaborator') {
      if ((['AWAITING_QUALIFICATION'].includes(ideaDetails.statusIdea.code)) === false) {
        return false;
      }
      return true;
    }
  }

  function shouldDisableIfNotPublished() {
    if (['AWAITING_QUALIFICATION', 'DRAFT', 'PATENT_PENDING', 'PENDING_REVIEW'].includes(ideaDetails.statusIdea.code)) {
      return true
    } 

    return false
  }

  function shouldDisableIfNotCreated() {
    if (['DRAFT'].includes(ideaDetails.statusIdea.code)) {
      return true
    } 
    return false  }

  function routeExecutionOrConclusion(code) {
    switch (code) {
      case 'EXECUTION_QUEUE':
        return `/idea/${ideaDetails._id}/execution`;
      case 'EXECUTING':
        return `/idea/${ideaDetails._id}/conclusion`;
      default:
        return `/idea/${ideaDetails._id}/general`;
    }
  }

  return (
    <>
      <List style={{ padding: 0 }}>
        <ListItem
          button
          component={Link}
          to={`/idea/${ideaDetails._id}/general`}
          replace
          className={
            page === "general"
              ? classes.backgroundActived
              : ''
          }
        >
          <ListItemIcon classes={classesIcon}>
            <LampIcon
              color={page === "general" ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                color={page === "general" ? 'primary' : 'inherit'}
              >
                {t('ideia_geral')}
              </Typography>
            }
          />
        </ListItem>
        <ListItem
          button
          component={Link}
          to={`/idea/${ideaDetails._id}/engagement`}
          replace
          disabled={shouldDisableIfNotPublished()}
          className={
            page === "engagement"
              ? classes.backgroundActived
              : ''
          }
        >
          <ListItemIcon classes={classesIcon}>
            <AddCommentIcon
              color={page === "engagement" ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                color={page === "engagement" ? 'primary' : 'inherit'}
              >
                {t('engajamento')}
              </Typography>
            }
          />
        </ListItem>
        <ListItem
          button
          disabled={shouldDisableButtonToUsers()}
          component={Link}
          to={`/idea/${ideaDetails._id}/qualification`}
          replace
          className={
            page === "qualification"
              ? classes.backgroundActived
              : ''
          }
        >
          <ListItemIcon classes={classesIcon}>
            <ThumbUpIcon
              color={page === "qualification" ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                color={page === "qualification" ? 'primary' : 'inherit'}
              >
                {t('qualificacao')}
              </Typography>
            }
          />
        </ListItem>
            { ideaDetails.challengePhases && (
              <ListItem
                button
                disabled={shouldDisableIfNotCreated()}
                component={Link}
                to={`/idea/${ideaDetails._id}/phase-review`}
                replace
                className={
                  page === "phase-review"
                    ? classes.backgroundActived
                    : ''
                }
                >
                <ListItemIcon classes={classesIcon}>
                  <AssignmentTurnedInIcon
                    color={
                      page === "phase-review"
                        ? 'primary'
                        : 'inherit'
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle2"
                      color={
                        page === "phase-review"
                          ? 'primary'
                          : 'inherit'
                      }
                    >
                      {t('avaliacao_por_fases')}
                    </Typography>
                  }
                />
                </ListItem>
            )}
        { (ideaDetails.contributors.filter(item => item?._id === userId).length > 0 || ideaDetails.user?._id === userId || type !== 'colaborator') && (
          <ListItem
            button
            disabled={!['EXECUTING', 'EXECUTION_QUEUE'].includes(ideaDetails.statusIdea.code)}
            component={Link}
            to={() =>
              routeExecutionOrConclusion(ideaDetails?.statusIdea?.code)
            }
            replace
            className={
              page === "execution" || page === "conclusion"
                ? classes.backgroundActived
                : ''
            }
            >
              <ListItemIcon classes={classesIcon}>
                <BuildIcon
                  color={page === "execution" || page === "conclusion" ? 'primary' : 'inherit'}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle2"
                    color={page === "execution" || page === "conclusion" ? 'primary' : 'inherit'}
                  >
                    {t('execucao').toLowerCase()}
                  </Typography>
                }
              />
            </ListItem>
        )}
        <ListItem
          button
          disabled={ideaDetails.statusIdea.code !== 'CONCLUDED'}
          component={Link}
          to={`/idea/${ideaDetails._id}/results`}
          replace
          className={
            page === "results"
              ? classes.backgroundActived
              : ''
          }
        >
          <ListItemIcon classes={classesIcon}>
            <AssignmentTurnedInIcon
              color={page === "results" ? 'primary' : 'inherit'}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="subtitle2"
                color={page === "results" ? 'primary' : 'inherit'}
              >
                {t('resultados')}
              </Typography>
            }
          />
        </ListItem>
      </List>
      <Divider style={{ marginTop: 16 }} />          
      <Copyright />
    </>
  );
}

export { SidebarIdeaPoint };
