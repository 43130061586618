import { challengeService, userService } from '../services';
import { challengeConstants } from '../constants';
import { history } from '../helpers';
import { alertActions } from './alert.actions';

export const challengeActions = {
  getChallenges,
  getMoreChallenges,
  getChallengeById,
  getChallengeToEditById,
  getIdeasChallenge,
  updateChallenge,
  deleteChallenge,
  createChallenge,
  nextFormImage,
  setCriteria,
  setPropChallenge,
  setChallenge,
  setImagem,
  removeImage,
  removeImagemServer,
  imageNextCard,
  setDates,
  setDate,
  addAward,
  removeAward,
  setAward,
  setFilterColaborator,
  clearEdit,
  setWinners,
  postWinners,
  setWinnerCard,
  clear,
  clearSelectWinner,
  clearAllWinners,
  clearProgress,
  addNewPhase,
  deletePhase,
  changeFieldCronograma,
  changeChallengeFilter,
  getIdeasByPhase,
  setImageToRemove,
  getChallengesActive,
  clearFilters,
  setFilter,
  selectAllStatus,
  selectStatus,
  resetFilters

};

function getChallenges(page, sort, filter, limit) {
  return (dispatch) => {
    dispatch(request());
    challengeService
      .getChallenges(page, sort, filter, limit)
      .then((challenges) => {
        dispatch(success(challenges, page, filter[0].value, filter[1].value));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: challengeConstants.GET_CHALLENGES_REQUEST };
  }

  function success(challenges, page, query, status) {
    const payload = {
      challenges,
      page,
      query,
      status,
    };

    return { type: challengeConstants.GET_CHALLENGES_SUCCESS, payload };
  }

  function failure(error) {
    return { type: challengeConstants.GET_CHALLENGES_FAILURE, error };
  }
}

function getMoreChallenges(page, sort, filter, limit) {
  return (dispatch) => {
    challengeService
      .getChallenges(page, sort, filter, limit)
      .then((challenges) => {
        dispatch(success(challenges, page, filter[0].value, filter[1].value));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function success(challenges, page, query, status) {
    const payload = {
      challenges,
      page,
      query,
      status,
    };

    return { type: challengeConstants.GET_MORE_CHALLENGES_SUCCESS, payload };
  }

  function failure(error) {
    return { type: challengeConstants.GET_MORE_CHALLENGES_FAILURE, error };
  }
}

function getChallengeById(id) {
  return (dispatch) => {
    dispatch({ type: challengeConstants.GET_CHALLENGE_BY_ID_REQUEST });
    challengeService.getChallengeById(id)
      .then(({ data }) => {
        dispatch({ 
          type: challengeConstants.GET_CHALLENGE_BY_ID_SUCCESS, 
          payload: data.data
        });
      })
      .catch((error) => {
        dispatch({ 
          type: challengeConstants.GET_CHALLENGE_BY_ID_FAILURE, 
          error
        });
      });
  }
}

function getChallengeToEditById(id) {
  return (dispatch) => {
    dispatch({ type: challengeConstants.GET_CHALLENGE_EDIT_REQUEST });
    challengeService.getChallengeById(id)
      .then(({ data }) => {
        dispatch({
          type: challengeConstants.GET_CHALLENGE_EDIT_SUCCESS,
          payload: data.data
        });
      })
      .catch((error) => {
        dispatch({
          type: challengeConstants.GET_CHALLENGE_EDIT_FAILURE,
          error: error
        });
      });
  }
}

function getIdeasChallenge(id, page, sort, limit, status, search = "") {
  return (dispatch) => {
    dispatch(request());
    challengeService
      .getIdeasChallenge(id, page, sort, limit, status, search)
      .then((ideas) => {
        dispatch(success(ideas));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: challengeConstants.GET_IDEAS_CHALLENGE_REQUEST };
  }

  function success(ideas) {
    const payload = {
      ideas,
    };

    return { type: challengeConstants.GET_IDEAS_CHALLENGE_SUCCESS, payload };
  }

  function failure(error) {
    return { type: challengeConstants.GET_IDEAS_CHALLENGE_FAILURE, error };
  }
}

function createChallenge(newChallenge, successMessage) {
  return (dispatch) => {
    dispatch(request());
    challengeService
      .createChallenge(newChallenge)
      .then((challenge) => {
        if (newChallenge.thumbnail.file) {
          challengeService
            .uploadImageChallenge('thumbnailFile', newChallenge.thumbnail, challenge.data._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        };

        if (newChallenge.banner.file) {
          challengeService
            .uploadImageChallenge('bannerFile', newChallenge.banner, challenge.data._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        };
        dispatch(success());
        dispatch(alertActions.success(successMessage));
        history.push('/challenges');
      })
      .catch((error) => {
        // dispatch(alertActions.success(error.response.data.errors));
        dispatch(failure(error));
      });
  };

  function request() {
    return { type: challengeConstants.POST_CHALLENGE_REQUEST }
  };

  function success() {
    return { type: challengeConstants.POST_CHALLENGE_SUCCESS };
  }

  function failure() {
    return { type: challengeConstants.POST_CHALLENGE_FAILURE };
  }
}

function updateChallenge(challenge, successMessage) {
  return (dispatch) => {
    dispatch({ type: challengeConstants.UPDATE_CHALLENGE_REQUEST })
    challengeService
      .updateChallenge(challenge)
      .then(() => {

        if (challenge.thumbnail.file) {
          challengeService
            .uploadImageChallenge('thumbnailFile', challenge.thumbnail, challenge._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        }


        if (challenge.banner.file) {
          challengeService
            .uploadImageChallenge('bannerFile', challenge.banner, challenge._id)
            .then(() => dispatch(success()))
            .catch((error) => {
              dispatch(failure(error));
            });
        };

        dispatch(success());
        dispatch(alertActions.success(successMessage));
        dispatch(clearEdit());
        history.push('/challenges');
      })
      .catch((error) => dispatch(failure(error)));
  };

  function success() {
    return { type: challengeConstants.UPDATE_CHALLENGE_SUCCESS };
  }

  function failure(error) {
    return { type: challengeConstants.UPDATE_CHALLENGE_FAILURE, error };
  }
}

function nextFormImage() {
  return (dispatch) => dispatch({ type: challengeConstants.NEXT_FORM_IMAGE });
}

function setCriteria(criteria) {
  const payload = {
    criteria,
  };
  return (dispatch) =>
    dispatch({ type: challengeConstants.SET_CRITERIA, payload });
}

function setChallenge(challenge) {
  const payload = {
    challenge,
  };

  return (dispatch) =>
    dispatch({ type: challengeConstants.SET_CHALLENGE, payload });
}

function setPropChallenge(value, input) {
  const payload = {
    value,
    input,
  };

  return (dispatch) =>
    dispatch({ type: challengeConstants.SET_PROP_CHALLENGE, payload });
}

function setImagem(type, imagem) {
  const payload = {
    type,
    imagem,
  };

  return (dispatch) =>
    dispatch({ type: challengeConstants.SET_IMAGEM, payload });
}

function removeImage(type) {
  return (dispatch) => dispatch({ type: challengeConstants.REMOVE_IMAGEM, payload: { type } });
}

function removeImagemServer(id, type) {
  return (dispatch) => {
    challengeService
      .removeImage(id, type)
      .then(() => {
        dispatch(success());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function success() {
    return { type: challengeConstants.REMOVE_IMAGEM };
  }
}

function imageNextCard() {
  return (dispatch) => dispatch({ type: challengeConstants.NEXT_CARD_IMAGE });
}

function setDates(dates) {
  const payload = {
    dates,
  };

  return (dispatch) =>
    dispatch({ type: challengeConstants.SET_DATES, payload });
}

function setDate(date, input) {
  const payload = {
    date,
    input,
  };

  return (dispatch) => dispatch({ type: challengeConstants.SET_DATE, payload });
}

function addAward() {
  return (dispatch) => dispatch({ type: challengeConstants.ADD_AWARD });
}

function removeAward(index) {
  const payload = {
    index,
  };

  return (dispatch) =>
    dispatch({ type: challengeConstants.REMOVE_AWARD, payload });
}

function setAward(rewards) {
  const payload = {
    rewards,
  };

  return (dispatch) =>
    dispatch({ type: challengeConstants.SET_AWARD, payload });
}

function clearEdit() {
  return (dispatch) => dispatch({ type: challengeConstants.CLEAR_FORM });
}

function setFilterColaborator() {
  return (dispatch) =>
    dispatch({ type: challengeConstants.SET_FILTER_COLABORATOR });
}

function deleteChallenge(challenge) {
  return (dispatch) => {
    challengeService
      .deleteChallenge(challenge._id)
      .then(() => {
        dispatch(success());
        dispatch(alertActions.success('Seu desafio foi excluído!'));
        history.push('/challenges');
      })
      .catch((error) => dispatch(failure(error)));
  };

  function success() {
    return { type: challengeConstants.CLEAR_FORM };
  }

  function failure(error) {
    return { type: challengeConstants.UPDATE_CHALLENGE_FAILURE, error };
  }
}

function setWinnerCard(position, ideaName, id) {
  return (dispatch) => {
    userService.getUserById(id).then(
      (_user) => {
        let url = _user.attachment ? _user.attachment.url : '';
        dispatch(success({ position, userName: _user.name, ideaName, url }));
      }
    );
  };

  function success(payload) {
    return { type: challengeConstants.SET_WINNER_CARD, payload };
  }
};

function setWinners(payload) {
  return (dispatch) => {
    dispatch({ type: challengeConstants.SET_WINNERS, payload });
  }
}

function postWinners(winners) {
  return (dispatch) => {
    dispatch({ type: challengeConstants.POST_WINNERS_REQUEST })
    console.log(winners)
    challengeService
      .postWinners(winners)
      .then(() => {
        dispatch({ type: challengeConstants.POST_WINNERS_SUCCESS });
        dispatch(alertActions.success('As ideias premiadas foram salvas!'));
      }).catch((_error) => {
        console.log(_error)
        console.log(_error.response)
        dispatch(alertActions.error('Erro configurar premiado'))
        dispatch({ type: challengeConstants.POST_WINNERS_SUCCESS });
      })
  };
}

function clear() {
  return (dispatch) => {
    dispatch({ type: challengeConstants.CLEAR });
  }
};

function clearSelectWinner(index) {
  return (dispatch) => {
    dispatch({ type: challengeConstants.CLEAR_SELECT_WINNER, index });
  }
};

function clearAllWinners() {
  return dispatch => dispatch({ type: challengeConstants.CLEAR_ALL_WINNERS });
}

function clearProgress() {
  return dispatch => dispatch({ type: challengeConstants.CLEAR_PROGRESS });
};

function getChallengesActive() {
  return (dispatch) => {
    dispatch(request());
    challengeService
      .getChallengesActive()
      .then((challenges) => {
        dispatch(success(challenges));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };
  function request() {
    return { type: challengeConstants.GET_CHALLENGES_REQUEST };
  }
  function success(challenges) {
    const payload = {
      challenges,
    };
    return { type: challengeConstants.GET_CHALLENGES_SUCCESS, payload };
  }
  function failure(error) {
    return { type: challengeConstants.GET_CHALLENGES_FAILURE, error };
  }
}
function addNewPhase() {
  return dispatch => {
    dispatch({
      type: challengeConstants.ADD_NEW_CHALLENGE_PHASE
    })
  }
}

function deletePhase(index) {
  return dispatch => {
    dispatch({ type: challengeConstants.DELETE_NEW_CHALLENGE_PHASE, payload: index });
  }
}

function changeFieldCronograma(name, value, index) {
  return dispatch => {
    dispatch({
      type: challengeConstants.CHANGE_NEW_CHALLENGE_FIELD_CRONOGRAMA,
      payload: {
        name,
        value,
        index
      }
    })
  }
}

function changeChallengeFilter(index, value) {
  return dispatch => {
    dispatch({
      type: challengeConstants.CHANGE_CHALLENGE_FILTERS,
      payload: {
        index,
        value
      }
    })
  }
}

function getIdeasByPhase(challengeId, page, sort, filter, statusCode) {
  return dispatch => {
    dispatch({ type: challengeConstants.GET_IDEAS_BY_PHASE_REQUEST })

    challengeService.getIdeasByPhase(challengeId, page, sort, filter, statusCode)
      .then((response) => {
        dispatch({
          type: challengeConstants.GET_IDEAS_BY_PHASE_SUCCESS,
          payload: {
            ideas: response.data,
            paging: response.paging
          }
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({ type: challengeConstants.GET_IDEAS_BY_PHASE_FAILURE })
      })
  }
}

function setImageToRemove(id, type) {
  const payload = { id, type };
  return dispatch => dispatch({ type: challengeConstants.SET_IMAGE_TO_REMOVE, payload });
}

function clearFilters() {
  return dispatch => dispatch({ 
    type: challengeConstants.CLEAR_CHALLENGE_FILTERS,
  });
}

function setFilter(name, value) {
  const payload = { name, value };
  return dispatch => dispatch({ 
    type: challengeConstants.SET_CHALLENGE_FILTER, 
    payload
  });
}

function selectAllStatus() {
  return dispatch => {
    dispatch({ type: challengeConstants.SET_ALL_CHALLENGE_STATUS_CODE })
  }
}

function selectStatus(value) {
  return dispatch => {
    dispatch({ type: challengeConstants.SET_CHALLENGE_STATUS_CODE, payload: { value } })
  }
}

function resetFilters() {
  return dispatch => {
    dispatch({ type: challengeConstants.RESET_CHALLENGE_FILTERS })
  }
}