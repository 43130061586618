import React from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  useTheme,
  Typography
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';

import { tableCss } from './TableCss';
import { PointsRuleTable } from '../Shared';
import { walletActions } from '../../actions';
import { dateFormat, truncate, history } from '../../helpers';
import { CustomTooltip } from '../Shared';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

function WalletTable({ wallets, paging, id }) {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const language = useSelector((state) => state.language);

  const style = {
    text: t('linhas_por_pagina'),
  };
  const tableClasses = tableCss(style);
  const theme = useTheme();
  const columns = [
    { id: 'date', label: t('data') },
    { id: 'action', label: t('acao') },
    { id: 'idea', label: t('ideia') },
    { id: 'points', label: t('pontos').toLowerCase(), rule: <PointsRuleTable /> },
  ];

  function getIconsWallet(type) {
    switch (type) {
      case 'COMMENT_UP_GIVEN':
        return <ThumbUpIcon />;

      case 'COMMENT_UP_GIVEN_REMOVED':
        return <ThumbDownIcon />;

      case 'COMMENT_UP_RECEIVED':
        return <ThumbUpIcon />;

      case 'COMMENT_UP_RECEIVED_REMOVED':
        return <ThumbDownIcon />;

      case 'IDEA_APPROVED':
        return <CheckCircleIcon />;

      case 'IDEA_COMMENT':
        return <ChatBubbleIcon />;

      case 'IDEA_COMMENT_GIVEN':
        return <ChatBubbleIcon />;

      case 'IDEA_COMMENT_GIVEN_COMMENT':
        return <ChatBubbleIcon />;

      case 'IDEA_COMMENT_REMOVED':
        return <ChatBubbleIcon />;

      case 'IDEA_COMMENT_GIVEN_REMOVED':
        return <ChatBubbleIcon />;

      case 'IDEA_EXECUTED':
        return <CheckCircleIcon />;

      case 'IDEA_UP':
        return <ThumbUpIcon />;

      case 'IDEA_UP_GIVEN':
        return <ThumbUpIcon />;

      case 'IDEA_UP_GIVEN_REMOVED':
        return <ThumbDownIcon />;

      case 'IDEA_UP_REMOVED':
        return <ThumbDownIcon />;

      case 'NEW_IDEA':
        return <EmojiObjectsIcon />;

      case 'NEW_USER':
        return <PersonIcon />;

      default:
        return null;
    }
  }

  function handleChangePage(event, page) {
    let newPage = page + 1;
    dispatch(walletActions.setPage(newPage));
    dispatch(
      walletActions.getWallets(
        id,
        newPage,
        paging.quantityPerPage,
        paging.filter,
        paging.sort
      )
    );
  }

  function handleChangeRowsPerPage(event) {
    paging.quantityPerPage = event.target.value || 10;

    dispatch(walletActions.setQuantityPerPage(paging.quantityPerPage));

    dispatch(
      walletActions.getWallets(
        id,
        1,
        paging.quantityPerPage,
        paging.filter,
        paging.sort
      )
    );
  }

  function handleView(id) {
    history.push(`/idea/${id}/general`);
  }
  return (
    <div>
      <Paper className={tableClasses.paperRoot}>
        <TableContainer>
          <Table
            stickyHeader
            aria-label="idea table"
            classes={{ root: tableClasses.table }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    classes={{ root: tableClasses.tableCellHeader }}
                  >
                    {column.label}
                    {column.rule}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {wallets &&
                wallets.map((wallet) => {
                  return (
                    <TableRow
                      hover
                      key={wallet._id}
                      style={{ cursor: 'pointer' }}
                      classes={{ root: tableClasses.tableRow }}
                    >
                      <TableCell
                        classes={{ root: tableClasses.tableCellRadiusLeft }}
                      >
                        {dateFormat(wallet.createdAt, language)}
                      </TableCell>
                      <TableCell classes={{ root: tableClasses.tableCell }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ marginRight: 10, color: theme.palette.neutrals.low.light }}>
                            {getIconsWallet(wallet.score?.type)}
                          </div>
                          <div>{wallet.score?.label}</div>
                        </div>
                      </TableCell>
                      <TableCell
                        className={tableClasses.oneLine}
                        classes={{ root: tableClasses.tableCell }}
                      >
                        <CustomTooltip 
                            title={wallet.idea.name} 
                            aria-label={wallet.idea.name} 
                            placement="bottom-start"
                          >
                            <Typography 
                              variant="subtitle2" 
                              className={tableClasses.link} 
                              onClick={() => handleView(wallet.idea._id)}
                            >
                        {truncate(wallet.idea?.name, 30)}
                            </Typography>
                          </CustomTooltip>
                      </TableCell>
                      <TableCell
                        className={tableClasses.oneLine}
                        classes={{ root: tableClasses.tableCell }}
                      >
                        {wallet.amount}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          component="div"
          classes={{
            input: tableClasses.paginationInput,
            spacer: tableClasses.paginationSpacer,
            caption: tableClasses.paginationCaption,
            toolbar: tableClasses.paginationToolbar,
          }}
          rowsPerPageOptions={[10, 20, 30, 40, 50]}
          count={paging.total}
          rowsPerPage={paging.quantityPerPage}
          page={paging.page - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={t('exibindo')}
        />
      </Paper>
    </div>
  );
}

export { WalletTable };
