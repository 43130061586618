import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, AlertTitle } from '@material-ui/lab';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { alertActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 12,
    fontWeight: 600,
    color: (props) => (props.severity === 'success' ? theme.palette.auxiliary.green.main : 'red'),
  },
  content: {
    fontSize: 14,
    color: theme.palette.neutrals.low.dark,
  },
  root: {
    zIndex: 9999999,
  },
  alert: {
    borderLeft: '8px solid',
    borderColor: (props) => (props.severity === 'success' ? theme.palette.auxiliary.green.main : 'red'),
    backgroundColor: theme.palette.neutrals.high.main,
  },
  icon: {
    width: 20,
    height: 20,
    color: theme.palette.auxiliary.green.main,
  }
}));

export function SnackAlert(props) {
  const classes = useStyles(props);
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.close();
    setOpen(false);
  };

  useEffect(() => {
    return () => {
      dispatch(alertActions.clear());
    };
  }, [dispatch]);

  function renderTitle() {
    if (props.severity === 'success') {
      return t('sucesso');
    }

    return t('erro');
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      classes={{ root: classes.root }}
    >
      <Alert
        onClose={handleClose}
        severity={props.severity}
        elevation={6}
        className={classes.alert}
        iconMapping={{ success: <CheckCircleIcon fontSize="large" className={classes.icon} /> }}
      >
        <AlertTitle className={classes.title}>{renderTitle()}</AlertTitle>
        <div className={classes.content}>{props.message}</div>
      </Alert>
    </Snackbar>
  );
}