import { Box, Collapse, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, Typography, withStyles } from "@material-ui/core";
import TableRow from '@material-ui/core/TableRow';
import { KeyboardArrowDown, KeyboardArrowUp, MoreHoriz, SubdirectoryArrowRight } from "@material-ui/icons";
import clsx from "clsx";
import { truncate } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
// import convertMongoIdToUTCDate from "../../../helpers/convertMongoIdToUTCDate";
import { CustomTooltip } from "../../Shared";
import { tableCss } from "../../Tables/TableCss";
import { TagInfrastructure } from "../TagInfrastructure";


const useRowStyles = makeStyles((theme) => ({
    hidden: {
        backgroundColor: "unset",
        border: "unset",
    },
    menuText: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.neutrals.low.medium,
    },
    avatar: {
        width: 20,
        height: 20,
        marginRight: theme.spacing(1),
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light
    },
    root: {
        cursor: 'default',
        marginRight: 15,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
}));

const TableRowStyled = withStyles({
    root: {
        borderRight: `1px solid #E7EaED`,
        borderLeft: `1px solid #E7EaED`,
    },
})(TableRow);

export default function InfraStructureCollapseRow({ row = {
    _id: {
        businessUnitId: "",
        name: ""
    },
    totalLocals: 0,
    totalOccupationAreas: 0,
    locals: [{ name: "", occupationAreas: [{ name: "" }] }]
} }) {
    const classes = useRowStyles();
    const tableClasses = tableCss();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = useState(null);


    function handleOpenMenu(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleCloseMenu() {
        setAnchorEl(null);
    }

    return (<>
        <TableRow className={clsx(classes.root, tableClasses.tableRow)}>
            <TableCell style={{ maxWidth: 540 }} classes={{ root: tableClasses.tableCellRadiusLeft }}>
                <Box display="flex" alignItems="center">
                    <IconButton aria-label="expand row" size="small" style={{ marginRight: 15 }} onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                    <Typography
                    >
                        {/* {moment(convertMongoIdToUTCDate(row._id.businessUnitId)).format('DD/MM/YYYY')} */}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell align="left" classes={{ root: tableClasses.tableCell }}>
                {row._id.name}
            </TableCell>
            <TableCell align="left" classes={{ root: tableClasses.tableCell }}>
                <TagInfrastructure type={'businessUnit'} />
            </TableCell>
            <TableCell classes={{ root: tableClasses.tableCell }}>
                {row.totalLocals}
            </TableCell>
            <TableCell classes={{ root: tableClasses.tableCell }}>
                {row.totalOccupationAreas}
            </TableCell>
        </TableRow>
        <TableRow className={tableClasses.tableRow}>
            <TableCell style={{ padding: 0 }} className={open ? tableClasses.tableRow : classes.hidden} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Typography style={{
                        margin: '17px 0 0 17px', fontWeight: 600,
                        fontSize: 16
                    }}>{t('vinculos').toLowerCase()}</Typography>
                    <Table size="medium" classes={{ root: tableClasses.table }}>
                        <TableHead>
                            <TableRow>
                                <TableCell classes={{ root: tableClasses.tableCellHeader }}>
                                    {t('unidade_de_negocio')}
                                </TableCell>
                                <TableCell classes={{ root: tableClasses.tableCellHeader }}>
                                    {t('local')}
                                </TableCell>
                                <TableCell classes={{ root: tableClasses.tableCellHeader }}>
                                    {t('area_de_ocupacao')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                row.locals.map(({ name, occupationAreas }, index) => (

                                    <TableRowStyled className={tableClasses.tableRow} key={index}>
                                        <TableCell align="left" className={tableClasses.oneLine} style={{ maxWidth: 540 }}>
                                            {truncate(row._id.name, 60)}
                                        </TableCell>
                                        <TableCell align="left" className={tableClasses.oneLine} style={{ maxWidth: 540 }}>

                                            {truncate(name)}
                                        </TableCell>
                                        <TableCell align="left" className={tableClasses.oneLine} style={{ maxWidth: 540 }}>
                                            {occupationAreas.map(({ name }) => <Typography>{truncate(name, 60)}</Typography>)}
                                        </TableCell>
                                    </TableRowStyled>
                                ))
                            }
                        </TableBody>
                    </Table>
                </Collapse>
            </TableCell>
        </TableRow>
    </>)
}