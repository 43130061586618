export const executionConstants = {
  CREATE_IDEA_ARRAY_COSTS: "CREATE_IDEA_ARRAY_COSTS",
  POST_EXECUTION_IDEA_REQUEST: 'POST_EXECUTION_IDEA_REQUEST',
  POST_EXECUTION_IDEA_SUCCESS: 'POST_EXECUTION_IDEA_SUCCESS',
  POST_EXECUTION_IDEA_FAILURE: 'POST_EXECUTION_IDEA_FAILURE',
  ADD_EXECUTION_COST_FIELD: "ADD_EXECUTION_COST_FIELD",
  CHANGE_EXECUTION_COST: "CHANGE_EXECUTION_COST",
  CHANGE_EXECUTION_FINANCIAL_RETURN: "CHANGE_EXECUTION_FINANCIAL_RETURN",
  CHANGE_EXECUTION_DETAILS: "CHANGE_EXECUTION_DETAILS",
  DELETE_EXECUTION_COST_FIELD: "DELETE_EXECUTION_COST_FIELD",
  SET_INPUT_EXECUTION: 'SET_INPUT_EXECUTION',
  SET_COSTS: 'SET_COSTS',
  REMOVE_COST: 'REMOVE_COST',
  IDEA_EXECUTION_CLEAR: 'IDEA_EXECUTION_CLEAR',
  GET_EXECUTED_IDEA_REQUEST: "GET_EXECUTED_IDEA_REQUEST",
  GET_EXECUTED_IDEA_SUCCESS: "GET_EXECUTED_IDEA_SUCCESS",
  GET_EXECUTED_IDEA_FAILURE: "GET_EXECUTED_IDEA_FAILURE"
};
