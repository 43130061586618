import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ImagineLinkify } from '../TextLinkify';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: theme.spacing(3),
    color: theme.palette.neutrals.low.main,
  },
  label: {
    color: theme.palette.neutrals.low.medium,
  },
  texto: {
    whiteSpace: 'pre-line',
  },
}));

export function ChallengeInfoDetails({ challenge }) {
  const { name, description, goal, requirement } = challenge;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3" className={classes.title}>{name}</Typography>
        <Typography variant="body2" className={classes.texto}>
          <ImagineLinkify>
            {description}
          </ImagineLinkify>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" className={classes.label}>{t('objetivo')}</Typography>
        <Typography variant="body2" className={classes.texto}>
          <ImagineLinkify>
            {goal}
          </ImagineLinkify>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle2" className={classes.label}>{t('criterios')}</Typography>
        <Typography variant="body2" className={classes.texto}>
          <ImagineLinkify>
            {requirement}
          </ImagineLinkify>
        </Typography>
      </Grid>
    </Grid>
  );
}