import React from 'react';
import {
  makeStyles,
  Box,
  Container,
  Toolbar,
} from '@material-ui/core';
import logo from '../../assets/imgs/logo-imagine_neutral-high-main.svg';

const useStyles = makeStyles ((theme) => ({
  root: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.primary.dark,
    padding: theme.spacing(2, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  directionMobile: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
  },
  logoFooter: {
    width: 200,
  },
  spacer: {
    flexGrow: 1
  },
  infoFooter: {
    maxWidth: 460,
    color: theme.palette.neutrals.high.main,
    lineHeight: 2,

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      textAlign: 'center',
    },
  }
}));

function FooterTerms (){
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box className={classes.footer}>
        <Container maxWidth="lg">
          <Toolbar className={classes.directionMobile}>
            <img src={logo} alt="Logo" className={classes.logoFooter} />
            <div className={classes.spacer}></div>
            <div className={classes.infoFooter}>
              O imagine é uma plataforma colaborativa de ideias, criada para dar voz
              aos colaboradores da empresa, otimizando o uso do capital intelectual,
              acelerando o processo de aculturamento digital e inovação.
            </div>
          </Toolbar>
        </Container>
      </Box>
    </div>
  );
}

export { FooterTerms };