import config from '../config';
import axios from 'axios';
import { handleResponse, requestHeaders } from '../helpers';
import { authHeader } from '../helpers/auth-headers';

export const externalContentService = {
    getExternalContentList,
    getExternalContentById,
    createContent,
    editContent,
    deleteContent,
    postContentImage
}

function getExternalContentList(page, query, limit, statusList) {
    const params = {
        page,
        sort: { createdAt: -1 },
        limit,
    }

    let url = `${config.URL_BASE}/contents`;

    if (statusList.length == 1) {
        url += '/status/' + ((statusList[0] == 'active') ? 'true' : 'false');
    }

    return axios
        .get(url, {
            params,
            ...requestHeaders(),
        })
        .then(handleResponse)
}

function getExternalContentById(id) {
    return axios.get(
        `${config.URL_BASE}/contents/${id}`,
        requestHeaders()
    )
    .then(handleResponse)
}

function createContent(data) {
    return axios
        .post(`${config.URL_BASE}/contents`, {
            ...data,
            status: true,
        }, {
            ...requestHeaders()
        })
        .then(handleResponse)
}

function editContent(data, id) {
    return axios
        .put(`${config.URL_BASE}/contents/${id}`, data, requestHeaders());
}

function deleteContent(id) {
    return axios
        .delete(
            `${config.URL_BASE}/contents/${id}`,
            requestHeaders()
        )
}

function postContentImage(file, id) {
  const data = new FormData();
  data.append('file', file);
  return axios.post(`${config.URL_BASE}/uploads/content/${id}`, data, requestHeaders()).then(handleResponse);
}