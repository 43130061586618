import React, { useEffect, useRef, useState } from 'react';
import {
  Grid,
  Box,
  makeStyles,
  Button,
  Container,
} from "@material-ui/core";
import { BackNavAdmin } from "./../../components";
import { FileCopyOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from "react-redux";
import { reportActions, challengeActions } from "./../../actions";
import { ReportHeader } from "./ReportHeader";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { formatDate } from '../../utils/date';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 14,
      paddingRight: 14,
    },
  },
  buttonsNavbar: {
    color: theme.palette.neutrals.low.main,
    borderColor: theme.palette.neutrals.low.main,
    "&:not(:first-child)": {
      marginLeft: 16
    }
  },
}))

export const ReportLayout = ({ children, title, tableInfo }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const componentToPrint = useRef();
  const { imagineLocals, statusIdea } = useSelector(state => state.domains)
  const {
    paging,
    filters,
    report,
    csvLoading,
    csvBase64,
    tab,
    chart,
  } = useSelector(state => state.report);
  const { local, occupationArea, businessUnit } = useSelector(state => state.businessUnitProps)
  const { challenges, loading, paging: { sort, filter } } = useSelector((state) => state.challenge);
  const [splittedPathname] = useState(history.location.pathname.split("/"))
  const { t } = useTranslation();

  useEffect(() => {
    if (imagineLocals) {
      let filteredLocals = [...new Set(imagineLocals.map(item => ({
        value: item.local._id,
        label: item.local.name,
        checked: false,
      })).map(o => JSON.stringify(o)))].map(s => JSON.parse(s))

      dispatch(reportActions.loadLocal([
        {
          value: "SELECT_ALL",
          label: "Selecionar Todos",
          checked: false,
        },
        ...filteredLocals,
      ]))
    }
  }, [imagineLocals, dispatch])

  useEffect(() => {
    if (statusIdea) {
      const statusFiltered = statusIdea.filter(item => item.code !== "DRAFT").map(item => ({
        value: item.code,
        label: item.name,
        checked: true,
      }));
      const selectAllOption = {
        checked: true,
        label: t('selecionar_todos'),
        value: 'SELECT_ALL'
      };
      dispatch(reportActions.loadStatus([selectAllOption,...statusFiltered]))
    }
  }, [statusIdea, dispatch])

  useEffect(() => {
    if (!loading) {
      dispatch(challengeActions.getChallenges(1, sort, filter));
    }
  }, [dispatch]);

  useEffect(() => {
    if (challenges) {
      dispatch(reportActions.loadChallenge(challenges.map(item => ({
        value: item._id,
        label: item.name,
        checked: false,
      }))))
    }
  }, [challenges, dispatch]);

  useEffect(() => {
      dispatch(reportActions.loadProperties(tableInfo.map(item => ({
        value: item?.id,
        label: item?.label,
        checked: true,
      }))))
  }, [dispatch]);

  useEffect(() => {
    let params = {
      challenge: filters.challenges.map(item => item.value),
      statusCode: filters.status.map(item => item.value),
      businessUnits: businessUnit.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      locals: local.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      occupationArea: occupationArea.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      startDate: formatDate(filters.startDate),
      endDate: formatDate(filters.endDate),
      reportType: splittedPathname[splittedPathname.length - 1],
    }

    if (tab === "graph" && report.length > 0 && chart.length === 0) {
      params = {
        ...params,
        page: -1,
      }
      dispatch(reportActions.getReportDataChart(params))
    }

    if (tab === "table" && chart.length > 0 && report.length === 0) {
      params = {
        ...params,
        page: 1,
        query: '',
        limit: paging.limit,
      }
      dispatch(reportActions.getReportData(params))
    }
  }, [tab])

  useEffect(() => {
    if (csvBase64) {
      const linkSource = `data:text/csv;base64,${csvBase64.substring(csvBase64.indexOf(";") + 1)}`;
      const downloadLink = document.createElement("a");
      const fileName = getCsvname();
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  }, [csvBase64])

  function handleClickDownloadCsv() {
    dispatch(reportActions.getReportCsv({
      statusCode: filters.status.map(item => item.value),
      businessUnits: businessUnit.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      locals: local.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      occupationArea: occupationArea.filter(item => item.checked && item.value !== "SELECT_ALL").map(item => item.value),
      startDate: formatDate(filters.startDate),
      endDate: formatDate(filters.endDate),
      page: -1,
      delimiter: ";",
      reportType: splittedPathname[splittedPathname.length - 1],
    }));
  }

  const getCsvname = () => {
    return splittedPathname[splittedPathname.length - 1];
  }

  const handlePrint = useReactToPrint({
    content: () => componentToPrint.current,
  });

  return (
    <Grid container>
      <Grid item xs={12}>
        <BackNavAdmin title={title}>
          <Box display="flex" alignItems="center">
            {/*  <Button
              className={classes.buttonsNavbar}
              variant="outlined"
              startIcon={<Print />}
              onClick={handlePrint}
            >
              {t('imprimir')}
            </Button> */}
            <Button
              className={classes.buttonsNavbar}
              variant="outlined"
              disabled={report.length === 0 || csvLoading}
              startIcon={<FileCopyOutlined />}
              onClick={handleClickDownloadCsv}
            >
              export excel
            </Button>
          </Box>
        </BackNavAdmin>
      </Grid>
      <Grid item xs={12}>
        <Container maxWidth="lg" className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <ReportHeader properties={tableInfo} />
            </Grid>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  )
}
