import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Grid,
  Button,
  Container,
  makeStyles,
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { crudTemplateActions } from '../../actions';
import { BackNavAdmin } from '../../components/Shared';
import { TemplateCrudHeader } from '../../components/TemplateCrud';
import { selectEndPoint } from '../../helpers/selectEndPointToTemplate';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 2),
    },
  },
  btnNewItem: {
    padding: theme.spacing(1, 2),
    float: 'right'
  },
}));

export function TemplateCrudLayout({ title, children }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { paging, type, typePage } = useSelector(state => state.crudTemplate);

  useEffect(() => {
    dispatch(crudTemplateActions.clearBodyFields());
  }, []);

  useEffect(() => {
    dispatch(crudTemplateActions.getAll({
      endPoint: selectEndPoint(type),
      page: paging?.page,
      limit: paging?.limit,
      sort: paging?.sort
    }));
  }, [type, typePage]);


  function handleClick() {
    dispatch(crudTemplateActions.setTypePage('crud'));
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <BackNavAdmin title={title}>
          <Box display="flex" alingItems="center">
            <Button
              className={classes.btnNewItem}
              variant="contained"
              color="primary"
              type="submit"
              startIcon={<AddCircleIcon />}
              component={Link}
              onClick={handleClick}
            >
              {t('novo_item')}
            </Button>
          </Box>
        </BackNavAdmin>
      </Grid>
      <Grid item xs={12}>
        <Container maxWidth="lg" className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <TemplateCrudHeader />
            </Grid>
            <Grid item xs={12}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
}