import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(3)
  }
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  rect: {
    borderRadius: 4,
  },
})) (MuiSkeleton);

export function SkeletonIdeaEditPage() {
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Grid item xs={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card elevation={0} className={classes.card}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Skeleton variant="circle" width={56} height={56} />
                    <Box display="flex" flexDirection="column" marginLeft="12px">
                      <Skeleton variant="rect" width={200} height={24.5} />
                      <Skeleton variant="rect" width={150} height={17} style={{ margin: '4px 0' }} />
                      <Skeleton variant="rect" width={100} height={17} />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Skeleton variant="rect" width={50} height={23} style={{ marginRight: 8 }} />
                    <Skeleton variant="rect" width={117} height={23} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rect" width="100%" height={54} />
                </Grid>
                <Grid item xs={12}>
                  <Skeleton variant="rect" width="100%" height={181} />
                </Grid>
              </Grid>
            </Card>
          </Grid>

          {[0,1,2].map((item, index) => (
            <Grid item xs={12} key={index}>
              <Card elevation={0} className={classes.card}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Box display="flex">
                        <Skeleton variant="circle" width={40} height={40} style={{ marginRight: 8 }} />
                        <Skeleton variant="rect" width={150} height={35} />
                      </Box>
                      <Skeleton variant="rect" width={180} height={30} />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <Skeleton variant="rect" width="100%" height={200} />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton variant="rect" width="90%" height={20} />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={4}>
        <Card elevation={0} className={classes.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="100%" height={52} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="100%" height={52} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}