import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent'
import GroupIcon from '@material-ui/icons/Group';
import EventIcon from '@material-ui/icons/Event';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import { Box, CardActionArea } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '10px',
    width: 250
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
    color: '#2F2F2F',
  },
  description: {
    color: '#2F2F2F',
    fontSize: 13,
  },
  media: {
    height: 220,
    width: '100%',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '1px solid #E9E9E9',
    padding: theme.spacing(2),
  },
  information: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
  },
  containerMedia: {
    position: 'relative',
  },
  status: {
    position: 'absolute',
    left: 16,
    top: 16,
    background: '#FFF3F5',
    padding: theme.spacing(0.4, 0.8),
    fontSize: 11.24,
    color: ({ status }) => status,
    borderRadius: 4,
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  icon: {
    color: '#ABABAB',
  },
  containerInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginLeft: 16,
    marginRight: 20,
  },
  infoText: {
    color: '#585858',
    fontSize: 12,
    fontWeight: 600,
  },
  infoDate: {
    color: '#434343',
    fontSize: 13,
  },
}));


function SkeletonChallengeCard() {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Card
      className={classes.root}
    >     <Skeleton animation="wave" width="100%" height="150px" variant="rect" />
      <CardActionArea style={{ padding: 0 }}>
        <CardContent>
        <Skeleton animation="wave" variant='text' width='80%' />
          <Skeleton animation="wave" variant='text' />
          <Skeleton animation="wave" variant='text' />
          <Skeleton animation="wave" variant='text' />
          <Skeleton animation="wave" variant='text' />
          <Box className={classes.information}>
            <EventIcon className={classes.icon} />
            <Box className={classes.containerInfo}>
              <Box>
                <Box className={classes.infoText}>{t('inicio')}</Box>
                <Box className={classes.infoDate}>
                  <Skeleton animation="wave" variant='text' />
                </Box>
              </Box>
              <Box>
                <Box className={classes.infoText}>{t('termino')}</Box>
                <Box className={classes.infoDate}>
                  <Skeleton animation="wave" variant='text' />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.information}>
            <EmojiEventsIcon className={classes.icon} />
            <Box className={classes.containerInfo}>
              <Box>
                <Box className={classes.infoText}>{t('premiacao')}</Box>
                <Skeleton animation="wave" variant='text' />
              </Box>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.actions}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <GroupIcon color="primary"></GroupIcon>
          <Box style={{ marginLeft: 6 }}>
            <Skeleton animation="wave" variant='text' />
            <span style={{ color: '#2F2F2F', fontSize: 13 }}>
              {t('ideias').toLowerCase()}
            </span>
          </Box>
        </Box>
      </CardActions>
    </Card>
  );
}

export { SkeletonChallengeCard };
