import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MuiCard from '@material-ui/core/Card';
import { styled, withStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
  padding: theme.spacing(4)
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  rect: {
    borderRadius: 4
  },
}))(MuiSkeleton);

export function SkeletonChallengeDetails() {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Skeleton variant="rect" width={130} height={25} />
          <Skeleton variant="rect" width={130} height={25} style={{ margin: '0 16px' }} />
          <Skeleton variant="rect" width={130} height={25} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" width="100%" height={203} />
      </Grid>
      <Grid item xs={12} lg={8}>
        <Card elevation={0}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Skeleton variant="rect" width="50%" height={25} />
              <Skeleton variant="rect" width="100%" height={80} style={{ marginTop: 16 }} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width={100} height={25} />
              <Skeleton variant="rect" width="100%" height={45} style={{ marginTop: 8 }} />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rect" width={100} height={25} />
              <Skeleton variant="rect" width="100%" height={45} style={{ marginTop: 8 }} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} lg={4}>
        <Card elevation={0}>
          <Grid container spacing={3}>
          <Grid item xs={12}>
              <Skeleton variant="rect" width="50%" height={21} />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Skeleton variant="circle" width={30} height={30} />
                <Skeleton variant="rect" width="70%" height={30} style={{ marginLeft: 8 }} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Skeleton variant="circle" width={30} height={30} />
                <Skeleton variant="rect" width="70%" height={30} style={{ marginLeft: 8 }}  />
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} lg={8}>
        <Card elevation={0}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Skeleton variant="rect" width={130} height={21} />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Skeleton variant="circle" width={48} height={48} />
                <Skeleton variant="rect" width="90%" height={48} style={{ marginLeft: 16 }} />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Skeleton variant="circle" width={48} height={48} />
                <Skeleton variant="rect" width="90%" height={48} style={{ marginLeft: 16 }} />
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}