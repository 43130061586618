import React, { useEffect, useState } from 'react';
import { Container, Grid, makeStyles, Button, Card, CircularProgress, Box } from '@material-ui/core';
import {
  BackNavAdmin,
  ChallengeCreateCard,
  ChallengeImageCard,
  ChallengeCriteriaCard,
  ChallengeDatesCard,
  ChallengeAwardCard,
  DialogImagine,
} from '../../components';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { challengeActions } from '../../actions';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import { history } from '../../helpers';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: 20,
  },
  containerLoading: {
    marginTop: theme.spacing(30),
    display: 'flex',
    justifyContent: 'center',
  },
}));

function ChallengeEditPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [dialogDeleteOpened, setDialogDeleteOpen] = useState(false);
  const classes = useStyles();
  const {
    newChallenge: {
      name,
      description,
      goal,
      thumbnail,
      banner,
      requirement,
      startDate,
      dueDate,
      resultDate,
      rewards,
      phases,
      imagesToRemove
    },
    newChallenge,
    loadingEdit
  } = useSelector((state) => state.challenge);

  const { t } = useTranslation();
  useEffect(() => {
    dispatch(challengeActions.getChallengeToEditById(id));

    return () => {
      dispatch(challengeActions.clearEdit());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function editChallenge() {
    if (!!imagesToRemove?.length) {
      imagesToRemove.forEach(({ id, type }) => {
        dispatch(challengeActions.removeImagemServer(id, type));
      });
    };
    let data = {
      ...newChallenge,
      phases: phases || []
    }
    dispatch(challengeActions.updateChallenge(data, t('seu_desafio_foi_editado')));
  }
  function deleteChallenge() {
    dispatch(challengeActions.deleteChallenge(newChallenge));
  }

  function handleOpenDeleteDialog() {
    setDialogDeleteOpen(!dialogDeleteOpened);
  };

  return (
    <>
      <BackNavAdmin title={t('editar_desafio')} />
      <Container maxWidth="lg">
        <DialogImagine
          open={dialogDeleteOpened}
          onClose={handleOpenDeleteDialog}
          title={t('deseja_excluir_este_desafio')}
          description={t('as_informacoes_deste_desafio_seram_excluidas_permanentemente')}
        >
          <Box>
            <Button variant="outlined" color="primary" onClick={() => handleOpenDeleteDialog()}>
              {t('voltar')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 23 }}
              onClick={() => deleteChallenge()}
            >
              {t('excluir')}
            </Button>
          </Box>
        </DialogImagine>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {loadingEdit ? (
              <Container maxWidth="lg" className={classes.containerLoading}>
                <CircularProgress />
              </Container> 
            ) : (
              <>
                <div className={classes.card}>
                  {name && goal && description && (
                    <ChallengeCreateCard />
                  )}
                </div>

                <Card className={classes.card}>
                  <ChallengeImageCard
                    type="thumbnail"
                    imagem={thumbnail}
                  />
                  <ChallengeImageCard
                    type="banner"
                    imagem={banner}
                  />
                </Card>

                <div className={classes.card}>
                  {requirement && (
                    <ChallengeCriteriaCard />
                  )}
                </div>

                <div className={classes.card}>
                  {startDate && dueDate && resultDate && (
                    <ChallengeDatesCard phases={phases} />
                  )}
                </div>

                <div className={classes.card}>
                  {rewards && rewards[0] && rewards[0]?.description && (
                    <ChallengeAwardCard rewards={rewards} />
                  )}
                </div>

                <div
                  className={classes.card}
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Button color="primary" onClick={() => history.goBack()}>
                    {t('cancelar')}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon style={{ color: 'white' }} />}
                    onClick={handleOpenDeleteDialog}
                  >
                    {t('excluir_desafio')}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SaveIcon style={{ color: 'white' }} />}
                    onClick={editChallenge}
                  >
                    {t('salvar_desafio')}
                  </Button>
                </div>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export { ChallengeEditPage };
