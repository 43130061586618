import React from 'react';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4)
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    marginTop: 12
  }
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  rect: {
    borderRadius: 4
  }
}))(MuiSkeleton);

export function SkeletonEvaluationTeams() {
  const classes = useStyles();
  const arrayTeams = Array.from(Array(3));

  return(
    <Card className={classes.card} elevation={0}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.flex} style={{ paddingBottom: 32 }}>
          <Skeleton variant="circle" width={40} height={40} />
          <Skeleton variant="rect" width={200} height={40} />
        </Grid>
        <Grid item xs={4}>
          <Skeleton variant="rect" width={100} height={15} />
        </Grid>
        <Grid item xs={6}>
          <Skeleton variant="rect" width={100} height={15} />
        </Grid>
        <Grid item xs={1}>
          <Skeleton variant="rect" width="100%" height={15} />
        </Grid>
        <Grid item xs={1}>
          
        </Grid>
        <Grid item xs={12}>
          {arrayTeams.map((item, index) => (
            <Grid container spacing={3} key={index}>
              <Grid item xs={4} className={classes.flex}>
                <Skeleton variant="rect" width="100%" height={20} />
              </Grid>
              <Grid item xs={6} className={classes.flex}>
                <Skeleton variant="rect" width="100%" height={20} />
              </Grid>
              <Grid item xs={1} className={classes.flex}>
                <Skeleton variant="rect" width="100%" height={20} />
              </Grid>
              <Grid item xs={1} className={classes.flex}>
                <Skeleton variant="circle" width={30} height={30} />
              </Grid>
              {index !== (arrayTeams?.length - 1) && (
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  );
} 