import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }
});

export function EmptyState({ icon, description }) {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item>
        <img src={icon} alt="icon empty state" width="96px" height="96px" />
      </Grid>
      <Grid item>
        <Typography variant="body1" align="center">
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
}