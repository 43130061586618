const email = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const phone = /\(\d{2}\)\d{4,}-\d{4}/g;

function goodPassword(password) {
  //a custom validator!
  return (
    !password || (password.length >= 8 && /(?=.*[!@#$%^&*])/.test(password))
  );
}

function sameAs(repeatPassword, password) {
  return repeatPassword === password ? true : false;
}

export const validation = {
  email,
  goodPassword,
  sameAs,
  phone,
};

export function checkObjectPropertyEmpty(object) {
  return Object.values(object).some(item => item === null || item === "")
}