import { challengeConstants } from '../constants';
import update from 'react-addons-update';
import filesize from 'filesize';
import { checkObjectPropertyEmpty } from "./../helpers/validation"

const initialStatePhase = {
  name: "",
  score: "0",
  startDate: null,
  endDate: null,
  description: "",
  acceptNewIdeas: false
}

const initialState = {
  challenges: [],
  paging: {
    page: 1,
    sort: { createdAt: -1 },
    limit: 10,
    total: 0,
    statusCode: [],
    filter: [
      {
        field: 'name',
        value: '',
      },
      {
        field: 'statusChallenge.code',
        value: 0,
      },
    ],
  },
  challengeDetail: {},
  loading: false,
  loadingPhaseIdeas: false,
  loadingEdit: false,
  loadingPostWinners: false,
  progress: {
    challenge: 'active',
    imagem: 'disabled',
    criteria: 'disabled',
    dates: 'disabled',
    award: 'disabled'
  },
  newChallenge: {
    phases: [initialStatePhase],
    requirement: '',
    name: '',
    goal: '',
    description: '',
    startDate: null,
    dueDate: null,
    resultDate: null,
    thumbnail: {},
    banner: {},
    imagesToRemove: [],
    rewards: [
      {
        score: null,
        description: '',
      },
    ],
  },
  challengeTableIdeas: {
    ideas: [],
    statusCode:[{
        "label": "concluída",
        "value": "CONCLUDED",
        "checked": true
      }, {
        "label": "cancelado",
        "value": "CANCELED",
        "checked": true
      }, {
        "label": "aguardando qualificação",
        "value": "AWAITING_QUALIFICATION",
        "checked": true
      }, {
        "label": "Desqualificado",
        "value": "UNQUALIFIED",
        "checked": true
      }, {
        "label": "pendente de patente",
        "value": "PATENT_PENDING",
        "checked": true
      }, {
        "label": "fila de execução",
        "value": "EXECUTION_QUEUE",
        "checked": true
      }, {
        "label": "em execução",
        "value": "EXECUTING",
        "checked": true
      }, {
        "label": "qualificado",
        "value": "QUALIFIED",
        "checked": true
      }, {
        "label": "rascunho",
        "value": "DRAFT",
        "checked": true
      }
    ],
    paging: {
      total: 1,
      limit: 10,
      search: '',
      page: 1,
      sort: { timelineDate: -1 },
      statusCode: [],
    },
  },
  winners: [],
  winnerCard: [],
  errors: false,
};

export function challenge(state = initialState, action) {
  switch (action.type) {
    case challengeConstants.POST_CHALLENGE_REQUEST:
      return update(state, {
        loading: { $set: true },
      });
    case challengeConstants.POST_CHALLENGE_FAILURE:
      return update(state, {
        loading: { $set: false },
      });

    case challengeConstants.GET_CHALLENGES_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case challengeConstants.GET_CHALLENGES_SUCCESS: {
      const filter = [
        { field: 'name', value: action.payload.query || '' },
        { field: 'statusChallenge.code', value: action.payload.status },
      ];
      return update(state, {
        paging: {
          page: { $set: action.payload.page + 1 },
          filter: { $set: filter },
        },
        challenges: {
          $set: action.payload.challenges.data,
        },
        loading: { $set: false },
      });
    }

    case challengeConstants.GET_CHALLENGES_FAILURE:
      return update(state, {
        loading: { $set: false },
      });

    case challengeConstants.GET_MORE_CHALLENGES_SUCCESS: {
      const filter = [
        { field: 'name', value: action.payload.query || '' },
        { field: 'statusChallenge.code', value: action.payload.status },
      ];
      return update(state, {
        paging: {
          page: { $set: action.payload.page + 1 },
          filter: { $set: filter },
        },
        challenges: {
          $push: action.payload.challenges.data,
        },
        loading: { $set: false },
      });
    }

    case challengeConstants.GET_MORE_CHALLENGES_FAILURE:
      return update(state, {
        loading: { $set: false },
      });

    case challengeConstants.GET_CHALLENGE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      }

    case challengeConstants.GET_CHALLENGE_BY_ID_SUCCESS:
      return {
        ...state,
        challengeDetail: action.payload,
        loading: false
      }

    case challengeConstants.GET_CHALLENGE_BY_ID_FAILURE:
      return {
        ...state,
        loading: false
      }

    case challengeConstants.GET_IDEAS_CHALLENGE_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case challengeConstants.GET_IDEAS_CHALLENGE_SUCCESS:
      return update(state, {
        challengeTableIdeas: {
          ideas: { $set: action.payload.ideas.data },
          paging: {
            total: {
              $set: action.payload.ideas.paging.total,
            },
            limit: {
              $set: action.payload.ideas.paging.limit,
            },
            page: {
              $set: parseInt(action.payload.ideas.paging.page),
            },
            sort: {
              $set: action.payload.ideas.paging.sort,
            }
          },
        },
        loading: { $set: false },
      });

    case challengeConstants.GET_IDEAS_CHALLENGE_FAILURE:
      return update(state, {
        loading: { $set: false },
      });

    case challengeConstants.GET_CHALLENGE_EDIT_REQUEST:
      return update(state, {
        loadingEdit: { $set: true },
      });

    case challengeConstants.GET_CHALLENGE_EDIT_SUCCESS: {
      return update(state, {
        loadingEdit: { $set: false },
        newChallenge: {
          requirement: { $set: action.payload.requirement },
          name: { $set: action.payload.name },
          goal: { $set: action.payload.goal },
          description: { $set: action.payload.description },
          startDate: { $set: action.payload.startDate },
          dueDate: { $set: action.payload.dueDate },
          resultDate: { $set: action.payload.resultDate },
          phases: { $set: action.payload.phases },
          thumbnail: {
            name: {
              $set: action.payload.thumbnail?.fileName
                ? action.payload.thumbnail.fileName
                : '',
            },
            readableSize: {
              $set: action.payload.thumbnail?.size
                ? filesize(action.payload.thumbnail.size)
                : '',
            },
            preview: {
              $set: action.payload.thumbnail?.url
                ? action.payload.thumbnail.url
                : '',
            },
          },
          banner: {
            name: {
              $set: action.payload.banner?.fileName
                ? action.payload.banner.fileName
                : '',
            },
            readableSize: {
              $set: action.payload.banner?.size
                ? filesize(action.payload.banner.size)
                : '',
            },
            preview: {
              $set: action.payload.banner?.url
                ? action.payload.banner.url
                : '',
            },
          },
          rewards: { $set: action.payload.rewards },
          _id: { $set: action.payload._id },
        },
      });
    }

    case challengeConstants.GET_CHALLENGE_EDIT_FAILURE:
      return update(state, {
        loadingEdit: { $set: false },
      });

    case challengeConstants.SET_PROP_CHALLENGE: {
      return update(state, {
        newChallenge: {
          [action.payload.input]: { $set: action.payload.value },
        },
      });
    }

    case challengeConstants.SET_CHALLENGE: {
      const { name, description, goal } = state.newChallenge;
      const stepIsConcluded = !!name?.length && description?.length >= 100 && !!goal?.length;

      return update(state, {
        newChallenge: {
          name: { $set: action.payload.challenge.name },
          description: { $set: action.payload.challenge.description },
          goal: { $set: action.payload.challenge.goal },
        },
        progress: {
          challenge: { $set: stepIsConcluded ? 'concluded' : 'active' },
          imagem: {
            $set:
              state.progress.imagem !== 'concluded' ? 'active' : 'concluded',
          },
        },
      });
    }

    case challengeConstants.SET_CRITERIA: {
      const { criteria } = action.payload;

      return update(state, {
        newChallenge: {
          requirement: { $set: criteria },
        },
        progress: {
          criteria: { $set: !!criteria.length ? 'concluded' : 'disabled' },
          dates: {
            $set: !!criteria.length ? (state.progress.dates !== 'concluded'  ? 'active' : 'concluded') : 'disabled',
          },
        },
      });
    }

    case challengeConstants.SET_IMAGEM:
      return update(state, {
        newChallenge: {
          [action.payload.type]: { $set: action.payload.imagem },
        },
      });

    case challengeConstants.REMOVE_IMAGEM:
      return update(state, {
        newChallenge: {
          [action.payload.type]: { $set: {} },
        },
      });


    case challengeConstants.NEXT_CARD_IMAGE: {
      const { banner, thumbnail } = state.newChallenge;
      const stepIsConcluded = banner || thumbnail;

      return update(state, {
        progress: {
          imagem: { $set: stepIsConcluded ? 'concluded' : 'active' },
          criteria: {
            $set:
              state.progress.criteria !== 'concluded' ? 'active' : 'concluded',
          },
        },
      });
    }

    case challengeConstants.SET_DATES: {
      return update(state, {
        progress: {
          dates: { $set: 'concluded' },
          award: {
            $set: state.progress.award !== 'concluded' ? 'active' : 'concluded',
          },
        },
      });
    }

    case challengeConstants.SET_DATE: {
      return update(state, {
        newChallenge: {
          [action.payload.input]: { $set: action.payload.date },
        },
      });
    }

    case challengeConstants.SET_AWARD: {
      const rewards = action.payload.rewards.map((award, index) => ({
        index: index,
        description: award.description,
        score: parseInt(award.score),
      }));

      return update(state, {
        newChallenge: {
          rewards: { $set: rewards },
        },
        progress: {
          award: { $set: 'concluded' },
        },
      });
    }

    case challengeConstants.ADD_AWARD:
      return update(state, {
        newChallenge: {
          rewards: { $push: [{ score: null, description: '' }] },
        },
      });

    case challengeConstants.REMOVE_AWARD:
      const rewards = state.newChallenge.rewards.filter(
        (award) => parseInt(award.index) !== action.payload.index
      );

      return update(state, {
        newChallenge: {
          rewards: {
            $set: rewards,
          },
        },
      });

    case challengeConstants.POST_CHALLENGE_SUCCESS:
      return initialState

    case challengeConstants.CLEAR_FORM: {
      return initialState;
    }

    case challengeConstants.CLEAR_PROGRESS:
      return update(state, {
        progress: {
          challenge: { $set: 'active' },
          imagem: { $set: 'disabled' },
          criteria: { $set: 'disabled' },
          dates: { $set: 'disabled' },
          award: { $set: 'disabled' },
        },
      });

    case challengeConstants.SET_FILTER_COLABORATOR:
      const status = state.challengeTableIdeas.paging.statusCode.filter(
        (s) => s !== ('AWAITING_QUALIFICATION' || 'CANCELED' || 'DRAFT')
      );

      return update(state, {
        challengeTableIdeas: {
          paging: {
            statusCode: { $set: status },
          },
        },
      });

    case challengeConstants.SET_WINNERS: {
      const findWinner = state.winners.find(winner => winner.position === action.payload.position);

      if (findWinner) {
        return {
          ...state,
          winners: state.winners.map(winner => {
            if (winner.position === action.payload.position) {
              return {
                id: action.payload.idea?._id,
                position: action.payload?.position,
                idea: action.payload?.idea,
              }
            }
            return winner;
          })
        }
      }

      return {
        ...state,
        winners: [
          ...state.winners,
          {
            id: action.payload.idea?._id,
            position: action.payload?.position,
            idea: action.payload?.idea,
          }
        ]
      }
    }
    
    case challengeConstants.POST_WINNERS_REQUEST: {
      return {
        ...state,
        loadingPostWinners: true
      }
    }

    case challengeConstants.POST_WINNERS_SUCCESS: {
      return {
        ...state,
        loadingPostWinners: false
      }
    }

    case challengeConstants.POST_WINNERS_FAILURE: {
      return {
        ...state,
        loadingPostWinners: false
      }
    }

    case challengeConstants.CLEAR_SELECT_WINNER:
      const winnersFiltered = state.winners.filter(winner => winner.position !== action.index);
      return update(state, {
        winners: { $set: winnersFiltered }
      });

    case challengeConstants.CLEAR_ALL_WINNERS: {
      return {
        ...state,
        winners: []
      }
    }

    case challengeConstants.SET_WINNER_CARD:
      return update(state, {
        winnerCard: {
          [action.payload.position]: { $set: action.payload }
        }
      });

    case challengeConstants.CLEAR:
      return update(state, { $set: initialState });

    case challengeConstants.ADD_NEW_CHALLENGE_PHASE: {
      let newState = {
        ...state,
        newChallenge: {
          ...state.newChallenge,
          phases: [
            ...state.newChallenge.phases,
            initialStatePhase
          ]
        },
      };

      const validateProgress = state.newChallenge.phases.filter(phase => checkObjectPropertyEmpty(phase))
      if (validateProgress.length === 0 && newState.progress.dates === "concluded") {
        newState = {
          ...newState,
          progress: {
            ...newState.progress,
            dates: 'active',
          }
        }
      }

      return newState;
    }

    case challengeConstants.DELETE_NEW_CHALLENGE_PHASE: {
      let newState = {
        ...state,
        newChallenge: {
          ...state.newChallenge,
          phases: state.newChallenge.phases.filter((item, index) => index !== action.payload)
        }
      };

      const validateProgress = newState.newChallenge.phases.filter(phase => checkObjectPropertyEmpty(phase))
      const shouldValidateProgress = !!validateProgress[0]?.score.length && !!validateProgress[0]?.name.length && !!validateProgress[0]?.description.length && validateProgress[0]?.endDate !== null && validateProgress[0]?.startDate !== null;
      
      //se esta 0, nao existe objeto de fase com atributo vazio, ou seja, todos estao preenchidos.
      if (validateProgress.length === 0 && newState.progress.dates === "concluded") {
        newState = {
          ...newState,
          progress: {
            ...newState.progress,
            dates: 'active',
          }
        }
      } if(shouldValidateProgress) {
        newState = {
          ...newState,
          progress: {
            ...newState.progress,
            dates: 'concluded',
          }
        }
      }

      return newState;
    }

    case challengeConstants.CHANGE_NEW_CHALLENGE_FIELD_CRONOGRAMA: {
      let newState = {
        ...state,
        newChallenge: {
          ...state.newChallenge,
          phases: state.newChallenge.phases.map((item, index) => {
            if (index === parseInt(action.payload.index)) {
              return {
                ...item,
                [action.payload.name]: action.payload.value
              }
            }

            return item;
          }),
        }
      }

      const validateProgress = newState.newChallenge.phases.filter(phase => checkObjectPropertyEmpty(phase))

      //se esta 0, nao existe objeto de fase com atributo vazio, ou seja, todos estao preenchidos.
      if (validateProgress.length === 0) {
        newState = {
          ...newState,
          progress: {
            ...newState.progress,
            dates: 'concluded',
          }
        }
      } else if (validateProgress.length !== 0 && newState.progress.dates === "concluded") {
        newState = {
          ...newState,
          progress: {
            ...newState.progress,
            dates: 'active',
          }
        }
      }

      return newState;
    }

    case challengeConstants.CHANGE_CHALLENGE_FILTERS: {
      return {
        ...state,
        paging: {
          ...state.paging,
          filter: state.paging.filter.map((item, index) => {
            if (index === action.payload.index) {
              return {
                ...item,
                value: action.payload.value
              }
            }

            return item;
          })
        }
      }
    }

    case challengeConstants.GET_IDEAS_BY_PHASE_REQUEST: {
      return {
        ...state,
        loadingPhaseIdeas: true
      }
    }

    case challengeConstants.GET_IDEAS_BY_PHASE_SUCCESS: {
      return {
        ...state,
        loadingPhaseIdeas: false,
        challengeTableIdeas: {
          ideas: action.payload.ideas,
          paging: {
            ...state.challengeTableIdeas.paging,
            total: action.payload.paging.total,
            limit: action.payload.paging.limit,
            page: parseInt(action.payload.paging.page),
          },
        },
      }
    }

    case challengeConstants.GET_IDEAS_BY_PHASE_FAILURE: {
      return {
        ...state,
        loadingPhaseIdeas: false
      }
    }

    case challengeConstants.SET_IMAGE_TO_REMOVE:
      console.log(action.payload)
      return update(state, {
        newChallenge: {
          imagesToRemove: { $push: [action.payload] }
        }
      });

    case challengeConstants.CLEAR_CHALLENGE_FILTERS: {
      return {
        ...state,
        paging: {
          page: 1,
          sort: { createdAt: -1 },
          limit: 10,
          total: 0,
          
          filter: [
            {
              field: 'name',
              value: '',
            },
            {
              field: 'statusChallenge.code',
              value: 0,
            },
          ],
        }
      }
    }

    case challengeConstants.SET_CHALLENGE_FILTER: {
      return {
        ...state,
        challengeTableIdeas: {
          ...state.challengeTableIdeas,
          paging: {
            ...state.challengeTableIdeas.paging,
            [action.payload.name]: action.payload.value
          }
        }
      }
    }

    case challengeConstants.SET_CHALLENGE_STATUS_CODE: {
      return {
        ...state,
        challengeTableIdeas: {
          ...state.challengeTableIdeas,
          paging: {
            ...state.challengeTableIdeas.paging,
            statusCode: state.challengeTableIdeas.paging.statusCode.map((item) => {
              if (item.value === action.payload.value) {
                return {
                  ...item,
                  checked: !item.checked
                }
              }
    
              return item;
            })
          }
        }
      }
    }

     case challengeConstants.SET_ALL_CHALLENGE_STATUS_CODE: {
      return {
        ...state,
        statusCode: state.statusCode.map((item) => ({
          ...item,
          checked: true
        }))
      }
    }

    case challengeConstants.RESET_CHALLENGE_FILTERS: {
      return {
        ...state,
        challengeTableIdeas: {
          ...state.challengeTableIdeas,
          ideas: [],
          paging: {
            total: 1,
            limit: 10,
            search: '',
            page: 1,
            sort: { timelineDate: -1 },
            statusCode: state.challengeTableIdeas.paging.statusCode.map((item) => ({
              ...item,
              checked: true
            }))
          }          
        }
        
      }
    }

    case challengeConstants.UPDATE_CHALLENGE_REQUEST:
      return {
        ...state,
        loading: true,
      }

    case challengeConstants.UPDATE_CHALLENGE_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case challengeConstants.UPDATE_CHALLENGE_FAILURE:
      return {
        ...state,
        loading: false,
      }

    default:
      return state;
  }
}