import React, { useState } from 'react';
import {
  makeStyles,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Box,
  Avatar,
  Hidden,
  TextField,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { 
  ideaActions,
  ideaCreateActions, 
  ideaCreateDialogsActions, 
  multiAreaCascadeActions 
} from '../../actions';
import config from '../../config';
import { dialogCss } from './dialogCss';
import { ImagineLinkify } from '../TextLinkify';
import { DialogAttachment } from './DialogAttachment';
import { DialogCreateIdea } from './DialogCreateIdea';
import { DialogCustomTitle } from './DialogCustomTitle';
import { AvatarWithoutPhoto, LoadingButton, MentionsUsers } from '../Shared';
import clsx from 'clsx';
import { useWindowSize } from '../../hooks';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    justifyContent: 'space-between',
  },
  title: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
    fontSize: '1rem',
    fontWeight: 600,
  },
  content: {
    padding: theme.spacing(2),
  },
  dividerBottom: {
    borderBottom: 'none',
  },
  authorHeader: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  small: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    marginRight: theme.spacing(2),
  },
  btnMargin: {
    marginRight: 6,
    marginBottom: theme.spacing(1),
  },
  mr: {
    marginRight: theme.spacing(2),
  }
}));

function DialogSendIdea({ isEdit=false }) {
  const classes = useStyles();
  const dialogClasses = dialogCss();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { dialogSendIdea } = useSelector((state) => state.ideaCreateDialogs);
  const ideaCreate = useSelector((state) => state.ideaCreate);
  const { fields } = useSelector(state => state.multiAreaCascade);
  const [width] = useWindowSize();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { personalizedClassification } = useSelector((state) => state.personalizedClassification);
  const idea = useSelector((state) => state.ideaCreate);
  const [value, setValue] = useState('');
  const { paging } = useSelector((state) => state.idea);

  const { register, handleSubmit, errors, getValues } = useForm({
    mode: 'onBlur',
  });

 function setValues() {
    const values = getValues();
    const data = {
      name: values.name,
      description: values.description,
    };
    dispatch(ideaCreateActions.setTitleDescription(data));
  }

  function getIdeas() {
  setTimeout(() => {
    dispatch(
      ideaActions.getIdeasUser(
        1,
        paging.sort,
        paging.search,
        paging.limit,
        paging.statusCode
      )
    );
  }, 2000);
  };

  function handleMentions(mentioned) {
    dispatch(ideaCreateActions.setContributors(mentioned));
  }

  function handleCharacters(e) {
    setValue(e.target.value)
  }

  async function createIdea() {
    if (fields.map(field => field.selectedBusinessUnit).length === 0 || !ideaCreate.challenge) {
      return;
    }

    if (personalizedClassification.map(item => item.selectedClassification).length === 0 || !ideaCreate.personalizedClassifications) {
      return;
    }

    if (ideaCreate?.challenge?.notChallenge) {
      delete ideaCreate.challenge;
    }

    ideaCreate.businessUnitLocal = fields.map(field => field.selectedBusinessUnit);
    dispatch(ideaCreateActions.createIdea(ideaCreate, t('sua_ideia_foi_enviada')));
    dispatch(ideaCreateDialogsActions.close());
    dispatch(ideaCreateActions.clearAll())
  }

  function createDraft() {
    dispatch(ideaCreateActions.createDraft(ideaCreate, t('ideia_salva_em_rascunhos')));
    dispatch(ideaCreateActions.clearAll())
  }

  function close() {
    if (width < config.RESPONSIVE_MOBILE) {
      dispatch(ideaCreateDialogsActions.openDialogClose());
    } else {
      dispatch(ideaCreateActions.clearAll());
      dispatch(ideaCreateDialogsActions.close());
    }
    dispatch(multiAreaCascadeActions.clearAll());
    dispatch(ideaCreateActions.clearAll())
  }
  return (
    <DialogCreateIdea open={dialogSendIdea}>
      <DialogCustomTitle id="customized-dialog-title" onClose={close}>
        {t('publicar_ideia')}
      </DialogCustomTitle>
      <DialogContent dividers className={classes.dividerBottom}>
        <div>
          <div className={classes.authorHeader}>
            <div>
              {user.attachment?.url ? (
                <Avatar
                  alt={user.name}
                  src={user.attachment.url}
                  className={classes.small}
                />
              ) : (
                <AvatarWithoutPhoto
                  userName={user.name}
                  className={classes.mr}
                  large
                />
              )}
            </div>
            <div>
              <Typography variant="subtitle1" component="h1">
                <Box fontWeight="fontWeightBold" fontSize={14}>
                  {user.name}
                </Box>
              </Typography>
              <Button
                size="small"
                variant="outlined"
                onClick={() =>
                  dispatch(ideaCreateDialogsActions.openDialogMultiArea('sendIdea'))
                }
                endIcon={<ArrowDropDownIcon />}
                style={{ marginRight: 6 }}
                color={
                  fields.filter(field => field.selectedBusinessUnit).length > 0
                    ? 'default'
                    : 'secondary'
                }
              >
                {fields.filter(field => field.selectedBusinessUnit).length === 0 &&
                  t('a_area_e_obrigatorio')}{' '}
                {fields.filter(field => field.selectedBusinessUnit).length > 0 && (
                  <span> +{fields.filter(field => field.selectedBusinessUnit).length}</span>
                )}
              </Button>
              <Button
                size="small"
                variant="outlined"
                className={classes.btnMargin}
                onClick={() =>
                  dispatch(ideaCreateDialogsActions.openDialogChallenge('sendIdea'))
                }
                endIcon={<ArrowDropDownIcon />}
                color={ideaCreate?.challenge?.name ? 'default' : 'secondary'}
              >
                {ideaCreate?.challenge?.name || t('o_desafio_e_obrigatorio')}
              </Button>
                {ideaCreate?.personalizedClassifications?.length > 0 && (
              <Button
                size="small"
                variant="outlined"
                onClick={() =>
                  dispatch(ideaCreateDialogsActions.openDialogClassification('sendIdea'))
                }
                endIcon={<ArrowDropDownIcon />}
                color={ideaCreate?.personalizedClassifications?.length > 0 ? 'default' : 'secondary'}
              >
                  <span>+{ideaCreate?.personalizedClassifications?.length}</span>
              </Button>
                )}
            </div>
          </div>
        </div>
        {!isEdit ? (<Box className={classes.content}>
          <Typography
            className={classes.title}
            variant="subtitle2"
            color="primary"
            component="h1"
          >
            <Box>{ideaCreate.name}</Box>
          </Typography>
          <Typography
            gutterBottom
            color="textSecondary"
            component="div"
            style={{ whiteSpace: 'pre-line' }}
          >
            <ImagineLinkify>
              {ideaCreate.description}
            </ImagineLinkify>
          </Typography>
        </Box>) : (<div>
          <TextField
            onChange={handleCharacters}
            error={!!errors.name}
            label={t('titulo').toUpperCase()}
            style={{ margin: 8 }}
            placeholder={t('de_um_titulo_para_sua_ideia')}
            fullWidth
            margin="normal"
            InputProps={{ disableUnderline: true }}
            onBlur={setValues}
            name="name"
            inputProps={{ maxLength: 70 }}
            inputRef={register({
              required: t('o_titulo_da_ideia_e_obrigatorio'),
              maxLength: 70,
            })}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: '1.25rem', fontWeight: 600 },
            }}
            helperText={
              <div className={classes.message}>
                {(errors.name && errors.name.message)}
                {value ? (
                  <div className={classes.characters}>
                    <span>{value.length}/70 caracteres</span>
                  </div>
                ) : null}
              </div>
            }
            defaultValue={idea.name}
          />
          <TextField
            error={!!errors.description}
            label={t('descricao').toUpperCase()}
            style={{ margin: 8 }}
            placeholder={t('em_qual_ideia_voce_esta_pensando_descreva') + '...'}
            fullWidth
            margin="normal"
            InputProps={{ disableUnderline: true }}
            onBlur={setValues}
            multiline
            rows={8}
            name="description"
            inputRef={register({
              required: t('a_descricao_da_ideia_e_obrigatoria'),
              style:({fontSize: '0.75rem',})
            })}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: '1.25rem', fontWeight: 600 },
            }}
            helperText={
              <div className={classes.message}>
                {errors.description && errors.description.message}
              </div>
            }
            defaultValue={idea.description}
          />
          <MentionsUsers onAddMentions={handleMentions} />
        </div>)}
      </DialogContent>
      <DialogActions
        className={clsx(classes.dialogActions, dialogClasses.containerAction)}
      >
        <DialogAttachment from="DIALOG_SEND_IDEA" />
        <div>
          <Hidden smDown>
            <Button
              className={dialogClasses.button}
              variant="outlined"
              color="primary"
              style={{ marginRight: 8 }}
              onClick={() => {
                createDraft();
                getIdeas();
              }}
            >
              {t('salvar_rascunho')}
            </Button>
          </Hidden>
          {loading ? 
            <LoadingButton />
          : 
            <Button
              className={dialogClasses.button}
              variant="contained"
              color="primary"
              onClick={createIdea}
              disabled={ideaCreate.loading}
            >
              {t('publicar')}
            </Button>
          }
        </div>
      </DialogActions>
    </DialogCreateIdea>
  );
}

export { DialogSendIdea };
