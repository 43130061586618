import React, { useEffect } from 'react';
import { Container, makeStyles, Grid, Card } from '@material-ui/core';
import {
  BackNavAdmin,
  ChallengeCreateCard,
  ChallengeImageCard,
  ChallengeCriteriaCard,
  ChallengeProgressCard,
  ChallengeAwardCard,
  ChallengeDatesCard,
} from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { challengeActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(3),
  },
  card: {
    marginBottom: 20,
  },
  fixed: {
    position: 'sticky',
    boxSizing: 'border-box',
    top: '76px',
  },
}));

function ChallengeNewPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    newChallenge: {
      rewards,
      thumbnail,
      banner
    },
    progress,
    newChallenge,
    loading
  } = useSelector((state) => state.challenge);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(challengeActions.clearProgress());
  }, [])

  return (
    <div>
      <BackNavAdmin title={t('novo_desafio')}></BackNavAdmin>
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <div className={classes.card}>
              <ChallengeCreateCard />
            </div>

            <Card className={classes.card}>
               <ChallengeImageCard
                type="thumbnail"
                imagem={thumbnail}
              />
              <ChallengeImageCard
                type="banner"
                imagem={banner}
              />
            </Card>

            <div className={classes.card}>
              <ChallengeCriteriaCard />
            </div>

            <div className={classes.card}>
              <ChallengeDatesCard />
            </div>

            <div className={classes.card}>
              <ChallengeAwardCard
                rewards={rewards}
              ></ChallengeAwardCard>
            </div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.fixed}>
              <ChallengeProgressCard
                progress={progress}
                challenge={newChallenge}
                loading={loading}
              ></ChallengeProgressCard>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export { ChallengeNewPage };
