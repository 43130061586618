import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Button, Card, Grid, makeStyles, TextField, Typography, Avatar } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { challengeActions } from '../../actions';
import { ChallengeInfoAwardsCard } from './ChallengeInfoAwardsCard';
import { ReactComponent as MedalIcon } from '../../assets/imgs/worskpace-premium-icon.svg';

const useStyle = makeStyles(theme => ({
  card: {
    borderRadius: 8,
    border: `1px solid ${theme.palette.neutrals.high.medium}`,
    padding: theme.spacing(4),
  },
  avatarIcon: {
    backgroundColor: theme.palette.primary.light,
    fill: theme.palette.primary.main,
  },
  title: {
    color: theme.palette.neutrals.low.dark,
    marginLeft: 16
  },
  colocation: {
    display: 'flex',
    alignItems: 'center',
    width: 90
  },
}));

export function ChallengeAwardWinningIdeas({ ideas }) {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { 
    winners,
    challengeDetail,
    loadingPostWinners,
    challengeDetail: { rewards, phases }
  } = useSelector((state) => state.challenge);

  function handleSendWinners() {
    if (winners.length > 0) {
      dispatch(challengeActions.postWinners(winners))
    }
  };

  function handleSelectWinners(index, id) {
    if (index !== null && id !== null) {
      const idea = ideas.find(idea => idea._id === id);
      dispatch(challengeActions.setWinners({ position: index+1, idea }))
    };
  };

  function handleSelectDefaultValue(index) {
    if (rewards[index].win) {
      const { userName, name, _id } = rewards[index].win;
      return { userName, name, _id };
    }
    return null
  };

  function clearSelectWinner(index) {
    dispatch(challengeActions.clearSelectWinner(index+1));
  };

  const shouldAbleSendButton = () => {
    if (phases) {
      const today = new Date();
      const endDate = new Date(phases[phases.length - 1].endDate);
      
      today.setUTCHours(0);
      endDate.setUTCHours(0);

      //se hoje for maior que a data final, não pode mais enviar os vencedores
      if (today.getTime() >= endDate.getTime()) {
        return false
      }

      //se todos os vencedores foram selecionados, pode enviar
      if (rewards.filter(item => item.win).length === rewards.length) {
        return true;
      }

      return false
    }    

    return true
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={8}>
        <Card elevation={0} className={classes.card}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                <Avatar className={classes.avatarIcon}>
                  <MedalIcon />
                </Avatar>
                <Typography variant="h3" className={classes.title}>{t('ideias_premiadas')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {rewards && rewards.length > 0 && rewards.map((_award, index) => (
                <Box key={index} display="flex" alignItems="center" marginTop="32px">
                  <Typography variant="subtitle1" className={classes.colocation}>{index + 1}° {t('lugar')}</Typography>
                  <Autocomplete
                    fullWidth
                    id={`autoComplete${index}`}
                    defaultValue={handleSelectDefaultValue(index)}
                    options={ideas.map(({ user, name, _id }) => ({ userName: user.name, name, _id }))}
                    getOptionLabel={(option) => `${option.name} - ${option.userName}`}
                    onChange={(_e, newValue) => newValue !== null ? handleSelectWinners(index, newValue._id) : clearSelectWinner(index)}
                    renderInput={(params) => <TextField {...params} placeholder={t('selecione_a_ideia_premiada')} label={t('ideias').toLowerCase()} variant="filled" name={index} />}
                  />
                </Box>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  color="primary"
                  variant="contained" 
                  onClick={handleSendWinners} 
                  disabled={!shouldAbleSendButton() || loadingPostWinners }
                >
                  {t('salvar_selecao')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} lg={4}>
        <ChallengeInfoAwardsCard challenge={challengeDetail} />
      </Grid>
    </Grid>
  );
}