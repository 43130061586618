import React from 'react';
import { Container, makeStyles, Grid, Hidden } from '@material-ui/core';
import { UserEdit } from '../../components/UserEdit/UserEdit';
import { SidebarUserEdit, BackNavAdmin, CustomSelect } from '../../components';
import { optionConstants } from '../../constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: { 
    flexGrow: 1,
    marginTop: theme.spacing(4),
  },
}));

export function PerfilPage(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  function handleChangePage(value) {
    if (value === optionConstants.NOTIFICATIONS) {
      props.history.push(`/user/settings`);
    }
  }

  return (
    <>
      <BackNavAdmin title={t('meu_perfil')} />
      <Container maxWidth="lg" className={classes.root}>
        <Grid container spacing={3}>
          <Hidden smDown>
            <Grid item xs={3}>
              <SidebarUserEdit />
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={12}>
              <CustomSelect
                optionsAvaiable={[
                  optionConstants.PROFILE,
                  optionConstants.NOTIFICATIONS,
                ]}
                startOption={optionConstants.PROFILE}
                handlePage={handleChangePage}
              />
            </Grid>
          </Hidden>
          <Grid item xs={12} md={9}>
            <UserEdit />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}