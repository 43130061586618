export const faqConstants = {
  GET_ALL_QUESTIONS_REQUEST: 'GET_ALL_QUESTIONS_REQUEST',
  GET_ALL_QUESTIONS_SUCCESS: 'GET_ALL_QUESTIONS_SUCCESS',
  GET_ALL_QUESTIONS_FAILURE: 'GET_ALL_QUESTIONS_FAILURE',

  GET_QUESTION_BY_ID_REQUEST: 'GET_QUESTION_BY_ID_REQUEST',
  GET_QUESTION_BY_ID_SUCCESS: 'GET_QUESTION_BY_ID_SUCCESS',
  GET_QUESTION_BY_ID_FAILURE: 'GET_QUESTION_BY_ID_FAILURE',

  DELETE_QUESTION_SUCCESS: 'DELETE_QUESTION_SUCCESS',
  DELETE_QUESTION_FAILURE: 'DELETE_QUESTION_FAILURE',

  SET_PAGE: 'SET_PAGE',
  SET_LIMIT: 'SET_LIMIT',
};
