import { makeStyles } from '@material-ui/core';

export const tableCss = makeStyles((theme) => ({
  oneLine: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  paginationInput: (props) => ({
    marginRight: 'auto',
    '&&:after': {
      content: `"${props.text}"`,
      position: 'absolute',
      width: theme.spacing(30),
      display: 'inline-flex',
      left: theme.spacing(8),
      pointerEvents: 'none',
      color: theme.palette.neutrals.low.medium,
    },
  }),
  paginationSpacer: {
    flex: 'none',
  },
  paginationCaption: {
    color: theme.palette.neutrals.low.medium,
  },
  paginationToolbar: {
    padding: theme.spacing(0),
  },
  table: {
    borderSpacing: theme.spacing(0, 1),
  },
  paperRoot: {
    width: '100%',
    marginBottom: theme.spacing(6),
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  tableCellHeader: {
    border: 'none',
    color: theme.palette.neutrals.low.dark,
    fontWeight: '600',
    paddingBottom: theme.spacing(1),
  },
  tableRow: {
    backgroundColor: theme.palette.neutrals.high.main,
  },
  tableCellRadiusLeft: {
    whiteSpace: 'nowrap',
    borderTop: '1px solid #E7EAED',
    borderLeft: '1px solid #E7EAED',
    borderTopLeftRadius: theme.spacing(0.5),
    borderBottomLeftRadius: theme.spacing(0.5),
  },
  tableCellRadiusRight: {
    whiteSpace: 'nowrap',
    borderTop: '1px solid #E7EAED',
    borderRight: '1px solid #E7EAED',
    borderTopRightRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
  },
  tableCell: {
    borderTop: '1px solid #E7EAED',
    borderBottom: '1px solid #E7EAED',
    whiteSpace: 'nowrap',
  },
  link: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    }
  }
}));
