import React, { useState } from 'react';

import {
	FormControl,
	makeStyles,
	Typography,
	Box,
	Divider,
	Menu,
	ButtonBase,
	RadioGroup,
	FormControlLabel,
	Radio
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SortIcon from '@material-ui/icons/Sort';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { challengeActions, curationActions } from '../../actions';

const useStyles = makeStyles((theme) => ({
	filterButton: {
		height: 51,
		borderRadius: 4,
		color: theme.palette.neutrals.low.dark,
		backgroundColor: theme.palette.neutrals.high.light,
		transition: 'all 0.5s ease-in-out', 
		'&:hover': {
			backgroundColor: theme.palette.neutrals.high.dark
		},
		marginLeft: 20
	},

	titleDropBox: { 
		padding: 24, 
		color: theme.palette.neutrals.low.light 
	}

}));

function ClassificationFilter() {
	const classes = useStyles();
	const [width] = useWindowSize();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState(null);
  const { filters, paging, curationTab } = useSelector(state => state.curation);
  const { occupationArea } = useSelector(state => state.businessUnitProps);

	const handleChange = ({target: {name, value}}) => {
		dispatch(curationActions.changeClassification(name, value));
		if (curationTab === "listagem") dispatch(curationActions.loadIdeasAdmin(filters, paging, occupationArea));
		else dispatch(curationActions.loadIdeas(filters, paging, occupationArea))
    	handleClose()
	};

	const handleOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (<>
		<ButtonBase
			onClick={handleOpen}
			className={classes.filterButton}
		>
			<Box width="100%" display='flex' justifyContent="space-evenly" alignItems="center">
				{width > config.RESPONSIVE_MOBILE ? (
					<>
						<SortIcon />
						<Typography variant="subtitle2" style={{ padding: '0 13px'}}>{t('classificacao')}</Typography>
					</>

				) : (
					<SortIcon />
				)}
				<ArrowDropDownIcon />
			</Box>
		</ButtonBase>

		<Menu
			elevation={2}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={handleClose}
		>
			<Typography variant="body2" className={classes.titleDropBox}>{t('classificar_por')}</Typography>
			<Divider />
			<Box style={{ padding: 24, display: 'flex', flexDirection: 'column' }}>
				<FormControl>
					<RadioGroup
						aria-labelledby="demo-controlled-radio-buttons-group"
						name="controlled-radio-buttons-group"
						onChange={handleChange}
					>
						<FormControlLabel name="dateSort" value="ASC" checked={filters.dateSort === "ASC"} control={<Radio />} label={t('data_em_ordem_crescente')} />
						<FormControlLabel name="dateSort" value="DESC" checked={filters.dateSort === "DESC"} control={<Radio />} label={t('data_em_ordem_decrescente')} />
						<FormControlLabel name="occupationAreaSort" value="A-Z" checked={filters.occupationAreaSort === "A-Z"} control={<Radio />} label={t('unidade_de_negocio_a_z')} />
					</RadioGroup>
				</FormControl>
			</Box>
		</Menu>
	</>);
}

export { ClassificationFilter };
