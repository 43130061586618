import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CardProfile } from '../CardProfile/CardProfile';
import { helpCenterService } from '../../services/help-center.service'
import { Box } from '@material-ui/core';
import { SkeletonAdministrators } from '../Skeletons';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-around',
    overflowY: 'scroll',
  },
})

function Administrators() {
  const classes = useStyles();
  const [administrators, setAdministrators] = useState([])

  useEffect(() => {
    helpCenterService.getAdministrators()
      .then(response => {
        setAdministrators(response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }, [administrators])

  return (
    <Box className={classes.container}>
      {!!administrators.length ? administrators.map((item, index) => (
        <CardProfile
          key={index}
          name={item.name}
          email={item.email}
          phone={item.phone}
          pictureUrl={item.pictureUrl}
          position={item.position}
        />
      )) : (
        <SkeletonAdministrators />
      )}
    </Box>
  );
}

export { Administrators };