import React from 'react'
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import makeStyles from "@material-ui/styles/makeStyles"

const useStyles = makeStyles(theme => ({
  wrapper: {
    borderRadius: 8,
    border: ({ border }) => border ? border : "1px solid #E7EAED",
    padding: 32,
    backgroundColor: "#fff"
  }
}))

export const ChallengeStepWrapper = ({ children, border }) => {
  const classes = useStyles({ border });

  return (
    <Box className={classes.wrapper}>
      <Grid container spacing={6}>
        { children }
      </Grid>
    </Box>
  )
}
