import React, { useEffect, useState } from 'react';

import {
	Checkbox,
	FormControl,
	makeStyles,
	Button,
	Typography,
	Box,
	Divider,
	TextField,
	Menu,
	ButtonBase
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import config from '../../config';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { challengeActions } from '../../actions';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useParams } from 'react-router-dom';

const filterOptions = createFilterOptions({
  limit: 100,
})

const useStyles = makeStyles((theme) => ({
	filterButton: {
		height: 51,
		borderRadius: 4,
		color: theme.palette.neutrals.low.dark,
		backgroundColor: theme.palette.neutrals.high.light,
		transition: 'all 0.5s ease-in-out', 
		'&:hover': {
			backgroundColor: theme.palette.neutrals.high.dark
		},
	},

	count: {
		marginRight: 3,
		padding: '3px 6px',
		backgroundColor: theme.palette.neutrals.low.lightest,
		fontSize: 11,
		color: theme.palette.neutrals.low.dark,
		borderRadius: 4,
	},

	titleDropBox: { 
		padding: 24, 
		color: theme.palette.neutrals.low.light
	}
}));

function ChallengeTableFilter() {
  const classes = useStyles();
  const [width] = useWindowSize();
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { 
    challengeTableIdeas: { paging },
   } = useSelector(state => state.challenge);
  const [anchorEl, setAnchorEl] = useState(null);

  function handleSendFilters() {

    dispatch(
      challengeActions.getIdeasChallenge(
        id,
        1,
        paging.sort,
        paging.limit,
        paging.statusCode.filter(item => item.checked).map(item => item.value),
        paging.search
      )
    );
    handleClose()
  }

  function handleChange(details) {
    const { option } = details;
    if (option.value === 'SELECT_ALL') {
      dispatch(challengeActions.selectAllStatus());
    } else {
      dispatch(challengeActions.selectStatus(option.value))
    }
  }

  function handleOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (<>
    <ButtonBase
      onClick={handleOpen}
      className={classes.filterButton}
    >
      <Box width="100%" display='flex' justifyContent="space-evenly" alignItems="center">
        {width > config.RESPONSIVE_MOBILE ? (
          <>
						<FilterListIcon />
						<Typography variant="subtitle2" style={{ padding: '0 13px' }}>{t('filtro')}</Typography>
          </>

				) : (
					<FilterListIcon />
        )}
        <ArrowDropDown width="25%" />
      </Box>
    </ButtonBase>

    <Menu
      elevation={2}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
			<Typography variant="body2" className={classes.titleDropBox}>{t('filtrar_por')}</Typography>
      <Divider />
      <Box style={{ padding: 24, display: 'flex', flexDirection: 'column' }}>
        <FormControl style={{ marginBottom: 30, width: 280 }}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            loadingText={t('carregando...')}
            noOptionsText={t('sem_opcoes')}
            id="checkboxes-status"
            options={paging.statusCode}
            loading={paging.statusCode.length === 0}
            getOptionLabel={(input) => input.text}
            value={paging.statusCode.filter(item => item.checked === true)}
            onChange={(e, items, options, details) => handleChange(details)}
            renderInput={(params) => <TextField {...params} label={t('status')} variant="filled" />}
            renderTags={(option) => {
              return (<span>{option.length} {option.length > 1 ? "itens" : "item"} {option.length > 1 ? "selecionados" : "selecionado"}</span>)
            }}
            renderOption={(value) => (
              <span className={classes.flex}>
                <Checkbox
                  color="primary"
                  checked={value.checked === true}
                />
                {value.text}
              </span>
            )}
          />
        </FormControl>
      </Box>

      <Box display='flex' justifyContent='end' style={{ padding: '0 24px 24px 0' }}>
        <Button
          color="primary"
          onClick={handleSendFilters}
					variant="contained"
					startIcon={<FilterListIcon />}
				>
					{t('filtrar')}
        </Button>
      </Box>
    </Menu>
  </>);
}

export { ChallengeTableFilter };
