import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Grid, Avatar, HeaderTitle } from './styles';
import { Search, MenuInformation } from '../../Shared';
import { infraStructureActions } from '../../../actions';
import { InfrastructureClassificationFilter } from '../';

export function InfrastructureHeader({ icon, title, info, children, tabChosed }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { paging, filters } = useSelector((state) => state.infraStructure);

  function sendQuery(query) {
    if (tabChosed === 0) {
      dispatch(infraStructureActions.searchByName({ name: query }));
    } else {
      dispatch(infraStructureActions.getAllAssociations(1, 10, filters.dateSort, query))
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Avatar>{icon}</Avatar>
          <HeaderTitle variant="h3">{title}</HeaderTitle>
          <MenuInformation>{info}</MenuInformation>
        </Box>
        {children}
      </Grid>
      <Grid item xs={12}>
        <InfrastructureClassificationFilter tabChosed={tabChosed} />
        <Search
          placeholder={t('pesquisar_infraestrutura')}
          value={paging.query}
          query={sendQuery}
        />
      </Grid>
    </>
  );
}