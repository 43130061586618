import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';
import { Divider } from '@material-ui/core';

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  rect: {
    borderRadius: 4
  }
}))(MuiSkeleton);

export function SkeletonEvaluations() {
  const arrItems = Array.from(Array(5));
  return (
    <Grid container spacing={3}>
      {arrItems.map((item, index) => (
        <Fragment key={index}>
          <Grid item>
            <Skeleton variant="circle" width={25} height={25} />
          </Grid>
          <Grid item xs={4}>
            <Skeleton variant="rect" width="100%" height={25} />
          </Grid>
          <Grid item xs={7}>
            <Skeleton variant="rect" width="100%" height={25} />
          </Grid>
          {index !== (arrItems.length - 1) && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
        </Fragment>
      ))}
    </Grid>
  );
}