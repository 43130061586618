import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { infraCss } from './infraCss';
import { truncate } from '../../helpers';
import { tableCss } from '../Tables/TableCss';
import { TableMenu } from '../Tables/TableMenu';
import { DialogAddInfra } from './DialogAddInfra';
import { DialogDeleteInfra } from './DialogDeleteInfra';
import { DialogInfraDetails } from './DialogInfraDetails';
import { DialogAssociationInfra } from './DialogAssociationInfra';
import { infraStructureActions } from '../../actions/infraStructure.actions';
import { TagInfrastructure } from './TagInfrastructure';
import { withStyles } from '@material-ui/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { InsertLink } from '@material-ui/icons';

const TCell = withStyles(() => ({
  root: {
    padding: '0 0 0 16px',
  }
}))(TableCell);

export function InfrastructureRow({ row }) {
  const classes = infraCss();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableClasses = tableCss();
  const [openDialogEditInfra, setOpenDialogEditInfra] = useState(false);
  const [openDialogDeleteInfra, setOpenDialogDeleteInfra] = useState(false);
  const [openDialogDetailsInfra, setOpenDialogDetailsInfra] = useState(false);
  const [openDialogAssociationInfra, setOpenDialogAssociationInfra] = useState(false);

  function handleOpenDialogEditInfra() {
    if (!openDialogEditInfra) {
      dispatch(infraStructureActions.loadInfraStructureDialog(row));
    }
    setOpenDialogEditInfra(!openDialogEditInfra);
  }

  function handleOpenDialogDeleteInfra() {
    setOpenDialogDeleteInfra(!openDialogDeleteInfra);
  }

  function handleOpenDialogDetailsInfra() {
    if (!openDialogDetailsInfra) {
      dispatch(infraStructureActions.getAssociation(row._id));
    }

    setOpenDialogDetailsInfra(!openDialogDetailsInfra);
  }


  function handleOpenDialogAssociationInfra() {
    if (!openDialogAssociationInfra && row.type === 'businessUnit') {
      dispatch(infraStructureActions.clearIdsToRemove());
      dispatch(infraStructureActions.getAssociation(row._id));
    }
    setOpenDialogAssociationInfra(!openDialogAssociationInfra);
  }

  return (
    <>
      <DialogInfraDetails
        open={openDialogDetailsInfra}
        onClose={handleOpenDialogDetailsInfra}
        row={row}
      />
      <DialogAddInfra
        open={openDialogEditInfra}
        onClose={handleOpenDialogEditInfra}
        id={row._id}
        isEdit={true}
      />
      <DialogDeleteInfra
        open={openDialogDeleteInfra}
        onClose={handleOpenDialogDeleteInfra}
        row={row}
      />
      <DialogAssociationInfra
        open={openDialogAssociationInfra}
        onClose={handleOpenDialogAssociationInfra}
        row={row}
      />
      <TableRow classes={{ root: tableClasses.tableRow }}>
        <TCell classes={{ root: tableClasses.tableCellRadiusLeft }} style={{ width: 200 }}>
          {moment(row.createdAt).format('DD/MM/YYYY')}
        </TCell>
        <TCell classes={{ root: tableClasses.tableCell }}>
          {truncate(row.name, 30)}
        </TCell>
        <TCell classes={{ root: tableClasses.tableCell }}>
          <TagInfrastructure type={row.type} />
        </TCell>
        <TCell classes={{ root: tableClasses.tableCell }}>
          {row.type === 'businessUnit' && (
            <IconButton onClick={handleOpenDialogAssociationInfra}>
              <Tooltip title={t('vincular')} aria-label="vincular">
                <InsertLink />
              </Tooltip>
            </IconButton>
          )}
        </TCell>
        <TCell classes={{ root: tableClasses.tableCellRadiusRight }}>
          <TableMenu>
            <MenuList style={{ padding: 0 }}>
              <MenuItem onClick={handleOpenDialogDetailsInfra}>
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
                <ListItemText primary={
                  <Typography className={classes.menuText}>{t('visualizar')}</Typography>
                } />
              </MenuItem>
              <MenuItem onClick={handleOpenDialogEditInfra}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary={
                  <Typography className={classes.menuText}>{t('editar')}</Typography>
                } />
              </MenuItem>
              <MenuItem onClick={handleOpenDialogDeleteInfra}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary={
                  <Typography className={classes.menuText}>{t('excluir')}</Typography>
                } />
              </MenuItem>
            </MenuList>
          </TableMenu>
        </TCell>
      </TableRow>
    </>
  );
}
