import React, { Fragment, useState } from 'react'
import ButtonBase from '@material-ui/core/ButtonBase'
import FilterListIcon from '@material-ui/icons/FilterList';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from "@material-ui/core" 
import { useDispatch, useSelector } from "react-redux"
import { rankingActions } from "../../../actions"

const useStyles = makeStyles((theme) => ({
  filterButton: {
    backgroundColor: theme.palette.grey.dark,
    borderRadius: "4px",
    fontSize: "15px",
    padding: "1rem",
    color: "#343A40"
  },
  filterButtonIcon: {
    color: "#343A40",
    marginRight: "0.5rem",
    fontSize: "20px"
  },
  filterButtonArrow: {
    fontSize: "1rem",
    color: "#343A40",
    marginLeft: "0.5rem"
  },
  filterButtonBadge: {
    padding: "4px 8px",
    fontSize: "10px",
    color: "#343A40",
    borderRadius: "4px",
    backgroundColor: "#CED4DA",
    marginLeft: "0.5rem"
  },
  filterMenu: {
    width: "328px",
    backgroundColor: "#fff",
    padding: "1rem",
  },
  filterMenuHeader: {
    backgroundColor: "#fff",
    padding: "1rem",
    width: "328px",
    borderBottom: `2px solid ${theme.palette.grey.dark}`,
  },
  filterMenuHeaderTitle: {
    color: theme.palette.grey.darkest,
    fontSize: "14px",
    fontWeight: "400",
  },
  select: {
    width: "100%"
  },
 
}))

export const ChallengeFilters = ({ handleChange, handleFilter }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const { paging, loading } = useSelector((state) => state.challenge);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleChangeStatus(event) {
    handleChange(event.target.value)
  }

  return (
    <Fragment>
      <ButtonBase 
          className={classes.filterButton}
          onClick={handleClick}
        >
          <FilterListIcon className={classes.filterButtonIcon} />
          filtro
          <ArrowDropDownIcon className={classes.filterButtonArrow} />
        </ButtonBase>
        <Menu
          elevation={2}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <Box display="flex" flexDirection="column">
            <Box className={classes.filterMenuHeader}>
              <Typography component="p" className={classes.filterMenuHeaderTitle}>filtrar por</Typography>
            </Box>
            <Box className={classes.filterMenu}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <FormControl variant="filled" className={classes.select}>
                    <InputLabel id="select-status-label" className={classes.label}>{t('visualizar')}</InputLabel>
                    <Select
                      labelId="select-status-label"
                      id="select-status"
                      fullWidth
                      onChange={handleChangeStatus}
                      disableUnderline
                      value={paging.filter[1].value}
                    >
                      <MenuItem value={0}>{t('todos')}</MenuItem>
                      <MenuItem value={3}>{t('programado')}</MenuItem>
                      <MenuItem value={4}>{t('em_andamento')}</MenuItem>
                      <MenuItem value={5}>{t('encerrados')}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={4}>
                    <Grid item xs={6}>
                      <Button 
                        variant="contained" 
                        color="primary"
                        startIcon={<FilterListIcon />}
                        onClick={handleFilter}
                        disabled={loading}
                      >
                        filtrar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Menu>
    </Fragment>
  )
}
