import React from 'react'
import Box from "@material-ui/core/Box"
import makeStyles from "@material-ui/styles/makeStyles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  iconDiscription: {
    backgroundColor: theme.palette.primary.light,
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  description: {
    fontWeight: 600,
    fontSize: 20,
  }
}))

export const ChallengeStepHeaderIcon = ({ icon, text }) => {
  const classes = useStyles();
  
  return (
    <Box display='flex' alignItems='center'>
      <Box className={classes.iconDiscription}>
        { icon }
      </Box>
      <Typography className={classes.description}>{ text }</Typography>
    </Box>
  )
}
