import React, { useEffect } from 'react';
import { Avatar, Box, Grid, makeStyles, Typography } from "@material-ui/core";
import { Beenhere } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from 'react';
import { portfolioDetailsActions } from '../../../actions/portfolio/portfolio-details.actions';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  item: {
    marginTop: 16
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light
  },
  iconAuthor: {
    marginLeft: 8,
    fontSize: 15,
    color: theme.palette.auxiliary.yellow.main
  }
}));

export default function TeamMember({ member }) {
  const classes = useStyles();
  const { project: { author }, tasks } = useSelector(state => state.portfolioDetails);
  const { projectTasksStatus } = useSelector(state => state.domains);
  const { id } = useParams();
  const dispatch = useDispatch();
  const owner = member.user._id === author

  useEffect(() => {
      dispatch(portfolioDetailsActions.getAllTask({
        page: 1,
        limit: 10,
        projectId: id
      }, projectTasksStatus))
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch, projectTasksStatus])

  const totalMemberTasks = useMemo(() => [...tasks.items['NOT_STARTED'], ...tasks.items['STARTED'], ...tasks.items['CONCLUDED']].filter(({ responsible }) => responsible._id === member.user._id).length, [tasks, member]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={7}>
        <Box display="flex" alignItems="center">
          <Avatar
            alt={member.user.name}
            title={member.user.name}
            src={member.user.attachment?.url}
            className={classes.avatar}
          />
          <Typography variant="body2">{member.user.name}</Typography>
          {owner && <Beenhere className={classes.iconAuthor} />}
        </Box>
      </Grid>
      <Grid item xs={5}>
        <Typography variant="body2">{`${member.performedTasks}/${totalMemberTasks} tarefas feitas`}</Typography>
      </Grid>
    </Grid>
  )
};