import React from 'react';
import { makeStyles } from '@material-ui/core';
import { dateFormat } from '../../helpers';
import { useSelector } from 'react-redux';
import formatUTCHours from '../../helpers/format-utc-hours';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.neutrals.low.medium,
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
    marginTop: 20,
  },
  containerSchedule: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerNumber: {
    width: 60,
    height: 60,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    fontSize: 25,
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  scheduleTitle: {
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  scheduleDate: {
    fontWeight: 600,
    color: theme.palette.neutrals.low.medium,
  },
  between: {
    width: '16%',
    marginTop: 24,
    marginRight: -12,
    marginLeft: -12,
  },
}));

function ChallengeSchedule({ challenge }) {
  const classes = useStyles();
  const { startDate, dueDate, resultDate } = challenge;
  const language = useSelector((state) => state.language);


  return (
    <div>
      <div className={classes.title}>CRONOGRAMA</div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <div className={classes.containerSchedule}>
          <div className={classes.containerNumber}>1º</div>
          <div className={classes.scheduleTitle}>inscrições</div>
          <div className={classes.scheduleDate}>
            {dateFormat(formatUTCHours(startDate), language)}
          </div>
        </div>
        <div className={classes.between}>
          <hr style={{ border: '1px solid theme.palette.primary.main' }} />
        </div>

        <div className={classes.containerSchedule}>
          <div className={classes.containerNumber}>2º</div>
          <div className={classes.scheduleTitle}>seleção</div>
          <div className={classes.scheduleDate}>
            {dateFormat(formatUTCHours(dueDate), language)}
          </div>
        </div>
        <div className={classes.between}>
          <hr style={{ border: '1px solid theme.palette.primary.main' }} />
        </div>
        <div className={classes.containerSchedule}>
          <div className={classes.containerNumber}>3º</div>
          <div className={classes.scheduleTitle}>resultado</div>
          <div className={classes.scheduleDate}>
            {dateFormat(formatUTCHours(resultDate), language)}
          </div>
        </div>
      </div>
    </div>
  );
}

export { ChallengeSchedule };
