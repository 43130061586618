import React, { useEffect, useState } from 'react';
import {
  Card,
  CardActions,
  Button,
  Grid,
  Collapse,
  CardContent,
  makeStyles,
  useTheme,
  Typography,
  TextField,
  ButtonBase,
  IconButton,
  Box,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { challengeActions } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { selectedLanguageDateInputFormat } from '../../helpers/input-date-format';
import { useTranslation } from 'react-i18next';
import formatUTCHours from '../../helpers/format-utc-hours';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ChallengeStepWrapper } from "./ChallengeStepCard/ChallengeStepWrapper"
import { ChallengeStepHeader } from "./ChallengeStepCard/ChallengeStepHeader"
import { ChallengeStepHeaderIcon } from "./ChallengeStepCard/ChallengeStepHeaderIcon"
import { ChallengeStepContent } from "./ChallengeStepCard/ChallengeStepContent"

const useStyles = makeStyles((theme) => ({
  description: {
    fontWeight: 600,
    fontSize: 20,
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
  },
  content: {
    padding: theme.spacing(4),
  },
  iconDiscription: {
    backgroundColor: theme.palette.primary.light,
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  phaseNumber: {
    color: "#343A40",
    fontSize: 14,
    fontWeight: "600"
  },
  error: {
    color: theme.palette.auxiliary.red.main,
    marginTop: 3,
    lineHeight: 1.66,
  },
}));

function ChallengeDatesCard({ phases = [] }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language);
  const { newChallenge } = useSelector(state => state.challenge)
  const { register, errors, control } = useForm({ mode: 'onBlur' });

  function handleChangePhase(e) {
    const { name, value } = e.target
    const index = name.split("-")[1];
    dispatch(challengeActions.changeFieldCronograma(name.split("-")[0], value, index))
  }

  function handleChangeAcceptNewIdeas(e) {
    const { name, checked } = e.target;
    const index = name.split("-")[1];
    dispatch(challengeActions.changeFieldCronograma(name.split("-")[0], checked, index));
  }

  function handleChangeDate(date, field, index) {
    if (date instanceof Date && isFinite(date)) {
      console.log(index);
      dispatch(challengeActions.changeFieldCronograma(field, date, index));
    }
  }

  function handleAddNewPhase() {
    dispatch(challengeActions.addNewPhase())
  }

  function handleDeletePhase(index) {
    return () => {
      dispatch(challengeActions.deletePhase(index));
      dispatch(challengeActions.changeFieldCronograma('name', newChallenge.phases[0].name, 0));
    }
  }

  return (
    <ChallengeStepWrapper>
      <ChallengeStepHeader>
        <ChallengeStepHeaderIcon icon={<AssignmentIcon color="primary" />} text={t('cronograma')} />
      </ChallengeStepHeader>
      <ChallengeStepContent>
        <Grid container spacing={2}>
          {newChallenge?.phases?.map((item, index) => (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={index === 0 ? 12 : 6}>
                  <Box height={"100%"} display='flex' alignItems='flex-end'>
                    <Typography component={"p"} className={classes.phaseNumber}>{index + 1}ª fase</Typography>
                  </Box>
                </Grid>
                {index !== 0 && (
                  <Grid item xs={6}>
                    <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end">
                      <IconButton onClick={handleDeletePhase(index)}>
                        <DeleteForeverIcon color="red" />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
                <Grid item xs={8}>
                  <TextField
                    name={`name-${index}`}
                    id={`name-${index}`}
                    label="nome da fase"
                    type="text"
                    variant="filled"
                    fullWidth
                    className={classes.box}
                    onBlur={handleChangePhase}
                    defaultValue={item.name}
                    inputRef={register({
                      required: t('nome_da_fase_e_obrigatorio')
                    })}
                    error={!!errors[`name-${index}`]}
                    helperText={errors[`name-${index}`] && errors[`name-${index}`].message}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name={`score-${index}`}
                    id={`score-${index}`}
                    label="pontos"
                    type="number"
                    variant="filled"
                    fullWidth
                    placeholder="0"
                    className={classes.box}
                    onBlur={handleChangePhase}
                    defaultValue={item.score}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    as={<KeyboardDatePicker />}
                    variant="inline"
                    inputVariant="filled"
                    format={selectedLanguageDateInputFormat(language)}
                    fullWidth
                    margin="normal"
                    name={`startDate-${index}`}
                    id={`startDate-${index}`}
                    label={t('inicio')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    inputRef={register}
                    rules={{ required: true }}
                    invalidDateMessage={
                      <span className={classes.errorSpan}>
                        {t('data_de_inicio_invalida')}
                      </span>
                    }
                    error={!!errors[`startDate-${index}`]}
                    control={control}
                    defaultValue={phases.length > 0 ? new Date(item.startDate) : null}
                    onAccept={(data) => handleChangeDate(data, 'startDate', index)}
                    autoOk
                    maxDateMessage={
                      <span className={classes.errorSpan}>
                        A data não pode ser futura ou maior que a data de termino
                      </span>
                    }
                  />

                  {/* <KeyboardDatePicker
                    variant="inline"
                    inputVariant="filled"
                    required={true}
                    format={selectedLanguageDateInputFormat(language)}
                    fullWidth
                    name={`startDate-${index}`}
                    id={`startDate-${index}`}
                    label={t('inicio')}
                    autoOk
                    rules={{ required: true }}
                    value={item.startDate}
                    onChange={(date) => handleChangeDate(date, 'startDate', index)}
                    defaultValue={phases.length > 0 ? new Date(item.startDate) : null}
                    maxDate={item.endDate}
                    maxDateMessage={
                      <span className={classes.errorSpan}>
                        A data não pode ser futura ou maior que a data de termino
                      </span>
                    }
                    invalidDateMessage={
                      <span className={classes.errorSpan}>
                        Data de inicio inválida
                      </span>
                    }
                  /> */}
            <p className={classes.error}>
                    {errors[`startDate-${index}`] &&
                      errors[`startDate-${index}`].type === 'required' &&
                      t('a_data_de_inicio_e_obrigatoria')}
                  </p>
                </Grid>
                <Grid item xs={6}>

                  <Controller
                    as={<KeyboardDatePicker />}
                    variant="inline"
                    inputVariant="filled"
                    format={selectedLanguageDateInputFormat(language)}
                    fullWidth
                    margin="normal"
                    label={t('termino')}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    inputRef={register}
                    rules={{ required: true }}
                    invalidDateMessage={
                      <span className={classes.errorSpan}>
                        {t('data_de_termino_invalida')}
                      </span>
                    }
                    error={!!errors[`endDate-${index}`]}
                    control={control}
                    name={`endDate-${index}`}
                    id={`endDate-${index}`}
                    defaultValue={phases.length > 0 ? new Date(item.endDate) : null}
                    autoOk
                    onAccept={(data) => handleChangeDate(data, 'endDate', index)}
                  />

                  {/* <KeyboardDatePicker
                    variant="inline"
                    inputVariant="filled"
                    required={true}
                    format={selectedLanguageDateInputFormat(language)}
                    fullWidth
                    name={`endDate-${index}`}
                    id={`endDate-${index}`}
                    label={t('termino')}
                    autoOk
                    value={item.endDate}
                    onChange={(date) => handleChangeDate(date, 'endDate', index)}
                    defaultValue={phases.length > 0 ? new Date(item.endDate) : null}
                    invalidDateMessage={
                      <span className={classes.errorSpan}>
                        Data de inicio inválida
                      </span>
                    }
                  /> */}
                  <p className={classes.error}>
                    {errors[`endDate-${index}`] &&
                      errors[`endDate-${index}`].type === 'required' &&
                      t('a_data_de_termino_e_obrigatoria')}
                  </p>
                </Grid>
           
                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={4}
                    name={`description-${index}`}
                    id={`description-${index}`}
                    label="descrição da fase"
                    type="descrição"
                    variant="filled"
                    fullWidth
                    className={classes.box}
                    onBlur={handleChangePhase}
                    defaultValue={item.description}
                    inputRef={register({
                      required: t('descricao_da_fase_e_obrigatorio')
                    })}
                    error={!!errors[`description-${index}`]}
                    helperText={errors[`description-${index}`] && errors[`description-${index}`].message}
                  />
                </Grid>
                <Grid item xs={12}>
                <FormControlLabel
                  label={t('checkbox_novas_ideias_fase')}
                  control={
                    <Checkbox
                      color="primary"
                      checked={item.acceptNewIdeas}
                      onChange={handleChangeAcceptNewIdeas}
                      name={`acceptNewIdeas-${index}`}
                      id={`acceptNewIdeas-${index}`}
                      type="checkbox"
                    />
                  }
                  className={classes.alignCheckBoxBulEmpty}
                  />
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              variant="text"
              color="primary"
              startIcon={<AddCircleIcon />}
              onClick={handleAddNewPhase}
            >
              incluir nova fase
            </Button>
          </Grid>
        </Grid>
      </ChallengeStepContent>
    </ChallengeStepWrapper>
  );
}

export { ChallengeDatesCard };
