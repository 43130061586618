import React from 'react';
import { makeStyles, Button } from '@material-ui/core';
import clsx from 'clsx';
import { history } from '../../helpers';
import { useLocation, Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  containerMenu: {
    display: 'flex',
    marginLeft: 60,
    alignItems: 'center',
  },
  tab: {
    paddingBottom: 15,
    paddingRight: 16,
    paddingLeft: 16,
    marginTop: 16,
    color: theme.palette.neutrals.low.light,
    fontSize: 14,
    fontWeight: 600,
    cursor: 'pointer',
  },
  active: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.neutrals.low.medium,
  },
}));

function BackNavAdminMenus({ type, id, createIdea,status }) {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <div>
        {type === 'admin' && (
          <div className={classes.containerMenu}>
            <div
              className={
                location.pathname === `/challenge/detail/${id}`
                  ? clsx(classes.tab, classes.active)
                  : classes.tab
              }
              onClick={() => history.push(`/challenge/detail/${id}`)}
            >
              {t('detalhes')}
            </div>
            <div
              className={
                location.pathname === `/challenge/follow-up/${id}`
                  ? clsx(classes.tab, classes.active)
                  : classes.tab
              }
              onClick={() => history.push(`/challenge/follow-up/${id}`)}
            >
              {t('acompanhamento')}
            </div>
           <div
              className={
                location.pathname === `/challenge/reward/${id}`
                  ? clsx(classes.tab, classes.active)
                  : classes.tab
              }
              onClick={() => history.push(`/challenge/reward/${id}`)}
            >
              {t('premiacao')}
            </div>
      </div>

        )}
      </div>
    </div>
  );
}

export { BackNavAdminMenus };
