import React from 'react';
import { makeStyles, FormControl, Select, MenuItem } from '@material-ui/core';
import { Search } from '../Shared';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import { challengeActions } from "./../../actions/challenge.actions"
import { ChallengeFilters as ChallengeFiltersButton } from "./../../components/Shared/FilterMenu/ChallengeFilters"

const useStyles = makeStyles((theme) => ({
  filter: {
    margin: theme.spacing(3, 0),
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function ChallengeFilters({ queryFilter, classification }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    paging: { 
      page, 
      sort, 
      filter 
    },
  } = useSelector((state) => state.challenge);

  function query(q) {
    queryFilter(q);
  }

  function handleChangeStatus(value) {
    console.log(value)
    dispatch(challengeActions.changeChallengeFilter(1, value));
  }

  function handleFilter() {
    dispatch(challengeActions.getChallenges(1, sort, filter));
  }

  return (
    <div className={classes.filter}>
      <ChallengeFiltersButton handleChange={handleChangeStatus} handleFilter={handleFilter} />
      <Search
        query={query}
        placeholder={t('pesquise_por_nome_da_ideia_ou_desafio')}
      />
    </div>
  );
}

export { ChallengeFilters };
