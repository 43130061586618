import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, Divider, ListItem, ListItemText, Typography } from '@material-ui/core';

import {
  ContentsContainer,
  ContentsCardTitle,
  NoteIcon,
  TextSeeAll,
  ContentsCard,
  Link,
  ContentDescription,
  Image
} from './styles';
import { truncate, history } from '../../../helpers';
import { CustomTooltip } from '../../Shared/CustomTooltip';

export function Contents() {
  const { t } = useTranslation();
  const { contents } = useSelector(state => state.contents);

  function handleSeeAllContent() {
    history.push('/contents-details');
  }

  return (
    <ContentsContainer>
      <ContentsCardTitle elevation={0}>
        <Box display="flex" alignItems="center">
          <NoteIcon />
          <Typography variant="subtitle2">{t('conteudos')}</Typography>
        </Box>
        <TextSeeAll onClick={handleSeeAllContent}>{t('ver_todos')}</TextSeeAll>
      </ContentsCardTitle>
      <ContentsCard disablePadding>
        { contents &&
          contents.slice(0, 4).map((content, index) => (
          <Fragment key={content._id}>
            <ListItem disableGutters  style={{ padding: 0 }}>
              <ListItemText>
                <CustomTooltip title={content.title}>
                  {content.link ? (
                    <Typography variant="subtitle2">
                      <Link
                        href={content.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ wordWrap: 'break-word' }} 
                      >
                        {content.title}
                      </Link>
                    </Typography>
                  ) : (
                    <Typography variant="subtitle2">
                      {truncate(content.title, !content?.attachment?.url ? 60 : 28)}
                    </Typography>
                  )}
                </CustomTooltip>
                <ContentDescription>
                  {truncate(content.description, !content?.attachment?.url ? 120 : 60)}
                </ContentDescription>
              </ListItemText>
              {content?.attachment?.url && (
                <Image src={content.attachment.url} alt="banner content" />
              )}
            </ListItem>
            {index !== (contents.length - 1) && <Divider style={{ margin: '1rem 0' }} />}
          </Fragment>
        ))}
      </ContentsCard>
    </ContentsContainer>
  );
}