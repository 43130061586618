import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  AppBar,
  Toolbar,
  Container,
  IconButton,
  Hidden,
  Drawer,
  Box,
  Divider,
  InputBase
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';

import config from '../../config';
import { menuCss } from './menuCss';
import { Settings } from './Settings';
import { MenuAdmin } from './MenuAdmin';
import { MenuTerms } from './MenuTerms';
import { MenuManager } from './MenuManager';
import { MenuColaborator } from './MenuColaborator';
import { Search } from "./../../components";
import { Notification } from './Notification';
import { domainsActions } from '../../actions';

import { timelineActions } from "./../../actions";
import LanguageSelect from '../LanguageSelect/LanguageSelect';
import logo from '../../assets/imgs/logo-haoc.png';
import logoCenter from '../../assets/imgs/logo-horizontal-HAOC.png';
function Navbar() {
  const classes = menuCss();
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const [mobileSearch, setMobileSearch] = useState(false);
  const {
    _id,
    typeProfile: { type },
  } = useSelector((state) => state.user);
  const { pendingIdeas } = useSelector((state) => state.idea);
  const { page, sort } = useSelector((state) => state.timeline.paging);
  const [searchMobileQuery, setSearchMobileQuery] = useState("");
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(domainsActions.getDomains());
  }, [dispatch, location.pathname]);

  function toggleDrawer() {
    setDrawer(!drawer);
  }

  function handleSearch(search) {
    dispatch(timelineActions.setSearch(search));
  }

  function handleClickSearchMobile() {
    setMobileSearch(true)
  }

  function handleClickCancelSearchMobile() {
    setMobileSearch(false)
    setSearchMobileQuery("")
  }

  function handleChangeSearchMobile(e) {
    setSearchMobileQuery(e.target.value)
  }

  function handleSearchMobile() {
    handleSearch(searchMobileQuery)
  }

  return (
    <>
      <Drawer style={{ zIndex: 1299 }} open={drawer} onClose={toggleDrawer}>
        <div className={classes.listDrawer}>
          {type === 'manager' && (
            <MenuManager toggleDrawer={toggleDrawer} userId={_id} pendingIdeas={pendingIdeas} />
          )}
          {type === 'colaborator' && (
            <MenuColaborator toggleDrawer={toggleDrawer} userId={_id} />
          )}
          {type === 'admin' && (
            <MenuAdmin toggleDrawer={toggleDrawer} userId={_id} pendingIdeas={pendingIdeas} />
          )}
        </div>
      </Drawer>

      <AppBar position="sticky" className={classes.appBar}>
        <Container maxWidth="lg" className={classes.container}>
          {['/regulation', '/terms', '/faq'].includes(location.pathname) ? (
            <Toolbar style={{ minHeight: 68}}>
              <Link to="/">
                <img src={logo} alt="logo" className={classes.logo} />
              </Link>
              <MenuTerms />
            </Toolbar>
          ) : (
            <Toolbar>
              <IconButton color="inherit" onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>
              <Hidden smDown>
                <Link to="/">
                  <img src={logo} alt="logo" className={classes.logo} />
                </Link>
                  <div className={classes.spacer}></div>

                <Link to="/">
                  <img src={logoCenter} alt="logo" maxWidth="204px" className={classes.logoCenter} />
                </Link> 
              </Hidden>
              <Hidden smDown>
                { location.pathname === "/" && (
                 <Box maxWidth="300px">
                    <Search
                      className={classes.searchInputWrapper}
                      placeholder={t('pesquisar')} 
                      query={handleSearch}
                      value=""
                      InputProps={{
                        className: classes.searchInput
                      }}
                    />
                 </Box>
                )}
              </Hidden>
              { mobileSearch && location.pathname === "/" ? (
                <>
                 <InputBase
                    className={classes.searchInputMobile}
                    placeholder={t("pesquisar")}
                    value={searchMobileQuery}
                    onChange={handleChangeSearchMobile}
                  />
                  <IconButton color="inherit" type="submit" className={classes.iconButton} onClick={handleSearchMobile}>
                    <SearchIcon />
                  </IconButton>
                  <Divider className={classes.dividerMobile} orientation="vertical" />
                  <IconButton color="inherit" type="submit" className={classes.iconButton} onClick={handleClickCancelSearchMobile}>
                    <CancelIcon />
                  </IconButton>
                </>
              ) : (
                <>
                  <div className={classes.spacer}></div>
                  {config.LANGUAGE_SELECT &&  <LanguageSelect />}
                  <Hidden smUp>
                    <IconButton color="inherit" onClick={handleClickSearchMobile}>
                      <SearchIcon xlarge />
                    </IconButton>
                  </Hidden>
                  <Notification />
                  <Settings />
                </>
              )}
           
            </Toolbar>
          )}
        </Container>
      </AppBar>
    </>
  );
}

export { Navbar };
