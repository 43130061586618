import React from 'react';
import Grid from '@material-ui/core/Grid';
import MuiCard from '@material-ui/core/Card';
import { styled, withStyles } from '@material-ui/core/styles';
import MuiSkeleton from '@material-ui/lab/Skeleton';

const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.neutrals.high.medium}`,
  padding: theme.spacing(4),
}));

const Skeleton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.neutrals.low.lightest
  },
  rect: {
    borderRadius: 4
  }
}))(MuiSkeleton);

export function SkeletonEvaluationFormActions() {
  return (
    <Card elevation={0}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Skeleton variant="rect" width={"100%"} height={54} />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rect" width={"100%"} height={54} />
        </Grid>
      </Grid>
    </Card>
  );
}