import update from 'react-addons-update';
import { crudTemplateConstants } from '../constants';

const INITIAL_STATE = {
    openDeleteDialog: false,
    idToUpdate: '',
    sidebarOptions: [{
        label: 'duvidas-frequentes',
        pathname: 'faq'
    }],
    typePage: 'table',
    title: '',
    type: '',
    typeQuery: ['title', 'description'],
    loadings: {
        getAll: false,
        create: false,
    },
    body: {},
    paging: {
        page: 1,
        limit: 10,
        sort: {createdAt: -1},
        query: '',
    },
    table: []
};

export function crudTemplate(state = INITIAL_STATE, action) {
    switch (action.type) {
        case crudTemplateConstants.REQUEST_GET_ALL:
            return (update(state, {
                loadings: {
                    getAll: { $set: true }
                }
            }));

        case crudTemplateConstants.FAILURE_GET_ALL:
            return (update(state, {
                loadings: {
                    getAll: { $set: false }
                }
            }));

        case crudTemplateConstants.GET_ALL:
            return (update(state, {
                loadings: {
                    getAll: { $set: false }
                },
                paging: { $set: action.data.paging },
                table: { $set: action.data.data }
            }));

        case crudTemplateConstants.SET_ITEMS:
            const { name, value } = action.payload;

            return (update(state, {
                body: { [name]: { $set: value } }
            }));

        case crudTemplateConstants.SET_TITLE:
            return (update(state, {
                title: { $set: action.payload.title }
            }));

        case crudTemplateConstants.SET_TYPE:
            return (update(state, {
                type: { $set: action.payload.type }
            }));

        case crudTemplateConstants.SET_TYPE_PAGE:
            return (update(state, {
                typePage: { $set: action.payload.type }
            }));

        case crudTemplateConstants.SET_ID:
            return (update(state, {
                idToUpdate: { $set: action.payload.id }
            }));

        case crudTemplateConstants.SET_OPEN_DELETE_DIALOG:
            return (update(state, {
                openDeleteDialog: { $set: action.payload.bool }
            }));

        case crudTemplateConstants.SET_PAGE:
            return(update(state, {
                paging: {
                    page: { $set: action.payload.page },
                  },
            }));

        case crudTemplateConstants.SET_LIMIT:
            return(update(state, {
                paging: {
                    limit: { $set: action.payload.limit },
                },
            }));

        case crudTemplateConstants.CLEAR_BODY_FIELDS:
            return(update(state,  {
                body: {
                    $set: INITIAL_STATE.body
                }
            }));

        case crudTemplateConstants.SET_SEARCH:
            return(update(state, {
                paging: {
                    query: { $set: action.payload.query }
                 },
            }));

        case crudTemplateConstants.SEARCH_REQUEST:
            return(update(state, {
                loadings: {
                    getAll: { $set: true }
                }
            }));

        case crudTemplateConstants.SEARCH_SUCCESS:
            return(update(state, {
                table: { $set: action.data },
                loadings: {
                    getAll: { $set: false }
                }
            }));

        case crudTemplateConstants.SEARCH_FAILURE:
            return(update(state, {
                loadings: {
                    getAll: { $set: false }
                }
            }));

        default:
            return state;
    };
};
