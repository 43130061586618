export const crudTemplateConstants = {
    GET_ALL: 'GET_ALL',
    REQUEST_GET_ALL: 'REQUEST_GET_ALL',
    FAILURE_GET_ALL: 'FAILURE_GET_ALL',

    CREATE: 'CREATE',
    FAILURE_CREATE: 'FAILURE_CREATE',

    SET_ITEMS: 'SET_ITEMS',

    DELETE: 'DELETE',
    FAILURE_DELETE: 'FAILURE_DELETE',

    UPDATE: 'UPDATE',
    FAILURE_UPDATE: 'FAILURE_UPDATE',

    SET_TITLE: 'SET_TITLE',
    SET_TYPE: 'SET_TYPE',
    SET_TYPE_PAGE: 'SET_TYPE_PAGE',
    SET_ID: 'SET_ID',
    SET_OPEN_DELETE_DIALOG: 'SET_OPEN_DELETE_DIALOG',

    SET_PAGE: 'SET_PAGE',
    SET_LIMIT: 'SET_LIMIT',

    CLEAR_BODY_FIELDS: 'CLEAR_BODY_FIELDS',

    SET_SEARCH: 'SET_SEARCH',
    SEARCH_REQUEST: 'SEARCH_REQUEST',
    SEARCH_SUCCESS: 'SEARCH_SUCCESS',
    SEARCH_FAILURE: 'SEARCH_FAILURE',

};