import React from 'react';
import { TemplateTextArea } from '../../components/TemplateCrud';


const textFields = [{
  title: 'duvidas-frequentes',
  rows: [
    { field: 'title', label: 'titulo' },
    { field: 'description', label: 'descricao', rows: 8 }
  ]
}];

export function QuestionEditPage () {
  return (<><TemplateTextArea fields={textFields} /></>);
};
