import { infraStructureConstants } from '../constants';
import { infraStructureService } from '../services';
import { alertActions } from './alert.actions';

export const infraStructureActions = {
    changeFieldName,
    clearInfrastructureDialog,
    loadInfraStructureDialog,
    loadInfraStructureAssociations,
    createInfraStructure,
    updateInfraStructure,
    deleteInfraStructure,
    getInfraStructure,
    searchByName,
    changeFieldAssociation,
    addNewAssociation,
    removeAssociation,
    clearAssociations,
    postNewAssociation,
    getAssociation,
    changeTab,
    changeClassificationFilter,
    getAllAssociations,
    clearIdsToRemove,
    putAssociation,
    deleteAssociation
};

function getAllAssociations(page, limit, dateSort, searchText) {
    return dispatch => {
        dispatch({ type: infraStructureConstants.REQUEST_ALL_ASSOCIATIONS });
        infraStructureService.getAllAssociations(page, limit, dateSort, searchText).then(({ paging, data }) => {
            dispatch({ type: infraStructureConstants.GET_ALL_ASSOCIATIONS, payload: { paging, data } })
        }).catch(err => {
            dispatch({ type: infraStructureConstants.FAILURE_ALL_ASSOCIATIONS });
            console.log(err);
        })
    }
}

function changeFieldName(name, value) {
    return dispatch => {
        dispatch({
            type: infraStructureConstants.CHANGE_INFRASTRUCTURE_FIELD_NAME,
            payload: { name, value }
        });
    }
}

function clearInfrastructureDialog() {
    return dispatch => {
        dispatch({ type: infraStructureConstants.CLEAR_INFRASTRUCTURE_DIALOG });
    }
}

function loadInfraStructureDialog(newInfraStructure) {
    return dispatch => {
        dispatch({
            type: infraStructureConstants.LOAD_INFRASTRUCTURE_DIALOG,
            payload: newInfraStructure
        })
    }
}

function loadInfraStructureAssociations(data) {
    return dispatch => {
        dispatch({
            type: infraStructureConstants.LOAD_INFRASTRUCTURE_ASSOCIATIONS,
            payload: data
        })
    }
}

function createInfraStructure({ type, data }, selectedTab, dateSort, successMessage) {
    return dispatch => {
        dispatch({ type: infraStructureConstants.CREATE_INFRASTRUCTURE_REQUEST });
        infraStructureService.postInfraStructure(type, data)
            .then(({ data }) => {
                dispatch({
                    type: infraStructureConstants.CREATE_INFRASTRUCTURE_SUCCESS,
                    payload: data
                });
                dispatch(getInfraStructure({ page: 1, limit: 10, types: [selectedTab], dateSort: dateSort }));
                dispatch(alertActions.success(successMessage));
            })
            .catch((error) => {
                dispatch({
                    type: infraStructureConstants.CREATE_INFRASTRUCTURE_FAILURE,
                    error
                })
            })
    }
}

function updateInfraStructure({ id, type, data }, selectedTab, dateSort, successMessage) {
    return dispatch => {
        dispatch({ type: infraStructureConstants.UPDATE_INFRASTRUCTURE_REQUEST });
        infraStructureService.editInfraStructures(id, type, data)
            .then(({ data }) => {
                dispatch({
                    type: infraStructureConstants.UPDATE_INFRASTRUCTURE_SUCCESS,
                    payload: data.data
                });
                dispatch(getInfraStructure({ page: 1, limit: 10, types: [selectedTab], dateSort: dateSort }));
                dispatch(alertActions.success(successMessage));
            })
            .catch((error) => {
                dispatch({
                    type: infraStructureConstants.UPDATE_INFRASTRUCTURE_FAILURE,
                    error
                })
            })
    }
}

function deleteInfraStructure({ id, type }, selectedTab, dateSort, successMessage) {
    return dispatch => {
        dispatch({ type: infraStructureConstants.DELETE_INFRASTRUCTURE_REQUEST });
        infraStructureService.deleteInfraStructure(id, type)
            .then(({ data }) => {
                dispatch({
                    type: infraStructureConstants.DELETE_INFRASTRUCTURE_SUCCESS,
                    payload: data
                });
                dispatch(getInfraStructure({ page: 1, limit: 10, types: [selectedTab], dateSort: dateSort }));
                dispatch(alertActions.success(successMessage));
            })
            .catch((error) => {
                dispatch({
                    type: infraStructureConstants.DELETE_INFRASTRUCTURE_FAILURE,
                    error
                })
            })
    }
}

function getInfraStructure({ page, limit, types, dateSort }) {
    return dispatch => {
        dispatch({ type: infraStructureConstants.GET_INFRASTRUCTURE_REQUEST });
        infraStructureService.getInfraStructure(page, limit, types, dateSort)
            .then((response) => {
                dispatch({
                    type: infraStructureConstants.GET_INFRASTRUCTURE_SUCCESS,
                    payload: {
                        infras: response.data.infras,
                        paging: response.data.paging,
                    }
                });
            })
            .catch((error) => {
                dispatch({
                    type: infraStructureConstants.GET_INFRASTRUCTURE_FAILURE,
                    error
                });
            })
    }
}

function searchByName({ name }) {
    return (dispatch) => {
        dispatch({ type: infraStructureConstants.GET_INFRASTRUCTURE_REQUEST });
        infraStructureService.searchByName(name)
            .then(({ data }) => {
                dispatch({
                    type: infraStructureConstants.GET_INFRASTRUCTURE_SUCCESS,
                    payload: {
                        paging: data.data.paging,
                        infras: data.data.infras
                    }
                });
            })
            .catch((error) => {
                dispatch({
                    type: infraStructureConstants.GET_INFRASTRUCTURE_FAILURE,
                    error
                });
            })
    }
}

function changeFieldAssociation(name, value, index) {
    return dispatch => {
        dispatch({
            type: infraStructureConstants.CHANGE_INFRASTRUCTURE_FIELD_ASSOCIATION,
            payload: { name, value, index }
        });
    }

}

function addNewAssociation() {
    return dispatch => {
        dispatch({ type: infraStructureConstants.ADD_NEW_INFRASTRUCTURE_ASSOCIATION });
    }
}

function removeAssociation(index, id) {
    return dispatch => {
        dispatch({
            type: infraStructureConstants.REMOVE_INFRASTRUCTURE_ASSOCIATION,
            payload: { index, id }
        });
    }
}

function clearAssociations() {
    return dispatch => {
        dispatch({ type: infraStructureConstants.CLEAR_INFRASTRUCTURE_ASSOCIATIONS });
    }
}

function postNewAssociation(data, successMessage) {
    return (dispatch) => {
        dispatch({ type: infraStructureConstants.POST_INFRASTRUCTURE_ASSOCIATION_REQUEST });
        infraStructureService.postNewAssociation(data).then(() => {
            dispatch({
                type: infraStructureConstants.POST_INFRASTRUCTURE_ASSOCIATION_SUCCESS,
            });
            dispatch(alertActions.success(successMessage));
        })
            .catch((error) => {
                dispatch({
                    type: infraStructureConstants.POST_INFRASTRUCTURE_ASSOCIATION_FAILURE,
                });
            });
    }
}

function putAssociation(data, successMessage) {
    return (dispatch) => {
        dispatch({ type: infraStructureConstants.PUT_INFRASTRUCTURE_ASSOCIATION_REQUEST });
        infraStructureService.updateAssociation(data).then(() => {
            dispatch({
                type: infraStructureConstants.PUT_INFRASTRUCTURE_ASSOCIATION_SUCCESS,
            });
            dispatch(alertActions.success(successMessage));
        })
            .catch((error) => {
                dispatch({
                    type: infraStructureConstants.PUT_INFRASTRUCTURE_ASSOCIATION_FAILURE,
                });
            });
    }
}

function deleteAssociation(data, successMessage) {
    return (dispatch) => {
        dispatch({ type: infraStructureConstants.DELETE_INFRASTRUCTURE_ASSOCIATION_REQUEST });
        infraStructureService.deleteAssociation(data).then(() => {
            dispatch({
                type: infraStructureConstants.DELETE_INFRASTRUCTURE_ASSOCIATION_SUCCESS,
            });
            dispatch(alertActions.success(successMessage));
        })
            .catch((error) => {
                dispatch({
                    type: infraStructureConstants.DELETE_INFRASTRUCTURE_ASSOCIATION_FAILURE,
                    error
                });
            });
    }
}

function getAssociation(id) {
    return dispatch => {
        dispatch({ type: infraStructureConstants.GET_INFRASTRUCTURE_ASSOCIATION_REQUEST });
        infraStructureService.getAssociation(id)
            .then(({ data }) => {
                dispatch({
                    type: infraStructureConstants.GET_INFRASTRUCTURE_ASSOCIATION_SUCCESS,
                    payload: data.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: infraStructureConstants.GET_INFRASTRUCTURE_ASSOCIATION_FAILURE,
                    error
                });
            })
    }
}

function changeTab(value) {
    return dispatch => {
        dispatch({
            type: infraStructureConstants.CHANGE_SELECTED_TAB,
            payload: value
        });
    }
}

function changeClassificationFilter(value) {
    return dispatch => {
        dispatch({
            type: infraStructureConstants.CHANGE_INFRA_CLASSIFICATION_FILTER,
            payload: value
        });
    }
}


function clearIdsToRemove() {
    return dispatch => {
        dispatch({
            type: infraStructureConstants.CLEAR_IDS_TO_REMOVE,
        });
    }
}