import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@material-ui/core';

import { Search } from '../../components/Shared';
import { crudTemplateActions } from '../../actions';
import { TemplateCrudFilter } from '../../components/TemplateCrud';
import { selectEndPoint } from '../../helpers/selectEndPointToTemplate';

export function TemplateCrudHeader() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { paging, typeQuery, type, query } = useSelector(state => state.crudTemplate);

  function search(query) {
    typeQuery.forEach((typequery) => {
      dispatch(
        crudTemplateActions.search({ 
          endPoint: selectEndPoint(type),
          typeQuery: typequery,
          query,
          page: paging?.page, 
          limit: paging?.limit, 
          sort: paging?.sort
        })
      );
    });
  }

  return (
    <Box display="flex" alingItems="center" justifyContent="space-between">
      <TemplateCrudFilter />
      <Search query={search} value={query} placeholder={t('pesquise_por_titulo_ou_descricao')} />
    </Box>
  );
}